import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import NoData from "../../Helper/NoDataFound.jsx";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import EducationCheck from "./Checklist/EducationCheck.jsx";
import AddressCheck from "./Checklist/AddressCheck.jsx";
import CertificateCheck from "./Checklist/CertificateCheck.jsx";
import IdentityCheck from "./Checklist/IdentityCheck.jsx";
import CourtCheck from "./Checklist/CourtCheck.jsx";
import CreditCheck from "./Checklist/CreditCheck.jsx";
import PoliceCheck from "./Checklist/PoliceCheck.jsx";
import DrugCheck from "./Checklist/DrugCheck.jsx";
import ReferenceCheck from "./Checklist/ReferenceCheck.jsx";
import ExperienceCheck from "./Checklist/ExperienceCheck.jsx";
import VideoKycCheck from "./Checklist/VideoKycCheck.jsx";
import GlobalDatbaseCheck from "./Checklist/GlobalDatbaseCheck.jsx";
import SexOffenderCheck from "./Checklist/SexOffenderCheck.jsx";
import BankCheck from "./Checklist/BankCheck.jsx";
import { Modal } from "antd";
import PamacLetter from "./PamacLetter.jsx";

export const UserLink = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  let webFormId;
  if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
    // const { webFormId: paramWebFormId } = useParams();
    // webFormId = paramWebFormId;
  } else {
    webFormId = user?.caseId;
  }

  const [ActiveTabInfo, setActiveTabInfo] = useState("Upload Documents");
  const [modal, setModal] = useState(false);
  const [pamacModal, setPamacModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isError, setIsError] = useState(false);
  const [userInfo, setUserInfo] = useState("");

  const handleIsTabClick = (tabNameInfo) => {
    setActiveTabInfo(tabNameInfo);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setIsError(false);
  };

  const handleSubmitDetails = () => {
    if (process.env.REACT_APP_IS_PAMAC_REPORT_ENABLED === "true") {
      setPamacModal(true);
    } else {
      setModal(true);
    }
  };

  const updateCaseIsDetailFilled = async (isChecked) => {
    let currentDate = new Date();
    if (isChecked) {
      let url;
      if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
        url = "userVerificationRequest/link";
      } else {
        url = "userVerificationRequest";
      }
      let body = {
        isDetailFilled: {
          status: true,
          dateAndTime: currentDate,
        },
        status: "Details-Filled",
        linkStatus: "DetailsFilled",
      };
      ApiService.post(`${url}/${userInfo?._id}`, body)
        .then(() => {
          toast.success("Case Detail Filled Successfully");
          setModal(false);
          setPamacModal(false);
          setIsError(false);
          getUserVerificationReqInfo();
        })
        .catch(() => {});
    } else {
      setIsError(true);
    }
  };

  const getUserVerificationReqInfo = async () => {
    ApiService.get(`userVerificationRequest/link/${webFormId}`)
      .then((res) => {
        setUserInfo(res?.data[0]);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (webFormId !== null) {
      getUserVerificationReqInfo();
    }
  }, [webFormId]);

  const renderContent = (activeTab) => {
    switch (activeTab) {
    case "Experience":
      return (
        <>
          <ExperienceCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Certificate":
      return (
        <>
          <CertificateCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Drug":
      return (
        <>
          <DrugCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Police":
      return (
        <>
          <PoliceCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Court":
      return (
        <>
          <CourtCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Credit":
      return (
        <>
          <CreditCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Reference":
      return (
        <>
          <ReferenceCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );

    case "Video KYC":
      return (
        <>
          <VideoKycCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Global Database":
      return (
        <>
          <GlobalDatbaseCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Sex Offender":
      return (
        <>
          <SexOffenderCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );

    case "Bank":
      return (
        <>
          <BankCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Education":
      return (
        <>
          <EducationCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Address":
      return (
        <>
          <AddressCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );

    case "Identity":
      return (
        <>
          <IdentityCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    default:
      return (
        <div>
          {" "}
          <NoData info="Ooops, no content available!!" />
        </div>
      );
    }
  };

  const renderTabsInfo = (ActiveTabInfo) => {
    switch (ActiveTabInfo) {
    case "Upload Documents":
      return (
        <>
          <div
            className="flex flex-col items-center justify-start sm:px-5 rounded-[10px] w-full"
            style={{ padding: "0px" }}
          >
            <div className="mb-1 w-full">
              <>
                <div className="bg-stone-50 shadow bg-opacity-95 rounded-[10px] sm:p-[18px] border border-stone-300 border-opacity-80 gap-[40px] mb-[100px]">
                  <div className="w-full h-auto">
                    <TabView scrollable>
                      {[
                        {
                          name: "Education",
                          condition: userInfo?.checks?.includes("Education"),
                        },
                        {
                          name: "Experience",
                          condition: userInfo?.checks?.includes("Experience"),
                        },
                        {
                          name: "Certificate",
                          condition:
                              userInfo?.checks?.includes("Certificate"),
                        },
                        {
                          name: "Drug",
                          condition: userInfo?.checks?.includes("Drug"),
                        },
                        {
                          name: "Address",
                          condition: userInfo?.checks?.includes("Address"),
                        },
                        {
                          name: "Identity",
                          condition: userInfo?.checks?.includes("Identity"),
                        },
                        {
                          name: "Police",
                          condition: userInfo?.checks?.includes("police"),
                        },
                        {
                          name: "Court",
                          condition: userInfo?.checks?.includes("Court"),
                        },
                        {
                          name: "Credit",
                          condition: userInfo?.checks?.includes("Credit"),
                        },
                        {
                          name: "Reference",
                          condition: userInfo?.checks?.includes("Reference"),
                        },
                        {
                          name: "Video KYC",
                          condition: userInfo?.checks?.includes("VideoKyc"),
                        },
                        {
                          name: "Global Database",
                          condition:
                              userInfo?.checks?.includes("GlobalDatabase"),
                        },
                        {
                          name: "Sex Offender",
                          condition:
                              userInfo?.checks?.includes("SexOffender"),
                        },
                      ]
                        .filter((tab) => tab.condition)
                        .map((tab) => (
                          <TabPanel
                            key={tab.name}
                            header={tab.name}
                            // onClick={() => handleTabClick(tab.name)}
                          >
                            {renderContent(tab.name)}
                          </TabPanel>
                        ))}
                    </TabView>
                  </div>
                </div>
              </>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="sm:container-xl px-auto mt-3">
        <div className="flex lg:flex-row px-[12px] flex-col xl:pt-0 lg:pt-[10%] md:pt-[10%] pt-[15%] lg:overflow-x-auto overflow-style-none md:rounded-lg gap-[20px]">
          {["Upload Documents"].map((tabName) => (
            <div
              key={Math.random().toString(36).substr(2, 9)}
              role="presentation"
              onClick={() => handleIsTabClick(tabName)}
              className={`${
                ActiveTabInfo === tabName
                  ? "text-black text-[18px] font-medium font-['Poppins'] w-auto"
                  : "bg-[#B8B8B8] rounded-[7px] shadow-md text-white text-[18px] font-medium font-['Poppins'] w-auto"
              } py-2 px-4 h-[36px] cursor-pointer flex flex-row`}
            >
              <div className="h-[20px] lg:text-nowrap ">
                {(tabName, ActiveTabInfo === tabName)}
              </div>

              <div className="h-[20px] lg:text-nowrap text-[18px] font-medium font-['Poppins']">
                Attach Documents & Fill Details:
              </div>
            </div>
          ))}
        </div>

        {ActiveTabInfo === "Upload Documents" && (
          <div className="text-end mb-[10px] max-sm:mt-[30px] px-[12px]">
            {userInfo?.isDetailFilled?.status !== true ? (
              <Button
                label="Submit Details"
                type="button"
                onClick={handleSubmitDetails}
                className="w-[170px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              />
            ) : (
              <div className="flex items-center justify-end mb-[10px] max-sm:mt-[30px] px-[12px] space-x-[10px]">
                <div className="text-green-500 !font-bold text-[20px] lg:text-nowrap">
                  Details Submitted
                </div>
                {process.env.REACT_APP_IS_PAMAC_REPORT_ENABLED === "true" && (
                  <Button
                    label="View Letter"
                    type="button"
                    onClick={() => setPamacModal(true)}
                    className="w-[170px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
                  />
                )}
              </div>
            )}
          </div>
        )}

        <div className="h-auto w-[1px] bg-gray-300"></div>
        <div className="h-auto sm:p-[12px] mt-[5px] ">
          {renderTabsInfo(ActiveTabInfo)}
        </div>

        <Modal
          title="Terms and Conditions :"
          width="70%"
          open={modal}
          footer={null}
          onCancel={() => setModal(false)}
        >
          <form>
            <p>
              1. Acceptance of Terms By accessing or using our services, you
              agree to be bound by these terms and conditions, all applicable
              laws, and regulations, and agree that you are responsible for
              compliance with any applicable local laws. If you do not agree
              with any of these terms, you are prohibited from using or
              accessing this site.
              <br />
              <br />
              2. Use of Our Services You agree to use the services only for
              lawful purposes and in accordance with these terms and conditions.
              You must not: Use the services in any way that breaches any
              applicable local, national, or international law or regulation.
              Use the services for any unlawful or fraudulent purposes, or with
              any unlawful or fraudulent intent. Attempt to gain unauthorized
              access to any part of the services or their related systems or
              networks.
              <br />
              <br />
              3. User Accounts To access certain features of our services, you
              may be required to create an account. You are responsible for
              maintaining the confidentiality of your account credentials and
              for all activities that occur under your account. We reserve the
              right to terminate your account at any time if we believe you have
              violated these terms.
            </p>
            <div className="relative">
              <input
                type="checkbox"
                id="consent"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="absolute top-[6px] cursor-pointer"
              />

              <label htmlFor="consent" className="ml-5">
                I agree to the{" "}
                <span className="text-blue-500">terms and conditions</span> and
                the <span className="text-blue-500">rules</span>.
              </label>
              {isError && (
                <div style={{ color: "red" }}>
                  You must agree to the terms and conditions and rules.
                </div>
              )}
            </div>
          </form>
          <div className="mt-[20px] text-center">
            <button
              type="submit"
              className="w-[141px] text-white text-xs font-medium h-[37px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow cursor-pointer"
              onClick={() => updateCaseIsDetailFilled(isChecked)}
            >
              Submit
            </button>
          </div>
        </Modal>
        <PamacLetter
          isOpen={pamacModal}
          onClose={() => setPamacModal(false)}
          userInfo={userInfo}
          onSubmit={() => updateCaseIsDetailFilled(true)}
          getUpdatedInfo={getUserVerificationReqInfo}
        />
      </div>
    </>
  );
};
