import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const DrugCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    // height: "590px",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 60,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

const getStatusColor = (status) => {
  switch (status) {
  case "Major Discrepancy":
    return "red";
  case "Minor Discrepancy":
    return "orange";
  case "Verified Clear":
    return "green";
  case "Insufficiency":
    return "#859bed";
  case "Unable to Verify":
    return "orange";
  case "Stop Check":
    return "gray";
  case "Rejected":
    return "red";
  case "Approved":
    return "green";
  case "Completed":
    return "green";
  case "InProgress":
    return "#859bed";
  case "Pending":
    return "orange";
  case "BcaApproval":
    return "green";
  case "BcaRejection":
    return "red";
  case "Positive":
    return "green";
  case "Negative":
    return "red";
  case "Recommended":
    return "yellow";
  case "Not Recommended":
    return "#859bed";
  case "Failed":
    return "red";
  case "Could Not Verified":
    return "red";
  default:
    return "yellow";
  }
};

const formatDate = (dateString) => {
  let date;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const SiriusDrugDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [drugInfo, setDrugInfo] = useState([]);

  const getDrugInfo = (id) => {
    ApiService.get(`drugCheck/${id}`)
      .then((res) => {
        setDrugInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getDrugInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (drugInfo.length > 0) {
      onDataUpdate({
        drugInfo,
        renderDrugDetails: () => renderDrugDetails(),
      });
    }
  }, [drugInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      <Image
        src={imgSrc}
        alt={alt || "image"}
        className={className || ""}
        style={{ height: "550px" }}
      />
    );
  };

  const renderDrugDetails = () => {
    return (
      <>
        {drugInfo?.length > 0 && (
          <>
            {drugInfo.map((drugEvidence, index) => (
              <React.Fragment key={index}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: 1,
                    marginTop: 50,
                  }}
                >
                  <Text
                    style={{
                      // textAlign: "left",
                      fontSize: 12,
                      // marginTop: 30,
                    }}
                  >
                    Detailed Report- 5 Panel Drug Test
                  </Text>
                </View>

                <View key={index} style={DrugCheckStyles.table}>
                  <View style={DrugCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Facts
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Details provided by client
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Verification remarks
                    </Text>
                  </View>
                  <View style={DrugCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Name of the candidate
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {drugEvidence?.drug?.name}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {drugEvidence?.verificationDetails?.nameRemark}
                    </Text>
                  </View>
                  <View style={DrugCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Test Name
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {drugEvidence?.drug?.testName}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {drugEvidence?.verificationDetails?.testNameRemark}
                    </Text>
                  </View>
                  <View style={DrugCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Laboratory
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {drugEvidence?.drug?.laboratory}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {drugEvidence?.verificationDetails?.laboratoryRemark}
                    </Text>
                  </View>
                  <View style={DrugCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Case#
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {drugEvidence?.drug?.case}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {drugEvidence?.verificationDetails?.caseRemark}
                    </Text>
                  </View>
                  <View style={DrugCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Date of test
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {formatDate(drugEvidence?.drug?.testDate || "NA")}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {drugEvidence?.verificationDetails?.testDateRemark}
                    </Text>
                  </View>
                  <View style={DrugCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Status
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    ></Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                        backgroundColor: getStatusColor(
                          drugEvidence?.drug?.status
                        ),
                      }}
                    >
                      {drugEvidence?.drug?.status}
                    </Text>
                  </View>
                  <View style={DrugCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Final remarks
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 8,
                      }}
                    >
                      {drugEvidence?.drug?.additionalRemarks}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 8,
                      }}
                    >
                      {
                        drugEvidence?.verificationDetails
                          ?.additionalRemarksRemark
                      }
                    </Text>
                  </View>
                </View>
                {/* <View break /> */}

                {/* Loop through and display each proof image */}
                {drugEvidence?.drug?.uploadProofUrl?.map((url, proofIndex) => (
                  <View
                    key={proofIndex}
                    style={DrugCheckStyles.centeredImageContainer}
                    wrap={false} // Ensure image stays on its own page
                    break={proofIndex !== 0} // Add page break after each image except the first one
                  >
                    <AsyncImage
                      fileUrl={url}
                      alt={`Employment evidence ${proofIndex + 1}`}
                    />
                  </View>
                ))}

                <View break />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };
  return null;
};
