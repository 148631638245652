import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const EducationCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
    // height: "590px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 40,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

const getStatusColor = (status) => {
  switch (status) {
  case "Major Discrepancy":
    return "red";
  case "Minor Discrepancy":
    return "orange";
  case "Verified Clear":
    return "green";
  case "Insufficiency":
    return "#859bed";
  case "Unable to Verify":
    return "orange";
  case "Stop Check":
    return "gray";
  case "Rejected":
    return "red";
  case "Approved":
    return "green";
  case "Completed":
    return "green";
  case "InProgress":
    return "#859bed";
  case "Pending":
    return "orange";
  case "BcaApproval":
    return "green";
  case "BcaRejection":
    return "red";
  case "Positive":
    return "green";
  case "Negative":
    return "red";
  case "Recommended":
    return "yellow";
  case "Not Recommended":
    return "#859bed";
  case "Failed":
    return "red";
  case "Could Not Verified":
    return "red";
  default:
    return "yellow";
  }
};

export const SiriusEducationDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [educationInfo, setEducationInfo] = useState([]);

  const getEducationInfo = (id) => {
    if (id) {
      ApiService.get(`educationCheck/${id}`)
        .then((res) => {
          setEducationInfo(res?.data?.docs);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getEducationInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (educationInfo.length > 0) {
      onDataUpdate({
        educationInfo,
        renderEducationDetails: () => renderEducationDetails(),
      });
    }
  }, [educationInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
      <Image
        src={imgSrc}
        alt={alt || "image"}
        className={className || ""}
        style={{ height: "550px" }}
      />
    );
  };

  const renderEducationDetails = () => {
    return (
      <>
        {educationInfo?.length > 0 && (
          <>
            {educationInfo.map((educationEvidence, index) => (
              <React.Fragment key={index}>
                {/* Render table on one page */}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: 1,
                    marginTop: 50,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  >
                    Detailed Report- Education Verification
                  </Text>
                </View>
                <View style={EducationCheckStyles.table}>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Facts
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Details provided by the client
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Verification remarks
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Name of the candidate
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {educationEvidence?.education?.candidateName}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {
                        educationEvidence?.verificationDetails
                          ?.candidateNameRemark
                      }
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Name of the university
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {educationEvidence?.education?.unversityName}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {
                        educationEvidence?.verificationDetails
                          ?.unversityNameRemark
                      }
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Degree
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {educationEvidence?.education?.degreeName}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {educationEvidence?.verificationDetails?.degreeNameRemark}
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Roll No.
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {educationEvidence?.education?.rollNumber}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {educationEvidence?.verificationDetails?.rollNumberRemark}
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Year of passing
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {educationEvidence?.education?.yearOfPassing}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {
                        educationEvidence?.verificationDetails
                          ?.yearOfPassingRemark
                      }
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Remarks about university
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {educationEvidence?.education?.remarkAboutUniversity}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {
                        educationEvidence?.verificationDetails
                          ?.remarkAboutUniversityRemark
                      }
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Status
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    ></Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                        backgroundColor: getStatusColor(
                          educationEvidence?.education?.status
                        ),
                      }}
                    >
                      {educationEvidence?.education?.status}
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Final remarks
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {educationEvidence?.education?.additionalRemarks}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {
                        educationEvidence?.verificationDetails
                          ?.additionalRemarksRemark
                      }
                    </Text>
                  </View>
                </View>
                {/* <View break /> */}
                {educationEvidence?.education?.uploadProofUrl?.map(
                  (url, proofIndex) => (
                    <View
                      key={proofIndex}
                      style={EducationCheckStyles.centeredImageContainer}
                      wrap={false} // Prevent image splitting between pages
                      break // Page break to place image on a new page
                    >
                      <AsyncImage
                        fileUrl={url}
                        alt={`Education evidence ${proofIndex + 1}`}
                      />
                    </View>
                  )
                )}
                <View break />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };

  return null;
};
