import React, { useState, useEffect } from "react";
import ApiService from "../../../services/APIServices";
import Icons from "../../../Helper/Icons.jsx";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { getBase64 } from "../../../Helper/Base64File";
import { FileUpload } from "primereact/fileupload";
import { Card } from "primereact/card";
import { customItemTemplate } from "../../../Helper/UploadFileTemplate.jsx";

export default function Inefficiency({ caseId, checkId, checkName }) {
  console.log("caseIdInsuff", caseId);

  console.log("checkIdInsuff", checkId);

  console.log("checkNameInsuff", checkName);

  let user = JSON.parse(localStorage.getItem("user"));

  const [remark, setRemark] = useState("");
  const [fileUpload, SetFileUplaod] = useState([]);
  const [inefficiencyInfo, setInefficiencyInfo] = useState([]);
  const [fileMap, setFileMap] = useState([]);
  const [timer, setTimer] = useState(null);
  const [userInfo, setUserInfo] = useState("");

  console.log("userInfo>>", userInfo);

  const SIGNEDURL_EXPIRATION_TIME =
    process.env.REACT_APP_SIGNEDURL_EXPIRATION_TIME;

  const getUserVerificationReqInfo = async () => {
    ApiService.get(`userVerificationRequest/${caseId}`)
      .then((res) => {
        setUserInfo(res?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (checkId !== null) {
      getInefficiency(checkId);
    }
  }, [checkId]);

  useEffect(() => {
    getUserVerificationReqInfo();
  }, []);

  const getInefficiency = async (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "inefficiency/ByCheckId/link";
    } else {
      url = "inefficiency/ByCheckId";
    }
    try {
      const response = await ApiService.get(`${url}/${id}`);
      setInefficiencyInfo(response?.data);
      // Prefill the remark field
      if (response?.data?.length > 0) {
        setRemark(response.data[0].remark);
        // Prefill the files
        if (response.data[0].files && response.data[0].files.length > 0) {
          const fileUrls = response.data[0].files.map((file) => ({
            name: file.name,
            url: file.url,
          }));
          SetFileUplaod(fileUrls);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateCaseIsInefficiency = async (action) => {
    let currentDate = new Date();
    let body = {
      isInefficiency: {
        status: action,
        dateAndTime: currentDate,
      },
      status: "Insufficiency",
    };
    ApiService.post(`userVerificationRequest/${caseId}`, body)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCaseIsIneffResolved = async () => {
    let body = {
      // isInefficiency: {
      //   status: action,
      //   dateAndTime: currentDate,
      // },
      status: "WIP",
    };
    ApiService.post(`userVerificationRequest/${caseId}`, body)
      .then(() => {
        toast.success("Insufficiency Resolved !!");
        // getUserVerificationReqInfo();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendInsuffEmailToClient = () => {
    let url = "sendInsuffEmailToClient";
    ApiService.post(url, {
      checkName: checkName,
      caseID: userInfo?.case,
      name: userInfo?.userInfo?.firstName,
      email: userInfo?.companyInfo?.email,
      empId: userInfo?.userInfo?.clientEmpId,
    })
      .then(() => {
        console.log("Email Sent Successfully");
      })
      .catch((error) => {
        console.error("Error on sending email...:", error);
      });
  };

  const createInefficiency = async () => {
    const body = {
      caseId: caseId,
      checkId: checkId,
      remark: "",
      files: [],
    };
    try {
      await ApiService.post("inefficiency", body);
      toast.success("Insufficiency Added successfully");
      getInefficiency(checkId);
      updateCaseIsInefficiency(true);
      if (process.env.REACT_APP_IS_VLEADER_REPORT_ENABLED === "true") {
        sendInsuffEmailToClient();
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const updateInefficiency = async (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "inefficiency/link";
    } else {
      url = "inefficiency";
    }
    const body = {
      caseId: caseId,
      checkId: checkId,
      remark: remark,
      files: fileUpload,
    };
    try {
      await ApiService.post(`${url}/${id}`, body);
      toast.success("Insufficiency updated successfully");
      getInefficiency(checkId);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setRemark(value);
  };

  const handleFileUpload = async (event, category) => {
    const allowedExtensions = /.(png|jpeg|pdf)$/i;
    const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
    const files = event.files;
    const fileData = [];

    for (let file of files) {
      // Split the filename by dots to check for multiple extensions
      const fileNameParts = file.name.split(".");

      // Validate file extension and check for multiple extensions
      if (!allowedExtensions.exec(file.name) || fileNameParts.length > 2) {
        toast.error(
          "Invalid file type. Only files with '.png','.jpeg', and '.pdf' extensions are allowed."
        );
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("Please choose a valid file size.");
        return;
      }

      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });

      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
      case "file":
        SetFileUplaod([...fileUpload, ...uploadedFileUrls]);
        toast.success("Insufficiency File successfully uploaded");
        break;
        // case "uploadProof":
        //   SetUploadProof([...uploadProof, ...uploadedFileUrls]);
        //   toast.success("Upload proof successfully uploaded");
        //   break;
        // default:
        //   break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      toast.error("Error uploading file. Please try again.");
      // Handle error uploading files
    }
  };

  const clearImages = () => {
    setFileMap([]);
    toast.warning("Link expired, please download again.");
  };

  const getImg = (e, fileName, reportIndex, imgFlag) => {
    e.preventDefault();
    // Clear the existing timer if any
    if (timer) {
      clearTimeout(timer);
    }

    ApiService.get(`download/${fileName}`)
      .then((res) => {
        if (imgFlag === "img") {
          setFileMap((prev) => ({
            ...prev,
            [reportIndex]: res?.data,
          }));
        }
        const newTimer = setTimeout(clearImages, SIGNEDURL_EXPIRATION_TIME); // 2 minutes
        setTimer(newTimer);
        toast.success("Document download Sucessfully ");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Image Download");
      });
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  return (
    <>
      <div>
        <div className="flex flex-col md:flex-row justify-between mb-[15px]">
          <div className="flex items-center flex-row gap-[10px]">
            <div className="mb-[9px]">
              <Icons name="reference-documents" color="grey" />
            </div>
            <h5>Insufficiency</h5>
          </div>
          {inefficiencyInfo?.length === 0 &&
            (user?.role === "company" ||
              user?.role === "BCA" ||
              user?.role === "BCAStaff" ||
              user?.role === "Coordinator" ||
              user?.role === "FieldCoordinator" ||
              user?.role === "DataEntry" ||
              user?.role === "Auditor") && (
            <Button
              label="Add Insufficiency"
              type="button"
              onClick={() => createInefficiency()}
              className="w-[170px] h-[45px] !text-[14px]  text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
            />
          )}
        </div>
        {inefficiencyInfo?.length !== 0 && (
          <div className="flex flex-col justify-end">
            <Card className="border-2" style={{ borderColor: "#14b8a6" }}>
              <div className="row my-2">
                <div className="col-md-12">
                  <label className="small mb-1" htmlFor="remark">
                    Remark
                  </label>
                  <InputTextarea
                    className="form-control"
                    type="text"
                    name="remark"
                    value={remark}
                    onChange={handleChange}
                    rows={5}
                    cols={30}
                  />
                </div>
                <div className="col-md-12 my-2">
                  {inefficiencyInfo[0]?.files?.length === 0 ? (
                    <div>
                      <label className="small mb-1" htmlFor="fileUpload">
                        File Upload
                      </label>
                      <FileUpload
                        name="fileUpload[]"
                        auto
                        multiple
                        accept="image/*,application/pdf"
                        // onChange={handleChange}
                        customUpload={true}
                        // maxFileSize={50000000}
                        uploadHandler={(e) => handleFileUpload(e, "file")}
                        itemTemplate={customItemTemplate}
                      />
                    </div>
                  ) : (
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {inefficiencyInfo[0]?.files?.map(
                        (fileUrl, reportIndex) => {
                          // Extract file name from the URL
                          const fileName = fileUrl.split("/").pop();
                          const fileExtension = fileName
                            .split(".")
                            .pop()
                            .toLowerCase();

                          const isImage = ["jpeg", "png"].includes(
                            fileExtension
                          );

                          const isPdf = fileExtension === "pdf";
                          return (
                            <li
                              key={reportIndex}
                              className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                            >
                              <Button
                                label="Download"
                                onClick={(e) =>
                                  getImg(e, fileName, reportIndex, "img")
                                }
                                className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                              />

                              {fileMap?.[reportIndex] && (
                                <div className="text-center mt-2">
                                  {isImage && (
                                    <a
                                      href={fileMap[reportIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download={`file_${reportIndex}`}
                                    >
                                      View Image
                                    </a>
                                  )}
                                  {isPdf && (
                                    <a
                                      href={fileMap[reportIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-center"
                                    >
                                      View PDF
                                    </a>
                                  )}
                                </div>
                              )}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex-1 flex justify-center">
                  <Button
                    label="Save"
                    type="submit"
                    onClick={() => updateInefficiency(inefficiencyInfo[0]?._id)}
                    className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                  />
                </div>
                <div className="flex">
                  <Button
                    label="Resolved"
                    type="submit"
                    onClick={() => updateCaseIsIneffResolved()}
                    className="w-[120px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                  />
                </div>
              </div>
            </Card>
          </div>
        )}
      </div>
    </>
  );
}
