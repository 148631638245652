import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const CriminalCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
    // height: "590px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

const getStatusColor = (status) => {
  switch (status) {
    case "Major Discrepancy":
      return "red";
    case "Minor Discrepancy":
      return "yellow";
    case "Verified Clear":
      return "green";
    case "Insufficiency":
      return "#859bed";
    case "Unable to Verify":
      return "#d3d3d3";
    case "Stop Check":
      return "gray";
    case "Rejected":
      return "red";
    case "Approved":
      return "green";
    case "Completed":
      return "green";
    case "InProgress":
      return "#859bed";
    case "Pending":
      return "orange";
    case "BcaApproval":
      return "green";
    case "BcaRejection":
      return "red";
    case "Positive":
      return "green";
    case "Negative":
      return "red";
    case "Recommended":
      return "green";
    case "Not Recommended":
      return "red";
    case "Failed":
      return "yellow";
    case "Could Not Verified":
      return "yellow";
    default:
      return "yellow";
  }
};

export const PamacCriminalDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [criminalInfo, setCriminalInfo] = useState([]);

  const getCriminalInfo = (id) => {
    if (id) {
      ApiService.get(`criminalCheck/${id}`)
        .then((res) => {
          setCriminalInfo(res?.data?.docs);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getCriminalInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (criminalInfo.length > 0) {
      onDataUpdate({
        criminalInfo,
        renderCriminalDetails: () => renderCriminalDetails(),
      });
    }
  }, [criminalInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
      <Image src={imgSrc} alt={alt || "image"} className={className || ""} />
    );
  };

  const renderCriminalDetails = () => {
    return (
      <>
        <Text style={{ textAlign: "center", textDecoration: "underline" }}>
          Criminal Verification
        </Text>
        {criminalInfo?.length > 0 && (
          <>
            {criminalInfo.map((criminalEvidence, index) => (
              <React.Fragment key={index}>
                <View style={{ marginTop: "40px" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Criminal verification:
                    </Text>

                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          marginRight: 5,
                        }}
                      >
                        Status:
                      </Text>
                      <View
                        style={{
                          backgroundColor: getStatusColor(
                            criminalEvidence?.criminal?.status
                          ),
                          paddingVertical: 2,
                          paddingHorizontal: 10,
                          borderRadius: 4,
                        }}
                      >
                        <Text
                          style={{
                            color: "black",
                            fontSize: 12,
                            fontWeight: "bold",
                          }}
                        >
                          {criminalEvidence?.criminal?.status}
                        </Text>
                      </View>
                    </View>
                  </View>
                  {/* Render table on one page */}
                  <View style={CriminalCheckStyles.table}>
                    <View style={CriminalCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 10,
                          fontWeight: "bold",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Details
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 10,
                          fontWeight: "bold",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        As Provided
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 10,
                          fontWeight: "bold",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        As Verified
                      </Text>
                    </View>
                    <View style={CriminalCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Address
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                        }}
                      >
                        {criminalEvidence?.criminal?.address}
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                        }}
                      >
                        {criminalEvidence?.verificationDetails?.addressRemark}
                      </Text>
                    </View>
                    <View style={CriminalCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Police Station
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                        }}
                      >
                        {criminalEvidence?.criminal?.policeStation}
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                        }}
                      >
                        {
                          criminalEvidence?.verificationDetails
                            ?.policeStationRemark
                        }
                      </Text>
                    </View>

                    <View style={CriminalCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Criminal Record Status
                      </Text>
                      <Text
                        style={{
                          width: "400px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                        }}
                      >
                        {criminalEvidence?.criminal?.criminalRecordStatus}
                      </Text>
                    </View>
                    <View style={CriminalCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Number of the years covered in the Police Verification
                      </Text>
                      <Text
                        style={{
                          width: "400px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                        }}
                      >
                        {criminalEvidence?.criminal?.yearsInPoliceVerification}
                      </Text>
                    </View>
                    <View style={CriminalCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Additional Comments
                      </Text>
                      <Text
                        style={{
                          width: "400px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                        }}
                      >
                        {criminalEvidence?.criminal?.additionalComments}
                      </Text>
                    </View>
                    <View style={CriminalCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Mode of Verification
                      </Text>
                      <Text
                        style={{
                          width: "400px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                        }}
                      >
                        {criminalEvidence?.criminal?.modeOfVerification}
                      </Text>
                    </View>
                    <View style={CriminalCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Name & Designation of the Verifier
                      </Text>
                      <Text
                        style={{
                          width: "400px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                        }}
                      >
                        {
                          criminalEvidence?.criminal
                            ?.nameAndDesignationOfTheVerifier
                        }
                      </Text>
                    </View>
                    <View style={CriminalCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Contact Details of the Verifier
                      </Text>
                      <Text
                        style={{
                          width: "400px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 8,
                        }}
                      >
                        {criminalEvidence?.criminal?.contactDetailsOfVerifier}
                      </Text>
                    </View>
                  </View>
                  {/* <View break /> */}
                  {criminalEvidence?.criminal?.uploadProofUrl?.map(
                    (url, proofIndex) => (
                      <View
                        key={proofIndex}
                        style={CriminalCheckStyles.centeredImageContainer}
                        wrap={false} // Prevent image splitting between pages
                        break // Page break to place image on a new page
                      >
                        <AsyncImage
                          fileUrl={url}
                          alt={`Criminal evidence ${proofIndex + 1}`}
                        />
                      </View>
                    )
                  )}
                </View>
                <View break />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };

  return null;
};
