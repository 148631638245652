import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "../../../services/APIServices";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import coin_logo from "../../../assets/images/coin_logo.png";
import { TabPanel, TabView } from "primereact/tabview";
import { Password } from "primereact/password";

function Signin() {
  useEffect(() => {
    // Injecting the anti-clickjacking style
    const antiClickjackStyle = document.createElement("style");
    antiClickjackStyle.id = "antiClickjack";
    antiClickjackStyle.innerHTML = "body{display:none !important;}";
    document.head.appendChild(antiClickjackStyle);

    // Injecting the anti-clickjacking script
    const antiClickjackScript = document.createElement("script");
    antiClickjackScript.type = "text/javascript";
    antiClickjackScript.innerHTML = `
      if (self === top) {
          var antiClickjack = document.getElementById("antiClickjack");
          if (antiClickjack) antiClickjack.parentNode.removeChild(antiClickjack);
      } else {
          top.location = self.location;
      }
    `;
    document.head.appendChild(antiClickjackScript);

    // Cleanup on component unmount
    return () => {
      if (antiClickjackStyle.parentNode) {
        antiClickjackStyle.parentNode.removeChild(antiClickjackStyle);
      }
      if (antiClickjackScript.parentNode) {
        antiClickjackScript.parentNode.removeChild(antiClickjackScript);
      }
    };
  }, []);

  const bannerlogo = process.env.REACT_APP_S3_URL
    ? `${process.env.REACT_APP_S3_URL}/public/assets/loginlogo.png`
    : coin_logo;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [email, setEmail] = useState(null);
  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex.index);
  };

  const checkScreenHeight = window.screen.height;
  let screen = "";
  if (checkScreenHeight <= 950) {
    screen = "h-screen";
  } else {
    screen = "h-screen";
  }

  const secretKey = process.env.REACT_APP_SECRET_KEY;

  const encryptField = (field) => {
    if (!field) return field; // If the field is empty or null, return as is
    return CryptoJS.AES.encrypt(field, secretKey).toString();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === null && userName === null && password === null) {
      toast.error("Please enter your email or username, and password.");
    } else {
      setIsLoading(true);
      await ApiService.post("signin", {
        email: email,
        userName: userName,
        password: encryptField(password),
      })
        .then(async (res) => {
          if (email === null) {
            toast.success("LoggedIn Successfully");
            localStorage.setItem("token", res?.data?.token); //JSON.stringify()
            localStorage.setItem("expiry", res?.data?.expiry);
            localStorage.setItem("refreshExpiry", res?.data?.refreshExpiry);
            localStorage.setItem("refreshToken", res?.data?.refreshToken);
            localStorage.setItem("user", JSON.stringify(res?.data?.user));

            let localStorageData = JSON.parse(localStorage.getItem("user"));

            if (localStorageData.role === "BCA") {
              navigate("/bca");
            } else if (localStorageData?.role === "Coordinator") {
              navigate("/bca");
            } else if (localStorageData?.role === "company") {
              navigate("/company");
            } else if (localStorageData?.role === "user") {
              navigate("/userLink");
            } else if (localStorageData?.role === "CCTAdmin") {
              navigate("/assign-bca");
            } else if (localStorageData?.role === "BCAStaff") {
              navigate("/staff-member-dashboard");
            } else if (localStorageData?.role === "DataEntry") {
              navigate("/cases");
            } else if (localStorageData?.role === "Auditor") {
              navigate("/cases");
            } else if (localStorageData?.role === "FieldCoordinator") {
              navigate("/team");
            } else if (localStorageData?.role === "subcompany") {
              navigate("/company");
            }
          } else {
            localStorage.setItem("userEmail", email);
            setIsLoading(false);

            navigate("/verify", {
              state: {
                email: email,
                formType: "login",
              },
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.response?.data?.error);
        });
    }
  };

  return (
    <>
      <div className="layout-wrapper">
        <div className={`main-wrapper w-full ${screen}`}>
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="loginbanner">
              <div className="p-[30%]">
                <img
                  src={bannerlogo}
                  alt="login-thumb"
                  className="h-[90%] w-[90%] "
                />
              </div>
            </div>
            <div className=" flex px-[20%] max-sm:px-[11%] py-[11%] min-h-screen max-sm:min-h-[67vh]">
              <form onSubmit={handleSubmit} className="w-full">
                <h3>Welcome!</h3>
                <p className="text-[15px]  font-medium">
                  Enter your credentials to access your account
                </p>
                <p className="text-[15px] text-yellow-500 font-medium">
                  Note: When you log in using OTP, you have only three attempts.
                  If you enter the wrong OTP more than three times, you will be
                  blocked for 30 minutes.
                </p>
                <TabView activeIndex={activeTab} onTabChange={handleTabChange}>
                  <TabPanel header="Login with Email">
                    <div>
                      <label
                        htmlFor="email"
                        className="text-black text-[15px] font-light mt-3 lg:mt-5"
                      >
                        Enter your email address
                      </label>
                      <div className="flex flex-col items-start">
                        <input
                          type="email"
                          className="h-[57px] p-[16px] bg-white rounded-[10px] shadow border border-zinc-400 border-opacity-95 block w-full mt-1 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          id="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel header="Login with UserName">
                    <div>
                      <label
                        htmlFor="userName"
                        className="text-black text-[15px] font-light mt-3 lg:mt-5"
                      >
                        Username
                      </label>
                      <div className="flex flex-col items-start">
                        <input
                          type="text"
                          className="h-[57px] p-[16px] bg-white rounded-[10px] shadow border border-zinc-400 border-opacity-95 block w-full mt-1 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          id="userName"
                          name="userName"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                        />
                      </div>
                      <label
                        htmlFor="password"
                        className="text-black text-[15px] font-light mt-3 lg:mt-5"
                      >
                        Password
                      </label>
                      <div className="flex flex-col items-start">
                        <Password
                          value={password}
                          className="w-full"
                          id="password"
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
                          toggleMask
                          feedback={false}
                        />
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
                <div className="mt-3">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full m-auto text-white h-[54px] bg-[#2D5AAF] rounded-[10px] shadow"
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Wait...
                      </>
                    ) : (
                      "Sign In"
                    )}
                  </button>
                </div>
                {process.env.REACT_APP_ENABLE_USER_SIGNUP === "true" ? (
                  <>
                    <div className="flex justify-center mt-3">
                      <span className="text-black text-[13px] font-normal">
                        Dont Have an Account ?<br />
                      </span>
                      &nbsp;
                      <span
                        role="presentation"
                        className="text-sky-800 text-[13px] cursor-pointer font-normal"
                        onClick={() => navigate("/signup")}
                      >
                        Sign Up
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signin;
