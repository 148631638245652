import React, { useEffect, useState } from "react";

import {
  StyleSheet,
  Text,
  View,
  Image,
  Page,
  Document,
  PDFViewer,
} from "@react-pdf/renderer";

import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import Sirius_Logo from "../../../assets/images/Sirius_Logo.png";
import { SiriusAddressDetailCheck } from "./SiriusAddressDetailCheck.jsx";
import { SiriusEducationDetailCheck } from "./SiriusEducationDetailCheck.jsx";
import { SiriusEmploymentDetailCheck } from "./SiriusEmploymentDetailCheck.jsx";
import { SiriusDrugDetailCheck } from "./SiriusDrugDetailCheck.jsx";
import { SiriusUanDetailCheck } from "./SiriusUanDetailCheck.jsx";
import { SiriusCreditDetailCheck } from "./SiriusCreditDetailCheck.jsx";
import { SiriusCriminalDetailCheck } from "./SiriusCriminalDetailCheck.jsx";
import { SiriusGlobalDatabaseDetailCheck } from "./SiriusGlobalDatabaseDetailCheck.jsx";
import { SiriusDisclaimer } from "./SiriusDisclaimer.jsx";

const getStatusColor = (status) => {
  switch (status) {
  case "Major Discrepancy":
    return "red";
  case "Minor Discrepancy":
    return "orange";
  case "Verified Clear":
    return "green";
  case "Insufficiency":
    return "#859bed";
  case "Unable to Verify":
    return "orange";
  case "Stop Check":
    return "gray";
  case "Rejected":
    return "red";
  case "Approved":
    return "green";
  case "Completed":
    return "green";
  case "InProgress":
    return "#859bed";
  case "Pending":
    return "orange";
  case "BcaApproval":
    return "green";
  case "BcaRejection":
    return "red";
  case "Positive":
    return "green";
  case "Negative":
    return "red";
  case "Recommended":
    return "yellow";
  case "Not Recommended":
    return "#859bed";
  case "Failed":
    return "red";
  case "Could Not Verified":
    return "red";
  default:
    return "yellow";
  }
};

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 75, // Increased top padding for header
    paddingBottom: 40, // Increased bottom padding for footer
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  headerContainer: {
    position: "absolute",
    top: 5,
    left: 75,
  },

  spaceBetween: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#3E3E3E",
  },

  titleContainer: {
    flexDirection: "row",
    marginTop: 10,
  },

  logo: { width: "440px", height: 110 },
  // heading: {
  //   textAlign: "center",
  //   marginTop: 40,
  //   fontSize: 14,
  //   fontWeight: "bold",
  //   textDecoration: "underline",
  // },
  headingContainer: {
    padding: 2,
    width: "100%",
    textAlign: "left",
    marginTop: 40,
  },
  heading: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "left",
    color: "#606960",
    marginLeft: "20px",
  },

  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: 15,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  labelCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontWeight: 900,
    fontSize: 8,
  },
  synopsis: {
    textAlign: "center",
    marginTop: 5,
    fontSize: 10,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  synopsistableCell: {
    flex: 1, // Normal cell width
    border: "1px solid black",
    textAlign: "left",
    padding: 5,
    fontSize: 10,
  },
  footer: {
    position: "absolute",
    height: "90px",
    bottom: 30,
    left: 0,
    right: 0,
  },
});

const checksStyle = StyleSheet.create({
  checksContainer: {
    marginTop: "20px",
  },
});

const executiveSummaryStyles = StyleSheet.create({
  table: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 10,
    border: "1px solid black",
  },

  // Header Row Style
  headerRow: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#d3d3d3", // Light gray background for header
    borderBottom: "1px solid black",
  },

  // Header Cell Style
  headerCell: {
    flex: 1,
    textAlign: "center",
    padding: 5,
    fontSize: 10,
    fontWeight: "bold",
    borderRight: "1px solid black",
  },

  // Row Style for Data
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid black",
  },

  // Cell Style for Data Rows
  cell: {
    flex: 1,
    textAlign: "center",
    padding: 8,
    fontSize: 10,
    borderRight: "1px solid black",
  },
});

const Header = () => (
  <View style={styles.headerContainer} fixed>
    <CompanyTitle />
  </View>
);

const CompanyTitle = () => (
  <View style={styles.titleContainer} fixed>
    <View>
      <Image style={styles.logo} src={Sirius_Logo} />
    </View>
  </View>
);

const formatDate = (dateString) => {
  let date;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};
const GeneralInfo = ({ verificationInfo }) => (
  <View style={styles.table}>
    {/* Row 1 */}
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
        }}
      >
        Name of the Client
      </Text>
      <Text style={styles.tableCell}>
        {verificationInfo?.companyInfo?.name}
      </Text>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: "900",
          fontSize: 8,
        }}
      >
        Case Received On
      </Text>
      <Text style={styles.tableCell}>
        {formatDate(verificationInfo?.finalReportDate)}
      </Text>
    </View>

    {/* Row 2 */}
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
        }}
      >
        Name of the Candidate
      </Text>
      <Text style={styles.tableCell}>
        {`${verificationInfo?.userInfo.firstName} ${verificationInfo?.userInfo.lastName}`}
      </Text>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
        }}
      >
        Case Completed On
      </Text>
      <Text style={styles.tableCell}>09 November 2016</Text>
    </View>
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
        }}
      >
        Employee number
      </Text>
      <Text style={styles.tableCell}>123</Text>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
        }}
      >
        Case completed by
      </Text>
      <Text style={styles.tableCell}>Not applicable</Text>
    </View>
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
        }}
      >
        Case reference number
      </Text>
      <Text style={styles.tableCell}>ABC/MUM/000</Text>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
        }}
      >
        Status
      </Text>
      <Text
        style={[
          styles.tableCell,
          { backgroundColor: getStatusColor(verificationInfo?.status) },
        ]}
      >
        {verificationInfo?.status}
      </Text>
    </View>
  </View>
);

const ExecutiveSummary = ({
  selectedChecks,
  addressData,
  educationData,
  experienceData,
  drugData,
  uanData,
  creditData,
  criminalData,
  globalDatabaseData,
}) => {
  // Helper function to chunk array into groups
  const chunkArray = (arr, size) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += size) {
      chunks.push(arr.slice(i, i + size));
    }
    return chunks;
  };

  // Collect all verification items
  const allVerifications = [];

  if (selectedChecks?.education) {
    educationData?.educationInfo?.forEach((item) => {
      allVerifications.push({
        service: "Education Verification",
        status: item?.education?.status,
        review: "No",
      });
    });
  }

  if (selectedChecks?.experience) {
    experienceData?.experienceInfo?.forEach((item) => {
      allVerifications.push({
        service: "Employment Verification",
        status: item?.experience?.status,
        review: "No",
      });
    });
  }

  if (selectedChecks?.address) {
    addressData?.addressInfo?.forEach((item) => {
      allVerifications.push({
        service: "Address Verification",
        status: item?.address?.status,
        review: "No",
      });
    });
  }

  if (selectedChecks?.drug) {
    drugData?.drugInfo?.forEach((item) => {
      allVerifications.push({
        service: "Drug Verification",
        status: item?.drug?.status,
        review: "No",
      });
    });
  }

  if (selectedChecks?.uan) {
    uanData?.uanInfo?.forEach((item) => {
      allVerifications.push({
        service: "UAN Verification",
        status: item?.uan?.status,
        review: "No",
      });
    });
  }

  if (selectedChecks?.credit) {
    creditData?.creditInfo?.forEach((item) => {
      allVerifications.push({
        service: "Credit Check",
        status: item?.credit?.status,
        review: "No",
      });
    });
  }

  if (selectedChecks?.criminal) {
    criminalData?.criminalInfo?.forEach((item) => {
      allVerifications.push({
        service: "Criminal Check",
        status: item?.criminal?.status,
        review: "No",
      });
    });
  }

  if (selectedChecks?.globalDatabase) {
    globalDatabaseData?.globalDatabaseInfo?.forEach((item) => {
      allVerifications.push({
        service: "GlobalDatabase Check",
        status: item?.globalDatabase?.status,
        review: "No",
      });
    });
  }

  // Split verifications into chunks of 5 items per page
  const chunkedVerifications = chunkArray(allVerifications, 12);

  return (
    <>
      {chunkedVerifications.map((chunk, pageIndex) => (
        <View
          key={pageIndex}
          style={[
            executiveSummaryStyles.table,
            pageIndex !== 0 && { marginTop: 40 },
          ]}
          break={pageIndex !== 0}
        >
          {/* Header - Show on first chunk only */}
          {pageIndex === 0 && (
            <View style={executiveSummaryStyles.headerRow}>
              <Text style={executiveSummaryStyles.headerCell}>
                Services requested
              </Text>
              <Text style={executiveSummaryStyles.headerCell}>
                Verification Remarks
              </Text>
              <Text style={executiveSummaryStyles.headerCell}>
                Client review required
              </Text>
            </View>
          )}

          {/* Content rows */}
          {chunk.map((item, index) => (
            <View key={index} style={executiveSummaryStyles.row}>
              <Text style={executiveSummaryStyles.cell}>{item.service}</Text>
              <Text
                style={[
                  executiveSummaryStyles.cell,
                  { backgroundColor: getStatusColor(item.status) },
                ]}
              >
                {item.status}
              </Text>
              <Text style={executiveSummaryStyles.cell}>{item.review}</Text>
            </View>
          ))}
        </View>
      ))}
    </>
  );
};
// Report document component
const ReportDocument = ({
  verificationInfo,
  selectedChecks,
  addressData,
  educationData,
  experienceData,
  drugData,
  uanData,
  creditData,
  criminalData,
  globalDatabaseData,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Header />
      <View style={styles.headingContainer}>
        <Text style={styles.heading}>Final Report</Text>
      </View>

      <GeneralInfo verificationInfo={verificationInfo} />
      <View>
        <Text> </Text>
      </View>
      <View>
        <Text style={styles.heading}>Executive Summary</Text>
      </View>

      <ExecutiveSummary
        selectedChecks={selectedChecks}
        addressData={addressData}
        educationData={educationData}
        experienceData={experienceData}
        drugData={drugData}
        uanData={uanData}
        creditData={creditData}
        criminalData={criminalData}
        globalDatabaseData={globalDatabaseData}
      />
    </Page>

    {selectedChecks?.education && educationData && (
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.education && educationData && (
            <>{educationData.renderEducationDetails()}</>
          )}
        </View>
      </Page>
    )}

    {selectedChecks?.experience && experienceData && (
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.experience && experienceData && (
            <>{experienceData.renderExperienceDetails()}</>
          )}
        </View>
      </Page>
    )}

    {selectedChecks?.address && addressData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.address && addressData && (
            <>{addressData.renderAddressDetails()}</>
          )}
        </View>
      </Page>
    )}

    {selectedChecks?.drug && drugData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.drug && drugData && (
            <>{drugData.renderDrugDetails()}</>
          )}
        </View>
      </Page>
    )}
    {selectedChecks?.uan && uanData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.uan && uanData && <>{uanData.renderUanDetails()}</>}
        </View>
      </Page>
    )}
    {selectedChecks?.credit && creditData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.credit && creditData && (
            <>{creditData.renderCreditDetails()}</>
          )}
        </View>
      </Page>
    )}

    {selectedChecks?.criminal && criminalData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.criminal && criminalData && (
            <>{criminalData.renderCriminalDetails()}</>
          )}
        </View>
      </Page>
    )}

    {selectedChecks?.globalDatabase && globalDatabaseData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.globalDatabase && globalDatabaseData && (
            <>{globalDatabaseData.renderGlobalDatabaseDetails()}</>
          )}
        </View>
      </Page>
    )}

    <Page size="A4" style={styles.page} wrap={true}>
      <Header />
      <View>
        <Text> </Text>
      </View>
      <SiriusDisclaimer />
    </Page>
  </Document>
);

export const SiriusReport = () => {
  const [selectedChecks, setSelectedChecks] = useState({
    address: true,
    education: true,
    experience: true,
    drug: true,
    uan: true,
    credit: true,
    criminal: true,
    globalDatabase: true,
  });
  const location = useLocation();
  const [isPreview, setIsPreview] = useState(false);
  const [verificationInfo, setVerificationInfo] = useState(null);
  const [addressData, setAddressData] = useState(null);
  const [educationData, setEducationData] = useState(null);
  const [experienceData, setExperienceData] = useState(null);
  const [drugData, setDrugData] = useState(null);
  const [uanData, setUanData] = useState(null);
  const [creditData, setCreditData] = useState(null);
  const [criminalData, setCriminalData] = useState(null);
  const [globalDatabaseData, setGlobalDatabaseData] = useState(null);

  const getUserVerificationReqInfo = async (userVerificationReqId) => {
    await ApiService.get(`userVerificationRequest/${userVerificationReqId}`)
      .then((res) => {
        setVerificationInfo(res?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("VerificationInfor>>>>", verificationInfo);
  }, [verificationInfo]);

  useEffect(() => {
    getUserVerificationReqInfo(location?.state?.id);
  }, [location?.state?.id]);

  const handleEducationDataUpdate = (data) => {
    setEducationData(data);
  };

  useEffect(() => {
    console.log("DTATTATATT>>>>>", educationData);
  }, [educationData]);

  const handleAddressDataUpdate = (data) => {
    setAddressData(data);
  };

  const handleExperienceDataUpdate = (data) => {
    setExperienceData(data);
  };

  const handleDrugDataUpdate = (data) => {
    setDrugData(data);
  };

  const handleUanDataUpdate = (data) => {
    setUanData(data);
  };

  const handleCreditDataUpdate = (data) => {
    setCreditData(data);
  };

  const handleCriminalDataUpdate = (data) => {
    setCriminalData(data);
  };

  const handleGlobalDatabaseDataUpdate = (data) => {
    setGlobalDatabaseData(data);
  };

  const togglePreview = () => {
    setIsPreview(!isPreview);
  };

  return (
    <div>
      <h1>Generate Background Report</h1>

      {/* Options to select sections for interim report */}
      {/* <div>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.education}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                education: !prev.education,
              }))
            }
          />{" "}
          Education
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.experience}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                experience: !prev.experience,
              }))
            }
          />{" "}
          Experience
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.address}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                address: !prev.address,
              }))
            }
          />
          Address
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.drug}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                drug: !prev.drug,
              }))
            }
          />
          Drug
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.uan}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                uan: !prev.uan,
              }))
            }
          />
          UAN
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.credit}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                credit: !prev.credit,
              }))
            }
          />
          Credit
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.criminal}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                criminal: !prev.criminal,
              }))
            }
          />
          Criminal
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.globalDatabase}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                globalDatabase: !prev.globalDatabase,
              }))
            }
          />
          GlobalDatabase
        </label>
      </div> */}
      <div>
        <style>
          {`
      .checkbox-container {
        display: flex; /* Enable flexbox for row layout */
        flex-wrap: wrap; /* Wrap items to the next line if needed */
        gap: 16px; /* Add spacing between checkboxes */
      }
    `}
        </style>
        <div className="checkbox-container">
          {verificationInfo?.checks?.map((check) => {
            const renderChecks = (() => {
              switch (check) {
              case "Education":
                return [{ label: "Education", key: "education" }];
              case "Experience":
                return [{ label: "Experience", key: "experience" }];
              case "Address":
                return [{ label: "Address", key: "address" }];
              case "CRIMINAL":
                return [{ label: "Criminal", key: "criminal" }];
              case "GlobalDatabase":
                return [{ label: "Global Database", key: "globalDatabase" }];
              case "Drug":
                return [{ label: "Drug", key: "drug" }];
              case "Credit":
                return [{ label: "Credit", key: "credit" }];
              case "UAN":
                return [{ label: "UAN", key: "uan" }];
              default:
                return [];
              }
            })();

            // If no checks to render, skip
            if (!renderChecks.length) return null;

            // Render all relevant checkboxes for the current `check`
            return renderChecks.map(({ label, key }) => (
              <label key={key}>
                <input
                  type="checkbox"
                  checked={selectedChecks[key]}
                  onChange={() =>
                    setSelectedChecks((prev) => ({
                      ...prev,
                      [key]: !prev[key],
                    }))
                  }
                />{" "}
                {label}
              </label>
            ));
          })}
        </div>
      </div>

      <button
        className="w-[130px] h-[35px] text-[12px] text-white bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60 p-2 mt-4 ml-4"
        onClick={togglePreview}
      >
        {isPreview ? "Close Preview" : "Preview Report"}
      </button>
      <SiriusAddressDetailCheck onDataUpdate={handleAddressDataUpdate} />
      <SiriusEducationDetailCheck onDataUpdate={handleEducationDataUpdate} />
      <SiriusEmploymentDetailCheck onDataUpdate={handleExperienceDataUpdate} />
      <SiriusDrugDetailCheck onDataUpdate={handleDrugDataUpdate} />
      <SiriusUanDetailCheck onDataUpdate={handleUanDataUpdate} />
      <SiriusCreditDetailCheck onDataUpdate={handleCreditDataUpdate} />
      <SiriusCriminalDetailCheck onDataUpdate={handleCriminalDataUpdate} />
      <SiriusGlobalDatabaseDetailCheck
        onDataUpdate={handleGlobalDatabaseDataUpdate}
      />

      {/* PDF Preview */}
      {isPreview && (
        <div style={{ height: "500px", marginTop: "20px" }}>
          <PDFViewer width="100%" height="500px">
            <ReportDocument
              //   data={sampleData}
              verificationInfo={verificationInfo}
              selectedChecks={selectedChecks}
              addressData={addressData}
              educationData={educationData}
              experienceData={experienceData}
              drugData={drugData}
              uanData={uanData}
              creditData={creditData}
              criminalData={criminalData}
              globalDatabaseData={globalDatabaseData}
            />
          </PDFViewer>
        </div>
      )}
    </div>
  );
};

export default SiriusReport;
