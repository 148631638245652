import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Link } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const CreditCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
    // height: "590px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

const getStatusColor = (status) => {
  switch (status) {
  case "Major Discrepancy":
    return "red";
  case "Minor Discrepancy":
    return "orange";
  case "Verified Clear":
    return "green";
  case "Insufficiency":
    return "#859bed";
  case "Unable to Verify":
    return "orange";
  case "Stop Check":
    return "gray";
  case "Rejected":
    return "red";
  case "Approved":
    return "green";
  case "Completed":
    return "green";
  case "InProgress":
    return "#859bed";
  case "Pending":
    return "orange";
  case "BcaApproval":
    return "green";
  case "BcaRejection":
    return "red";
  case "Positive":
    return "green";
  case "Negative":
    return "red";
  case "Recommended":
    return "yellow";
  case "Not Recommended":
    return "#859bed";
  case "Failed":
    return "red";
  case "Could Not Verified":
    return "red";
  default:
    return "yellow";
  }
};

const formatDate = (dateString) => {
  let date;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const VleaderCreditDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [creditInfo, setCreditInfo] = useState([]);

  const getCreditInfo = (id) => {
    if (id) {
      ApiService.get(`creditCheck/${id}`)
        .then((res) => {
          setCreditInfo(res?.data?.docs);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getCreditInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (creditInfo.length > 0) {
      onDataUpdate({
        creditInfo,
        renderCreditDetails: () => renderCreditDetails(),
      });
    }
  }, [creditInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      <Link href={imgSrc} className={className || ""} target="_blank">
        {alt || "Click here to view the image"}
      </Link>
    );
  };
  const renderCreditDetails = () => {
    return (
      <>
        <Text style={{ textAlign: "center", textDecoration: "underline" }}>
          Credit Verification
        </Text>
        {creditInfo?.length > 0 && (
          <>
            {creditInfo.map((creditEvidence, index) => (
              <React.Fragment key={index}>
                <View style={{ marginTop: "40px" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Credit Verification Report
                    </Text>

                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          marginRight: 5,
                        }}
                      >
                        Status:
                      </Text>
                      <View
                        style={{
                          backgroundColor: getStatusColor(
                            creditEvidence?.credit?.status
                          ),
                          paddingVertical: 2,
                          paddingHorizontal: 10,
                          borderRadius: 4,
                        }}
                      >
                        <Text
                          style={{
                            color: "black",
                            fontSize: 12,
                            fontWeight: "bold",
                          }}
                        >
                          {creditEvidence?.credit?.status}
                        </Text>
                      </View>
                    </View>
                  </View>
                  {/* Render table on one page */}
                  <View style={CreditCheckStyles.table}>
                    <View style={CreditCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 6,
                          fontSize: 10,
                          fontWeight: "bold",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Credit Checks –India Conducted
                      </Text>
                    </View>
                    <View style={CreditCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 6,
                          fontSize: 10,
                          fontWeight: "bold",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Antecedents Verified
                      </Text>
                    </View>
                    <View style={CreditCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 6,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Date of Verification
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 6,
                          fontSize: 8,
                        }}
                      >
                        {creditEvidence?.credit?.dateOfVerification
                          ? formatDate(
                            creditEvidence?.credit?.dateOfVerification
                          )
                          : "NA"}
                      </Text>
                    </View>
                    <View style={CreditCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 6,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Time of Verification
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 6,
                          fontSize: 8,
                        }}
                      >
                        {creditEvidence?.credit?.timeOfVerification}
                      </Text>
                    </View>

                    <View style={CreditCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 6,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Loan Defaulters List - Reserve Bank of India
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 6,
                          fontSize: 8,
                        }}
                      >
                        {creditEvidence?.credit?.loan}
                      </Text>
                    </View>
                    <View style={CreditCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 6,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Address Updated in CIBIL database Of Candidate
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 6,
                          fontSize: 8,
                        }}
                      >
                        {creditEvidence?.credit?.updatedAddressInCIBIL}
                      </Text>
                    </View>
                    <View style={CreditCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 6,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        CIBIL Score
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 6,
                          fontSize: 8,
                        }}
                      >
                        {creditEvidence?.credit?.cibilScore}
                      </Text>
                    </View>
                    <View style={CreditCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 6,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        PAN Card Number
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 6,
                          fontSize: 8,
                        }}
                      >
                        {creditEvidence?.credit?.panNumber}
                      </Text>
                    </View>
                    <View style={CreditCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 6,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Credit Score
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 6,
                          fontSize: 8,
                        }}
                      >
                        {creditEvidence?.credit?.creditScore}
                      </Text>
                    </View>
                    <View style={CreditCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 6,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Proof
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 6,
                          fontSize: 8,
                        }}
                      >
                        {creditEvidence?.credit?.uploadProofUrl?.map(
                          (url, proofIndex) => {
                            return (
                              <Text key={proofIndex} style={{ marginRight: 5 }}>
                                <AsyncImage
                                  fileUrl={url}
                                  alt={`Credit evidence ${proofIndex + 1}`}
                                />
                                {proofIndex <
                                  creditEvidence.credit.uploadProofUrl.length -
                                    1 && (
                                  <Text style={{ marginRight: 5 }}> , </Text>
                                )}
                              </Text>
                            );
                          }
                        )}
                      </Text>
                    </View>
                  </View>
                  {/* <View break /> */}
                </View>
                <View break />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };

  return null;
};
