import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const EmploymentCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    // height: "590px",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 40,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

// const getStatusColor = (status) => {
//   switch (status) {
//     case "Major Discrepancy":
//       return "red";
//     case "Minor Discrepancy":
//       return "orange";
//     case "Verified Clear":
//       return "green";
//     case "Insufficiency":
//       return "#859bed";
//     case "Unable to Verify":
//       return "orange";
//     case "Stop Check":
//       return "gray";
//     case "Rejected":
//       return "red";
//     case "Approved":
//       return "green";
//     case "Completed":
//       return "green";
//     case "InProgress":
//       return "#859bed";
//     case "Pending":
//       return "orange";
//     case "BcaApproval":
//       return "green";
//     case "BcaRejection":
//       return "red";
//     case "Positive":
//       return "green";
//     case "Negative":
//       return "red";
//     case "Recommended":
//       return "yellow";
//     case "Not Recommended":
//       return "#859bed";
//     case "Failed":
//       return "red";
//     case "Could Not Verified":
//       return "red";
//     default:
//       return "yellow";
//   }
// };

export const SiriusEmploymentDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [experienceInfo, setExperienceInfo] = useState([]);

  const getExperienceInfo = (id) => {
    ApiService.get(`experienceCheck/${id}`)
      .then((res) => {
        setExperienceInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getExperienceInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (experienceInfo.length > 0) {
      onDataUpdate({
        experienceInfo,
        renderExperienceDetails: () => renderExperienceDetails(),
      });
    }
  }, [experienceInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      <Image src={imgSrc} alt={alt || "image"} className={className || ""} />
    );
  };

  const renderExperienceDetails = () => {
    return (
      <>
        {experienceInfo?.length > 0 && (
          <>
            {experienceInfo.map((experienceEvidence, index) => (
              <React.Fragment key={index}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: 1,
                    marginTop: 50,
                  }}
                >
                  <Text
                    style={{
                      // textAlign: "left",
                      fontSize: 12,
                    }}
                  >
                    Detailed Report- Employment Verification
                  </Text>
                </View>

                <View key={index} style={EmploymentCheckStyles.table}>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Facts
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Information
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Verifier comments
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Name
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.nameOfCandidate}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {
                        experienceEvidence?.verificationDetails
                          ?.nameOfCandidateRemark
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Employee ID
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.employeeId}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {
                        experienceEvidence?.verificationDetails
                          ?.employeeIdRemark
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Designation
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.designation}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {
                        experienceEvidence?.verificationDetails
                          ?.designationRemark
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Department
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.department}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {
                        experienceEvidence?.verificationDetails
                          ?.departmentRemark
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Date of Joining
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.employmentStartDates}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {
                        experienceEvidence?.verificationDetails
                          ?.employmentStartDatesRemark
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Last working date
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.employmentEndDates}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {
                        experienceEvidence?.verificationDetails
                          ?.employmentEndDatesRemark
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      CTC per annum
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.salary}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.verificationDetails?.salaryRemark}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Verifier Name
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.verifierName}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {
                        experienceEvidence?.verificationDetails
                          ?.verifierNameRemark
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Any comments?
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.additionalRemarks}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.verificationDetails?.remark}
                    </Text>
                  </View>
                </View>

                {/* Loop through and display each proof image */}
                {experienceEvidence?.experience?.uploadProofUrl?.map(
                  (url, proofIndex) => (
                    <View
                      key={proofIndex}
                      style={EmploymentCheckStyles.centeredImageContainer}
                      wrap={false} // Ensure image stays on its own page
                      break={proofIndex !== 0} // Add page break after each image except the first one
                    >
                      <AsyncImage
                        fileUrl={url}
                        alt={`Employment evidence ${proofIndex + 1}`}
                      />
                    </View>
                  )
                )}

                <View break />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };
  return null;
};
