import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices";
import { StyleSheet, Text, View, Link } from "@react-pdf/renderer";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const AddressCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
    // height: "600px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
});

const getStatusColor = (status) => {
  switch (status) {
  case "Major Discrepancy":
    return "red";
  case "Minor Discrepancy":
    return "orange";
  case "Verified Clear":
    return "green";
  case "Insufficiency":
    return "#859bed";
  case "Unable to Verify":
    return "orange";
  case "Stop Check":
    return "gray";
  case "Rejected":
    return "red";
  case "Approved":
    return "green";
  case "Completed":
    return "green";
  case "InProgress":
    return "#859bed";
  case "Pending":
    return "orange";
  case "BcaApproval":
    return "green";
  case "BcaRejection":
    return "red";
  case "Positive":
    return "green";
  case "Negative":
    return "red";
  case "Recommended":
    return "yellow";
  case "Not Recommended":
    return "#859bed";
  case "Failed":
    return "red";
  case "Could Not Verified":
    return "red";
  default:
    return "yellow";
  }
};

export const VleaderAddressDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [addressInfo, setAddressInfo] = useState([]);

  const getAddressInfo = (id) => {
    ApiService.get(`addressCheck/${id}`)
      .then((res) => {
        setAddressInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const calculateMonthsAndDays = (fromDate, toDate) => {
    console.log("DATE>>", fromDate);

    const from = new Date(fromDate);
    const to = new Date(toDate);

    let yearsDiff = to.getFullYear() - from.getFullYear();
    let monthsDiff = to.getMonth() - from.getMonth();
    let daysDiff = to.getDate() - from.getDate();

    // Normalize negative days by adjusting months and days
    if (daysDiff < 0) {
      monthsDiff -= 1;
      const daysInPreviousMonth = new Date(
        to.getFullYear(),
        to.getMonth(),
        0
      ).getDate();
      daysDiff += daysInPreviousMonth;
    }

    // Normalize negative months by adjusting years and months
    if (monthsDiff < 0) {
      yearsDiff -= 1;
      monthsDiff += 12;
    }

    const totalMonths = yearsDiff * 12 + monthsDiff;

    return `${totalMonths} months ${daysDiff} days`;
  };

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getAddressInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (addressInfo.length > 0) {
      onDataUpdate({
        addressInfo,
        renderAddressDetails: () => renderAddressDetails(),
      });
    }
  }, [addressInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      <Link href={imgSrc} className={className || ""} target="_blank">
        {alt || "Click here to view the image"}
      </Link>
    );
  };

  const renderAddressDetails = () => {
    return (
      <>
        <View wrap>
          {addressInfo?.length > 0 && (
            <>
              {addressInfo.map((addressEvidence, index) => (
                <React.Fragment key={index}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: 5,
                      marginTop: 30,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Address Verification:
                    </Text>

                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          marginRight: 5,
                        }}
                      >
                        Check Status:
                      </Text>
                      <View
                        style={{
                          backgroundColor: getStatusColor(
                            addressEvidence?.address?.status
                          ),
                          paddingVertical: 2,
                          paddingHorizontal: 10,
                          borderRadius: 4,
                        }}
                      >
                        <Text
                          style={{
                            color: "black",
                            fontSize: 12,
                            fontWeight: "bold",
                          }}
                        >
                          {addressEvidence?.address?.status}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={AddressCheckStyles.table}>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 12,
                          fontWeight: "bold",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Details
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 8,
                          fontSize: 12,
                          fontWeight: "bold",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        As Provided
                      </Text>
                    </View>

                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Provided Address
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {addressEvidence?.address?.completeAddress}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Verified Address
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {addressEvidence?.address?.verifiedAddress}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Period of Stay
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {addressEvidence?.address?.stayFrom &&
                        addressEvidence?.address?.stayTo
                          ? calculateMonthsAndDays(
                            addressEvidence?.address?.stayFrom,
                            addressEvidence?.address?.stayTo
                          )
                          : "N/A"}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Residence Status
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {addressEvidence?.address?.residenceStatus}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Candidate’s marital status
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {addressEvidence?.address?.maritalStatus}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Members in family
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {addressEvidence?.address?.familyMembers}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Name of the person contacted
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {addressEvidence?.address?.personContacted}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Relationship with the candidate
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {addressEvidence?.address?.relationshipWithCandidate}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Contact Number
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {addressEvidence?.address?.contactNumber}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Any other comments
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {addressEvidence?.address?.anyOtherComments}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Proof
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {addressEvidence?.address?.uploadProofUrl?.map(
                          (url, proofIndex) => {
                            return (
                              <Text key={proofIndex} style={{ marginRight: 5 }}>
                                <AsyncImage
                                  fileUrl={url}
                                  alt={`Address evidence ${proofIndex + 1}`}
                                />
                                {proofIndex <
                                  addressEvidence.address.uploadProofUrl
                                    .length -
                                    1 && (
                                  <Text style={{ marginRight: 5 }}> , </Text>
                                )}
                              </Text>
                            );
                          }
                        )}
                      </Text>
                    </View>
                  </View>
                  <View break />
                </React.Fragment>
              ))}
            </>
          )}
        </View>
      </>
    );
  };
  return null;
};
