import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const AddressCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
    // height: "600px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 40,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

// const getStatusColor = (status) => {
//   switch (status) {
//     case "Major Discrepancy":
//       return "red";
//     case "Minor Discrepancy":
//       return "orange";
//     case "Verified Clear":
//       return "green";
//     case "Insufficiency":
//       return "blue";
//     case "Unable to Verify":
//       return "orange";
//     case "Stop Check":
//       return "gray";
//     case "Rejected":
//       return "red";
//     case "Approved":
//       return "green";
//     case "Completed":
//       return "green";
//     case "InProgress":
//       return "blue";
//     case "Pending":
//       return "orange";
//     case "BcaApproval":
//       return "green";
//     case "BcaRejection":
//       return "red";
//     default:
//       return "black";
//   }
// };

const formatDate = (dateString) => {
  let date;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const SiriusAddressDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [addressInfo, setAddressInfo] = useState([]);

  const getAddressInfo = (id) => {
    ApiService.get(`addressCheck/${id}`)
      .then((res) => {
        setAddressInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getAddressInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (addressInfo.length > 0) {
      onDataUpdate({
        addressInfo,
        renderAddressDetails: () => renderAddressDetails(),
      });
    }
  }, [addressInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
      <Image
        src={imgSrc}
        alt={alt || "image"}
        className={className || ""}
        style={{ height: "550px" }}
      />
    );
  };

  const renderAddressDetails = () => {
    return (
      <>
        {/* <View> */}
        {/* <Text style={{ textAlign: "center", textDecoration: "underline" }}>
            Address verification
          </Text> */}
        {addressInfo?.length > 0 && (
          <>
            {addressInfo.map((addressEvidence, index) => (
              <React.Fragment key={index}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: 5,
                    marginTop: 50,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  >
                    Detailed Report- Address Verification
                  </Text>
                </View>
                <View style={AddressCheckStyles.table}>
                  <View style={AddressCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Candidate&apos;s Information
                    </Text>
                  </View>
                  <View style={AddressCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                      }}
                    >
                      Candidate Name:
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {addressEvidence?.address?.nameOfCandidate}
                    </Text>
                  </View>
                  <View style={AddressCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                      }}
                    >
                      Complete Address:
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {addressEvidence?.address?.completeAddress}
                    </Text>
                  </View>
                  <View style={AddressCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                      }}
                    >
                      Father’s Name:
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {addressEvidence?.address?.fatherName}
                    </Text>
                  </View>
                  <View style={AddressCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                      }}
                    >
                      Date Of Visit:
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {formatDate(addressEvidence?.address?.dateOfVisit)}
                    </Text>
                  </View>
                  <View style={AddressCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                      }}
                    >
                      Landmark:
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {addressEvidence?.address?.landmark}
                    </Text>
                  </View>
                  <View style={AddressCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                      }}
                    >
                      Type Of Accommodation:
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {addressEvidence?.address?.accommodationType}
                    </Text>
                  </View>
                  <View style={AddressCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                      }}
                    >
                      Residential Status:
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {addressEvidence?.address?.residenceStatus}
                    </Text>
                  </View>
                  <View style={AddressCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                      }}
                    >
                      Period Of Stay
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {addressEvidence?.address?.periodOfStay}
                    </Text>
                  </View>
                  <View style={AddressCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                      }}
                    >
                      Person met on the address:
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {addressEvidence?.address?.personMet}
                    </Text>
                  </View>
                  <View style={AddressCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                      }}
                    >
                      Relation with the candidate
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {addressEvidence?.address?.relationWithCandidate}
                    </Text>
                  </View>
                  <View style={AddressCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                      }}
                    >
                      Conclusion & Additional Comments:
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {addressEvidence?.address?.additionalRemarks}
                    </Text>
                  </View>
                </View>
                {addressEvidence?.address?.uploadProofUrl?.map(
                  (url, proofIndex) => (
                    <View
                      key={proofIndex}
                      style={AddressCheckStyles.centeredImageContainer}
                      wrap={false} // Prevent image splitting between pages
                      break // Page break to place image on a new page
                    >
                      <AsyncImage
                        fileUrl={url}
                        alt={`Address evidence ${proofIndex + 1}`}
                      />
                    </View>
                  )
                )}
                <View break />
              </React.Fragment>
            ))}
          </>
        )}
        {/* </View> */}
      </>
    );
  };
  return null;
};
