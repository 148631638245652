import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Checkbox } from "primereact/checkbox";
import { getBase64 } from "../../Helper/Base64File";
import { InputTextarea } from "primereact/inputtextarea";
import Inefficiency from "../../components/UserProfileView/Checklist/Inefficiency.jsx";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
  modeOfVerificationRemark,
  CourseRemark,
  finalRemark,
  educationAnnexure,
  statusRemark,
  selectRemark,
  educationCategories,
  getSeverity,
  remarkStatus,
  modeOfVerification,
} from "../../utils/constants.js";
import { ValidateInput } from "../../Helper/ValidateInput.jsx";
import { customItemTemplate } from "../../Helper/UploadFileTemplate.jsx";
import { SplitButton } from "primereact/splitbutton";
import DeleteIcon from "@mui/icons-material/Delete";
// import { Modal } from "antd";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import NoData from "../../Helper/NoDataFound.jsx";
import { Calendar } from "primereact/calendar";

const EducationDetail = ({
  userVerificationDetail,
  userVerificationRequestInfo,
  taskDetail,
  educationCheckInfo,
  geteducationCheckInfo,
  activityDetails,
  showActivity,
  showReWork,
  getActivityDetails,
  flag,
}) => {
  let user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const [data, setData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [editStartDate, setEditStartDate] = useState(false);
  // const [editEndDate, setEditEndDate] = useState(false);
  // const [editGraduationDate, setEditGradutaionDate] = useState(false);
  // const [editGraduationRemarkDate, setEditGradutaionRemarkDate] =
  //   useState(false);
  const [editRemarkDate, setEditRemarkDate] = useState(false);
  const [fileUpload, SetFileUplaod] = useState([]);
  const [uploadProof, SetUploadProof] = useState([]);
  const [fileMap, setFileMap] = useState([]);
  const [proofImage, setProofImage] = useState([]);
  const [timer, setTimer] = useState(null);
  const [details, setDetails] = useState({});
  const [showAddStaff, setShowaddStaff] = useState(false);
  const [emailForVerification, setEmailForVerification] = useState("");
  const [activity, setActivity] = useState("");

  const [isEdit, setIsEdit] = useState({
    collegeName: false,
    universityName: false,
    startDate: false,
    endDate: false,
    enrollmentNumber: false,
    degreeName: false,
    yearOfPassing: false,
  });

  const updateCaseIsQCOpen = async (flag) => {
    let body = {
      status: flag,
    };
    ApiService.post(`userVerificationRequest/${userVerificationDetail}`, body)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("FILEURLS>>", fileUpload);
  }, [fileUpload]);

  useEffect(() => {
    console.log("PROOFURLS>>", uploadProof);
  }, [uploadProof]);

  const SIGNEDURL_EXPIRATION_TIME =
    process.env.REACT_APP_SIGNEDURL_EXPIRATION_TIME;

  let localStorageData = JSON.parse(localStorage.getItem("user"));

  // };
  const formatDateToDDMMYY = (isoDate) => {
    if (!isoDate) return "";
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
    const year = date.getFullYear();
    console.log("FORMATEDDATE>>", month, year);

    return `${day}/${month}/${year}`;
  };

  const toggleEdit = (field) => {
    setIsEdit((prevState) => ({
      ...prevState,

      [field]: !prevState[field], // Toggles the boolean value for the specific field
    }));
  };

  const sendEmailForVerification = (e, email, info) => {
    e.preventDefault();
    console.log("PROVIDED EMAIL", email);
    console.log("PROVIDED INFO", info);
    console.log(
      "CANDIDATE",
      taskDetail?.userVerificationRequest?.userInfo?.firstName
    );

    let linkUrl = `${process.env.REACT_APP_FRONTEND_BASE_URL}`;
    let candidate = `${taskDetail?.userVerificationRequest?.userInfo?.firstName} ${taskDetail?.userVerificationRequest?.userInfo?.lastName}`;

    ApiService.post("sendEmailForEducation", {
      email: email,
      info: info,
      url: linkUrl,
      candidate: candidate,
    })
      .then(() => {
        toast.success("Email Sent Successfully");
        setShowaddStaff(false);
        setEmailForVerification("");
      })
      .catch((error) => {
        console.error("Unable to send:", error);
        setShowaddStaff(false);
        setEmailForVerification("");
      });
  };

  const confirm1 = (id) => {
    if (localStorageData?.role === "user") {
      setIsModalOpen(true);
    }

    console.log("Delete clicked");
    console.log("ID>>", id);
    confirmDialog({
      message: (
        <div className="flex items-center pt-[17px]">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          <span className="pt-[2px]">Are you sure you want to DELETE?</span>
        </div>
      ),
      header: "Confirmation",
      accept: () => accept(id),
      // reject: () => setDialogVisible(false),
      // onHide: () => setDialogVisible(false),
    });
  };

  const accept = (id) => {
    deleteItem(id);
  };

  const deleteItem = (id) => {
    const url = `educationCheck/remove/${id}`;

    ApiService.post(url)
      .then(() => {
        // setDialogVisible(false);
        toast.success("Item Deleted Successfully");
        geteducationCheckInfo(userVerificationDetail);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Delete Item");
      });
  };

  const createActivity = async (action, caseId) => {
    let body = {
      userVerificationRequestId: caseId,
      // checkId: checkId,
      FEName:
        localStorageData?.role === "BCA"
          ? localStorageData?.name
          : localStorageData?.role === "BCAStaff"
            ? `${localStorageData?.firstName} ${localStorageData?.lastName}`
            : localStorageData?.role === "Coordinator"
              ? localStorageData?.vendorName
              : localStorageData?.role === "DataEntry"
                ? localStorageData?.vendorName
                : "",
      checkName: "Education",
      description: action,
      activityDetails: {
        action: action,
      },
    };
    await ApiService.post("caseActivity", body)
      .then(() => {
        getActivityDetails(userVerificationDetail);

        setActivity("");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Create Activity");
      });
  };

  const removeImg = (e, id, item, reportIndex, type) => {
    e.preventDefault();

    // Filter the array and exclude the selected URL by index

    if (type === "file") {
      const updatedUrls = item?.education?.reportUrl.filter(
        (_, index) => index !== reportIndex
      );
      updateEducationFileArray(e, id, item, updatedUrls);
    } else {
      const updatedUrls = item?.education?.uploadProofUrl.filter(
        (_, index) => index !== reportIndex
      );
      updateEducationProofArray(e, id, item, updatedUrls);
    }
  };

  const updateEducationFileArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "educationCheck/link";
    } else {
      url = "educationCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      education: {
        ...item.education,
        reportUrl: updatedReportUrls, // Set the updated reportUrl array here
        remark: item.education.remark,
        uploadProofUrl: [
          ...new Set([...item.education.uploadProofUrl, ...uploadProof]),
        ],
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      reason: {
        ...item.reason,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Education details updated successfully");
      SetFileUplaod([]); // Reset file upload state
      SetUploadProof([]); // Reset proof upload state
      if (flag === "false") {
        geteducationCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateEducationProofArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "educationCheck/link";
    } else {
      url = "educationCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      education: {
        ...item.education,
        reportUrl: [...new Set([...item.education.reportUrl, ...fileUpload])], // Set the updated reportUrl array here
        remark: item.education.remark,
        uploadProofUrl: updatedReportUrls,
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      reason: {
        ...item.reason,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Education details updated successfully");
      SetFileUplaod([]); // Reset file upload state
      SetUploadProof([]); // Reset proof upload state
      if (flag === "false") {
        geteducationCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateEducationDetails = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "educationCheck/link";
    } else {
      url = "educationCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      education: {
        ...data[index]?.education,
        reportUrl: [
          ...new Set([...data[index]?.education?.reportUrl, ...fileUpload]),
        ],
        remark: data[index]?.education?.remark,
        uploadProofUrl: [
          ...new Set([
            ...data[index]?.education?.uploadProofUrl,
            ...uploadProof,
          ]),
        ],
      },
      verificationDetails: {
        ...data[index]?.verificationDetails,
      },
      reason: {
        ...data[index]?.reason,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      // createActivity(
      //   remarkForActivity,
      //   userVerificationDetail,
      //   educationCheckInfo[0]._id
      // );
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Education details updated successfully");
      SetFileUplaod([]);
      SetUploadProof([]);
      if (flag === "false") {
        geteducationCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {}, [fileUpload]);
  const getDiscreteCallInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "educationCheck/link";
    } else {
      url = "educationCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then(() => {
        // SetFileUplaod(data?.education?.reportUrl || []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const updateTaskStatus = async () => {
  //   let body = {
  //     status: "Completed",
  //   };
  //   await ApiService.post(`task/status/${taskDetail?._id}`, body)
  //     .then(() => {
  //       toast.success("Task Submitted Successfully");
  //       navigate("/staff-member-dashboard");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  // const updateTaskStatusForReWork = async () => {
  //   let body = {
  //     status: "InProgress",
  //   };
  //   await ApiService.post(`task/status/${userVerificationRequestInfo}`, body)
  //     .then(() => {
  //       toast.success("Task Updated Successfully");
  //       // navigate("/staff-member-dashboard");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  const handleSaveClick = (e, id, index) => {
    e.preventDefault();
    updateEducationDetails(e, id, index);
    handleTaskForReWork();
  };

  const updateTaskStatus = async (taskId, status, flag) => {
    let body = {
      status: status,
    };
    await ApiService.post(`task/status/${taskId}`, body)
      .then(() => {
        if (status === "Completed") {
          toast.success("Task Submitted Successfully");
          updateCaseIsQCOpen(flag);
          navigate("/staff-member-dashboard");
        } else if (status === "Rework") {
          updateCaseIsQCOpen(flag);
          // navigate("/cases");
          toast.success("Task Updated Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  const handleSubmitTask = () => {
    updateTaskStatus(taskDetail?._id, "Completed", "QC-Open");
  };

  const handleTaskForReWork = () => {
    updateTaskStatus(userVerificationRequestInfo, "Rework", "Rework-WIP");
  };

  // const updateTaskItem = async (id, status) => {
  //   let body = {
  //     status: status,
  //     _id: id,
  //     objectName: "education",
  //   };
  //   await ApiService.post(`task/item/${taskDetail?._id}`, body)
  //     .then(() => {
  //       // toast.success("Task Item Updated Successfully");
  //       // navigate("/staff-member-dashboard");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  const onInputChange = (e, index, type, field) => {
    const { value } = e.target;
    if (ValidateInput(value, type)) {
      setData((prevData) => {
        // Create a copy of the original data array
        const newData = [...prevData];
        // Update the specific object at the given index
        if (field === "education") {
          newData[index] = {
            ...newData[index],
            education: {
              ...newData[index].education,
              [e.target.name]: e.target.value,
            },
          };
        } else if (field === "verificationDetails") {
          newData[index] = {
            ...newData[index],
            verificationDetails: {
              ...newData[index].verificationDetails,
              [e.target.name]: e.target.value,
            },
          };
        } else if (field === "reason") {
          newData[index] = {
            ...newData[index],
            reason: {
              ...newData[index].reason,
              [e.target.name]: e.target.value,
            },
          };
        }

        return newData;
      });
    }
  };

  useEffect(() => {
    setData(educationCheckInfo);
  }, [educationCheckInfo]);

  const updateCheckStatus = async (status, id) => {
    const body = { status: status };
    try {
      await ApiService.post(`educationCheck/education/status/${id}`, body);
      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      // getDiscreteCallInfo(userVerificationDetail);
      geteducationCheckInfo(userVerificationDetail);
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };

  const handleEdit = () => {
    setEditable(!editable);
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };
  const handleFileUpload = async (event, category) => {
    const allowedExtensions = /.(png|jpeg|pdf)$/i;
    const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
    const files = event.files;
    const fileData = [];

    for (let file of files) {
      // Split the filename by dots to check for multiple extensions
      const fileNameParts = file.name.split(".");

      // Validate file extension and check for multiple extensions
      if (!allowedExtensions.exec(file.name) || fileNameParts.length > 10) {
        toast.error(
          "Invalid file type. Only files with '.png','.jpeg', and '.pdf' extensions are allowed."
        );
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("Please choose a valid file size.");
        return;
      }

      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });
      const uploadedFileUrls = res.data.map((item) => item.url);

      console.log("uploadedFileUrls>>", uploadedFileUrls);

      switch (category) {
      case "file":
        SetFileUplaod(uploadedFileUrls);
        toast.success("File successfully uploaded");
        break;
      case "uploadProof":
        SetUploadProof(uploadedFileUrls);
        toast.success("Upload proof successfully uploaded");
        break;
      default:
        break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      toast.error("Error uploading file. Please try again.");
      // Handle error uploading files
    }
  };

  const clearImages = () => {
    setFileMap([]);
    setProofImage([]);
    toast.warning("Link expired, please download again.");
  };

  const getImg = (e, fileName, dataIndex, reportIndex, imgFlag) => {
    e.preventDefault();
    // Clear the existing timer if any
    if (timer) {
      clearTimeout(timer);
    }

    ApiService.get(`download/${fileName}`)
      .then((res) => {
        if (imgFlag === "img") {
          setFileMap((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
          // const newDownloadState = downloadState.map((state, i) =>
          //   i === index ? !state : state
          // );
          // setDownloadState(newDownloadState);
        } else {
          setProofImage((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
        }
        const newTimer = setTimeout(clearImages, SIGNEDURL_EXPIRATION_TIME); // 2 minutes
        setTimer(newTimer);
        toast.success("Document download Sucessfully ");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Image Download");
      });
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  console.log("data", data);

  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              createActivity(
                "Education Task Submitted!",
                userVerificationDetail,
                educationCheckInfo[0]._id
              );
              handleSubmitTask();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}

      {showReWork &&
        data?.map((item, dataIndex) =>
          dataIndex === 0 ? (
            <div
              key={dataIndex}
              className="border rounded shadow-md my-4 bg-[#fafafa]"
            >
              <div className="grid items-center sm:grid-cols-3 gap-x-4 mt-2 p-3">
                <div className="col-span-2">
                  <label
                    className="small mb-1 font-bold"
                    htmlFor="reWorkRemark"
                  >
                    Remark For Re-Work
                  </label>
                  <InputTextarea
                    className="form-control"
                    type="text"
                    name="reWorkRemark"
                    onChange={(e) =>
                      onInputChange(
                        e,
                        dataIndex,
                        "alphanumericWithAllowedSymbols",
                        "education"
                      )
                    }
                    value={item?.education?.reWorkRemark || ""}
                    // disabled={
                    //   flag === "true" || localStorageData?.role === "BCAStaff"
                    // }
                    rows={5}
                    cols={30}
                  />
                </div>
                {taskDetail?.status !== "InProgress" ? (
                  <div className="mt-[26px]">
                    <Button
                      className="w-[100px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      label="Submit"
                      type="submit"
                      loading={loading}
                      onClick={(e) => handleSaveClick(e, item._id, dataIndex)}
                      disabled={
                        flag === "true" || localStorageData?.role === "BCAStaff"
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
                {/* {taskDetail?.status !== "InProgress" ? (
                  <div className="mt-[26px]">
                    <Button
                      label="Submit"
                      type="button"
                      loading={loading}
                      onClick={() => handleTaskForReWork()}
                      className="w-[100px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      disabled={
                        flag === "true" || localStorageData?.role === "BCAStaff"
                      }
                    />
                  </div>
                ) : (
                  <></>
                )} */}
              </div>
              <div className="px-3 py-4 mt-4 bg-white shadow-md rounded">
                <label
                  className="small mb-1 font-bold"
                  htmlFor="submittedRemark"
                >
                  Submitted Remark:
                </label>
                <p className="text-gray-800 mt-2">
                  {item.education?.reWorkRemark || "No remark submitted"}
                </p>
              </div>
            </div>
          ) : null
        )}

      {showActivity && (
        <div className="border  rounded shadow-md my-4 bg-[#fafafa]">
          <div className="grid items-center sm:grid-cols-3 gap-x-4 mt-2 p-3 ">
            <div className="col-span-2">
              <label className="small mb-1 font-bold" htmlFor="activity">
                Activity
              </label>
              <textarea
                className="form-control h-[60px] "
                name="activity"
                onChange={(e) => {
                  setActivity(e.target.value);
                  // onInputChange(e, dataIndex, "alphanumeric");
                }}
                value={activity || ""}
              />
            </div>
            <div className="mt-[26px]">
              <Button
                label="Save"
                type="submit"
                loading={loading}
                onClick={(e) => {
                  e.preventDefault();
                  createActivity(
                    activity,
                    userVerificationDetail
                    // item?._id
                  );
                }}
                className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              />
            </div>
          </div>
          <div className="">
            <label className="small mb-1 px-3   font-bold" htmlFor="activity">
              All Activities List
            </label>
            <div className="h-[310px] p-3 overflow-y-scroll">
              <List
                sx={{
                  width: "100%",
                  // maxWidth: 450,
                  bgcolor: "#fafafa",
                }}
              >
                {activityDetails.length === 0 ? (
                  <>
                    <div className="flex items-center lg:ml-[50px] mt-[30px]">
                      <NoData info="Ooops, we could not find any activity" />
                    </div>
                  </>
                ) : (
                  <>
                    {activityDetails.map((item) => {
                      const createdAt = new Date(item?.createdAt);
                      const formattedDate =
                        createdAt.toLocaleDateString("en-GB");
                      const formattedTime =
                        createdAt.toLocaleTimeString("en-GB");

                      return (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            className="border rounded !mb-3 shadow-md bg-[#ffffff]"
                          >
                            <ListItemAvatar>
                              <Avatar
                                alt="Remy Sharp"
                                src="http://bootdey.com/img/Content/avatar/avatar1.png"
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Box className="flex flex-wrap item-center justify-between wrap">
                                  <Typography
                                    component="span"
                                    sx={{ fontWeight: "600" }} // Apply bold style here
                                    className="text-black text-wrap"
                                  >
                                    {item.FEName}
                                  </Typography>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    className="!font-bold text-black mt-1"
                                  >
                                    {formattedDate} {formattedTime}
                                  </Typography>
                                </Box>
                              }
                              className="!font-bold text-black"
                              secondary={
                                <React.Fragment>
                                  <Box
                                    component="span"
                                    sx={{
                                      display: "inline",
                                      color: "blue-300",
                                      marginRight: 1,
                                    }}
                                  >
                                    <Typography
                                      component="span"
                                      sx={{ color: "green" }}
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      Activity -
                                    </Typography>
                                  </Box>
                                  {item?.description}
                                  <br />
                                  {/* <Typography
                                sx={{ display: "inline", color: "green" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {item?.activityDetails?.action}
                              </Typography> */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          {/* <Divider variant="inset" component="li" /> */}
                        </>
                      );
                    })}
                  </>
                )}
              </List>
            </div>
          </div>{" "}
        </div>
      )}

      {data?.map((item, dataIndex) => (
        <>
          <Card
            title={item?.qualification}
            className={`sm:border-2 ${dataIndex === 0 ? "mt-2" : "mt-4"} relative`}
          >
            {localStorageData?.role !== "BCAStaff" && (
              <div
                className=" text-end cursor-pointer absolute right-[-11px] top-[-13px] "
                role="presentation"
                onClick={() => confirm1(item._id)}
              >
                <DeleteIcon
                  style={{ color: "red", height: "35px", width: "35px" }}
                />
                {/* <ConfirmDialog /> */}
              </div>
            )}
            {/* <div>
              <Button
                label="Edit"
                type="button"
                onClick={(e) => {
                  handleEditDate(e);
                }}
                className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              />
            </div> */}
            <div className="flex flex-col justify-end">
              {(() => {
                if (process.env.REACT_APP_IS_PAMAC_REPORT_ENABLED === "true") {
                  let user = JSON.parse(localStorage.getItem("user"));
                  const allowedRoles = [
                    "BCAStaff",
                    "BCA",
                    "Coordinator",
                    "Auditor",
                  ];

                  return (
                    <>
                      <div className="flex flex-row justify-between mb-[10px]">
                        <div className="flex gap-2">
                          {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                            "true" && (
                            <div className="flex flex-row gap-1">
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  e.preventDefault();
                                  createActivity(
                                    "Pending -> Approved",
                                    item?.userVerificationRequestId,
                                    item?._id
                                  );
                                  updateCheckStatus(
                                    "Approved",

                                    item?._id,
                                    dataIndex
                                  );
                                }}
                              >
                                <Button
                                  label="Approve"
                                  severity="success"
                                  icon="pi text-white pi-check-circle"
                                  size="small"
                                />
                              </div>
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  e.preventDefault();
                                  createActivity(
                                    "Pending -> Rejected",
                                    item?.userVerificationRequestId,
                                    item?._id
                                  );
                                  updateCheckStatus(
                                    "Rejected",

                                    item?._id,
                                    dataIndex
                                  );
                                }}
                              >
                                <Button
                                  label="Reject"
                                  icon="pi text-white pi-times-circle"
                                  severity="danger"
                                  size="small"
                                />
                              </div>
                            </div>
                          )}
                          {process.env.REACT_APP_SHOW_PAMAC_COLUMNS ===
                            "true" &&
                            allowedRoles.includes(user?.role) && (
                            <>
                              <SplitButton
                                label="Update Status"
                                icon="pi pi-check"
                                model={[
                                  {
                                    label: "Major Discrepancy",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Major Discrepancy",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Major Discrepancy",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Minor Discrepancy",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Minor Discrepancy",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Minor Discrepancy",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Verified Clear",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Verified Clear",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Verified Clear",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Insufficiency",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Insufficiency",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Insufficiency",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Unable to Verify",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Unable to Verify",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Unable to Verify",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Stop Check",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Stop Check",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Stop Check",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Positive",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Positive",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Positive",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Negative",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Negative",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Negative",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Recommended",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Recommended",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Recommended",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Not Recommended",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Not Recommended",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Not Recommended",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Failed",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Failed",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Failed",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Could Not Verified",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Could Not Verified",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Could Not Verified",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                ]}
                              />
                              <div className="ml-[20px]">
                                {getSeverity(item?.education?.status)}
                              </div>
                            </>
                          )}
                        </div>
                        {process.env.REACT_APP_IS_VLEADER_REPORT_ENABLED ===
                          "true" && (
                          <div>
                            <button
                              className="w-[100px]  text-white text-[10px] font-medium h-[40px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
                              onClick={() => {
                                setShowaddStaff(true);
                                setDetails({
                                  // documentId: item?._id,
                                  info: item?.education,
                                  // hrEmail: item?.experience?.hrEmail,
                                  // userVerificationRequestId:
                                  //   item?.userVerificationRequestId,
                                });
                              }}
                            >
                              send mail
                            </button>
                          </div>
                        )}
                      </div>
                    </>
                  );
                } 
                return (
                  flag === "true" && (
                    <>
                      <div className="flex flex-row justify-between mb-[10px]">
                        <div className="flex gap-2">
                          {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                              "true" && (
                            <div className="flex flex-row gap-1">
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  e.preventDefault();
                                  createActivity(
                                    "Pending -> Approved",
                                    item?.userVerificationRequestId,
                                    item?._id
                                  );
                                  updateCheckStatus(
                                    "Approved",

                                    item?._id,
                                    dataIndex
                                  );
                                }}
                              >
                                <Button
                                  label="Approve"
                                  severity="success"
                                  icon="pi text-white pi-check-circle"
                                  size="small"
                                />
                              </div>
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  e.preventDefault();
                                  createActivity(
                                    "Pending -> Rejected",
                                    item?.userVerificationRequestId,
                                    item?._id
                                  );
                                  updateCheckStatus(
                                    "Rejected",

                                    item?._id,
                                    dataIndex
                                  );
                                }}
                              >
                                <Button
                                  label="Reject"
                                  icon="pi text-white pi-times-circle"
                                  severity="danger"
                                  size="small"
                                />
                              </div>
                            </div>
                          )}
                          {process.env.REACT_APP_SHOW_PAMAC_COLUMNS ===
                              "true" && (
                            <SplitButton
                              label="Update Status"
                              icon="pi pi-check"
                              model={[
                                {
                                  label: "Major Discrepancy",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Major Discrepancy",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Major Discrepancy",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Minor Discrepancy",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Minor Discrepancy",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Minor Discrepancy",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Verified Clear",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Verified Clear",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Verified Clear",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Insufficiency",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Insufficiency",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Insufficiency",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Unable to Verify",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Unable to Verify",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Unable to Verify",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Stop Check",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Stop Check",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Stop Check",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Positive",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Positive",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Positive",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Negative",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Negative",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Negative",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Recommended",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Recommended",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Recommended",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Not Recommended",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Not Recommended",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Not Recommended",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Failed",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Failed",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Failed",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Could Not Verified",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Could Not Verified",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Could Not Verified",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                              ]}
                            />
                          )}

                          <div className="ml-[20px]">
                            {getSeverity(item?.education?.status)}
                          </div>
                        </div>
                        {process.env.REACT_APP_IS_VLEADER_REPORT_ENABLED ===
                            "true" && (
                          <div>
                            <button
                              className="w-[100px]  text-white text-[10px] font-medium h-[40px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
                              onClick={() => {
                                setShowaddStaff(true);
                                setDetails({
                                  // documentId: item?._id,
                                  info: item?.education,
                                  // hrEmail: item?.experience?.hrEmail,
                                  // userVerificationRequestId:
                                  //   item?.userVerificationRequestId,
                                });
                              }}
                            >
                                send mail
                            </button>
                          </div>
                        )}
                      </div>
                    </>
                  )
                );
                
              })()}
              {/* {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS === "true" && (
                <form>
                  <div className="row my-2">
                    {(process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" ||
                      process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                        "true") && (
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true"
                            ? "Affiliated to University/ Board"
                            : process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                                "true"
                              ? "University Name"
                              : ""}
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="unversityName"
                          onChange={(e) => onInputChange(e, dataIndex, "text")}
                          value={item?.education?.unversityName || ""}
                          disabled={
                            flag === "true"
                            
                          }
                        />
                      </div>
                    )}

                    {(process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" ||
                      process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                        "true") && (
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="yearOfPassing">
                          Year Of Passing
                        </label>
                        <InputText
                          className="form-control"
                          type="number"
                          name="yearOfPassing"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "numeric")
                          }
                          value={item?.education?.yearOfPassing || ""}
                          disabled={
                            flag === "true"
                            
                          }
                        />
                      </div>
                    )}
                    {(process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" ||
                      process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                        "true") && (
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Qualification
                        </label>
                        <Dropdown
                          value={item?.education?.qualification || ""}
                          onChange={(e) => onInputChange(e, dataIndex)}
                          options={educationCategories}
                          optionLabel="name"
                          optionValue="name"
                          placeholder="Select Qualification"
                          filter
                          valueTemplate={selectedCountryTemplate}
                          itemTemplate={countryOptionTemplate}
                          className="w-full md:w-14rem"
                          inputId="qualification"
                          name="qualification"
                          disabled={
                            flag === "true"
                            
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="row my-2">
                    {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "false" &&
                      process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                        "false" && (
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="schoolAddress">
                            School Address
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="schoolAddress"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.education?.schoolAddress || ""}
                            disabled={
                              flag === "true"
                              
                            }
                          />
                        </div>
                      )}

                    {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "false" &&
                      process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                        "false" && (
                        <div className="col-md-4">
                          <label
                            className="small mb-1"
                            htmlFor="unversityAddress"
                          >
                            University Address
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="unversityAddress"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.education?.unversityAddress || ""}
                            disabled={
                              flag === "true"
                              
                            }
                          />
                        </div>
                      )}

                    {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" && (
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true"
                            ? "Name of the College / Institute"
                            : "School Name"}
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="schoolName"
                          onChange={(e) => onInputChange(e, dataIndex, "text")}
                          value={item?.education?.schoolName || ""}
                          disabled={
                            flag === "true"
                            
                          }
                        />
                      </div>
                    )}
                    {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" && (
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Roll Number
                        </label>
                        <InputText
                          className="form-control"
                          type="number"
                          name="rollNumber"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "numeric")
                          }
                          value={item?.education?.rollNumber || ""}
                          disabled={
                            flag === "true"
                            
                          }
                        />
                      </div>
                    )}
                    {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" && (
                      <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <label className="small mb-1" htmlFor="reportNumber">
                          Contact Details
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="contactDetails"
                          maxLength={10}
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "numeric")
                          }
                          value={item?.education?.contactDetails || ""}
                          disabled={
                            flag === "true"
                            
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="row my-2">
                    {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                      "true" && (
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Enrollment no./Roll.no
                        </label>
                        <InputText
                          className="form-control"
                          type="number"
                          name="enrollmentNumber"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "alphanumeric")
                          }
                          value={item?.education?.enrollmentNumber || ""}
                          disabled={
                            flag === "true"
                            
                          }
                        />
                      </div>
                    )}
                    {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "false" &&
                      process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                        "false" && (
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="grade">
                            Final result/grade
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="grade"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.education?.grade || ""}
                            disabled={
                              flag === "true"
                              
                            }
                          />
                        </div>
                      )}
                  </div>

                  <div className="row my-2">
                    {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "false" &&
                      process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                        "false" && (
                        <div className="col-md-4">
                          <label
                            className="small mb-1"
                            htmlFor="modeOfEducation"
                          >
                            Mode Of Education
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="modeOfEducation"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.education?.modeOfEducation || ""}
                            disabled={
                              flag === "true"
                              
                            }
                          />
                        </div>
                      )}

                    {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "false" &&
                      process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                        "false" && (
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                          <label className="small mb-1" htmlFor="periodOfStudy">
                            Period Of Study
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="periodOfStudy"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.education?.periodOfStudy || ""}
                            disabled={
                              flag === "true"
                              
                            }
                          />
                        </div>
                      )}

                    {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" && (
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <label
                          className="small mb-1"
                          htmlFor="modeOfVerification"
                        >
                          Mode of Verification
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="modeOfVerification"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "alphanumeric")
                          }
                          value={item?.education?.modeOfVerification || ""}
                          disabled={
                            flag === "true"
                            
                          }
                        />
                      </div>
                    )}
                    {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" && (
                      <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <label className="small mb-1" htmlFor="reportNumber">
                          Verified By
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="verifiedBy"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "alphanumeric")
                          }
                          value={item?.education?.verifiedBy || ""}
                          disabled={
                            flag === "true"
                            
                          }
                        />
                      </div>
                    )}
                    {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" && (
                      <div className="col-md-8">
                        <label className="small mb-1" htmlFor="clientType">
                          Additional remarks if any
                        </label>
                        <InputTextarea
                          className="form-control"
                          type="text"
                          name="additionalRemarks"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "alphanumeric")
                          }
                          value={item?.education?.additionalRemarks || ""}
                          disabled={
                            flag === "true"
                            
                          }
                          rows={5}
                          cols={30}
                        />
                      </div>
                    )}

                    {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "false" &&
                      process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                        "false" && (
                        <div className="col-md-8">
                          <label className="small mb-1" htmlFor="clientType">
                            If there is any gap/break in education mention here
                            along with reasons:
                          </label>
                          <InputTextarea
                            className="form-control"
                            type="text"
                            name="comment"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.education?.comment || ""}
                            disabled={
                              flag === "true"
                              
                            }
                            rows={5}
                            cols={30}
                          />
                        </div>
                      )}

                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.education?.reportUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          multiple
                          auto
                          accept="image/*,application/pdf"
                          customUpload={true}
                          // maxFileSize={5000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          itemTemplate={customItemTemplate}
                          disabled={
                            flag === "true"
                            
                          }
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.education?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>

                    {user?.role !== "user" && (
                      <>
                        <div className="col-md-8 mt-2">
                          <label className="small mb-1" htmlFor="remark">
                            Remark
                          </label>
                          <textarea
                            className="form-control h-[110px]"
                            name="remark"
                            onChange={(e) => {
                              // setRemarkForActivity(e.target.value);
                              onInputChange(e, dataIndex, "alphanumeric");
                            }}
                            value={item?.education?.remark || ""}
                            disabled={
                              flag === "false"
                              
                            }
                          />
                        </div>
                        <div className="col-md-12 my-2">
                          <label className="small mb-1" htmlFor="clientType">
                            Upload Proof{" "}
                            <span className="opacity-70">
                              (Please Choose PDF less than 10MB)
                            </span>
                          </label>
                          {item?.education?.uploadProofUrl?.length === 0 ? (
                            <FileUpload
                              name="fileUpload[]"
                              auto
                              multiple
                              accept="image/*"
                              customUpload={true}
                              maxFileSize={10000000}
                              uploadHandler={(e) =>
                                handleFileUpload(e, "uploadProof")
                              }
                              itemTemplate={customItemTemplate}
                              disabled={
                                flag === "false"
                                
                              }
                            />
                          ) : (
                            ""
                          )}
                          <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                            {(item?.education?.uploadProofUrl || []).map(
                              (fileUrl, proofIndex) => {
                                // Extract file name from the URL
                                const fileName = fileUrl.split("/").pop();
                                const fileExtension = fileName
                                  .split(".")
                                  .pop()
                                  .toLowerCase();

                                const isImage = ["jpeg", "png"].includes(
                                  fileExtension
                                );

                                return (
                                  <li
                                    key={proofIndex}
                                    className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                  >
                                    <Button
                                      label="Download"
                                      onClick={(e) =>
                                        getImg(
                                          e,
                                          fileName,
                                          dataIndex,
                                          proofIndex,
                                          "proof"
                                        )
                                      }
                                      className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                    />
                                    <div className="text-center mt-2">
                                      {isImage &&
                                        proofImage[dataIndex]?.[proofIndex] && (
                                          <a
                                            href={
                                              proofImage[dataIndex][proofIndex]
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            download={`file_${dataIndex}_${proofIndex}`}
                                          >
                                            View Image
                                          </a>
                                        )}
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>{" "}
                      </>
                    )}
                  </div>
                  <div></div>
                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.education?.status === "Pending" ? (
                      item?.education?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.education?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateEducationDetails(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.education?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.education?.status)}</div>
                    )}
                  </div>
                </form>
              )} */}
              {process.env.REACT_APP_IS_PAMAC_REPORT_ENABLED === "true" && (
                <form>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Qualification
                      </label>
                      <Dropdown
                        value={item?.education?.qualification || ""}
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        options={educationCategories}
                        optionLabel="name"
                        optionValue="name"
                        placeholder="Select Qualification"
                        filter
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                        className="w-full md:w-14rem"
                        inputId="qualification"
                        name="qualification"
                      />
                    </div>
                    {user.role !== "user" && (
                      <>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Remark
                          </label>
                          <Dropdown
                            value={
                              item?.verificationDetails?.qualificationRemark ||
                              ""
                            }
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "text",
                                "verificationDetails"
                              )
                            }
                            options={remarkStatus}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select"
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem"
                            inputId="qualificationRemark"
                            name="qualificationRemark"
                          />
                        </div>
                        <div className="col-md-4">
                          <label
                            className="small mb-1"
                            htmlFor="entityName"
                          ></label>
                          {item?.verificationDetails?.qualificationRemark ===
                            "Not Verified" && (
                            <InputText
                              className="form-control"
                              type="text"
                              name="reasonOfQualification"
                              placeholder="Enter details"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "text", "reason")
                              }
                              value={item?.reason?.reasonOfQualification || ""}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Name of the College / Institute
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="schoolName"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.schoolName || ""}
                      />
                    </div>
                    {user.role !== "user" && (
                      <>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Remark
                          </label>
                          <Dropdown
                            value={
                              item?.verificationDetails?.schoolNameRemark || ""
                            }
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "text",
                                "verificationDetails"
                              )
                            }
                            options={remarkStatus}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select"
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem"
                            inputId="schoolNameRemark"
                            name="schoolNameRemark"
                          />
                        </div>
                        <div className="col-md-4">
                          <label
                            className="small mb-1"
                            htmlFor="entityName"
                          ></label>
                          {item?.verificationDetails?.schoolNameRemark ===
                            "Not Verified" && (
                            <InputText
                              className="form-control"
                              type="text"
                              name="reasonOfSchoolName"
                              placeholder="Enter details"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "text", "reason")
                              }
                              value={item?.reason?.reasonOfSchoolName || ""}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Affiliated to University/ Board
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="unversityName"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.unversityName || ""}
                      />
                    </div>
                    {user.role !== "user" && (
                      <>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Remark
                          </label>
                          <Dropdown
                            value={
                              item?.verificationDetails?.unversityNameRemark ||
                              ""
                            }
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "text",
                                "verificationDetails"
                              )
                            }
                            options={remarkStatus}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select"
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem"
                            inputId="unversityNameRemark"
                            name="unversityNameRemark"
                          />
                        </div>
                        <div className="col-md-4">
                          <label
                            className="small mb-1"
                            htmlFor="entityName"
                          ></label>
                          {item?.verificationDetails?.unversityNameRemark ===
                            "Not Verified" && (
                            <InputText
                              className="form-control"
                              type="text"
                              name="reasonOfUnversityName"
                              placeholder="Enter details"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "text", "reason")
                              }
                              value={item?.reason?.reasonOfUnversityName || ""}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Year Of Passing
                      </label>
                      {/* {item?.education?.yearOfPassing !== "" &&
                        editGraduationDate === false && (
                          <i
                            className="pi pi-pencil"
                            style={{
                              fontSize: "1rem",
                              color: "#075B86",
                              cursor: "pointer",
                              marginLeft: 5,
                            }}
                            onClick={() =>
                              setEditGradutaionDate(!editGraduationDate)
                            }
                            role="button"
                            tabIndex="0"
                            aria-label="Edit Date"
                          ></i>
                        )}
                      <br />
                      {item?.education?.yearOfPassing === "" ||
                      editGraduationDate === true ? (
                        <>
                          <Calendar
                            value={item?.education?.yearOfPassing || ""}
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "education"
                              )
                            }
                            name="yearOfPassing"
                            placeholder="MM/YYYY"
                            dateFormat="mm/yy"
                            showIcon
                            // readOnlyInput
                            className="!w-[350px]"
                          />
                        </>
                      ) : (
                        <> */}
                      <InputText
                        className="form-control"
                        type="Date"
                        name="yearOfPassing"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols",
                            "education"
                          )
                        }
                        value={item?.education?.yearOfPassing || ""}
                      />
                      {/* </>
                      )} */}
                    </div>
                    {user.role !== "user" && (
                      <>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Remark
                          </label>
                          <Dropdown
                            value={
                              item?.verificationDetails?.yearOfPassingRemark ||
                              ""
                            }
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "text",
                                "verificationDetails"
                              )
                            }
                            options={remarkStatus}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select"
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem"
                            inputId="yearOfPassingRemark"
                            name="yearOfPassingRemark"
                            // disabled={
                            //   flag === "false" ||
                            //   localStorageData?.role === "Auditor"
                            // }
                          />
                        </div>
                        <div className="col-md-4">
                          <label
                            className="small mb-1"
                            htmlFor="entityName"
                          ></label>
                          {item?.verificationDetails?.yearOfPassingRemark ===
                            "Not Verified" && (
                            <InputText
                              className="form-control"
                              type="text"
                              name="reasonOfYearOfPassing"
                              placeholder="Enter details"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "reason"
                                )
                              }
                              value={item?.reason?.reasonOfYearOfPassing || ""}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Roll Number
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="rollNumber"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumeric",
                            "education"
                          )
                        }
                        value={item?.education?.rollNumber || ""}
                      />
                    </div>
                    {user.role !== "user" && (
                      <>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Remark
                          </label>
                          <Dropdown
                            value={
                              item?.verificationDetails?.rollNumberRemark || ""
                            }
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "text",
                                "verificationDetails"
                              )
                            }
                            options={remarkStatus}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select"
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem"
                            inputId="rollNumberRemark"
                            name="rollNumberRemark"
                          />
                        </div>
                        <div className="col-md-4">
                          <label
                            className="small mb-1"
                            htmlFor="entityName"
                          ></label>
                          {item?.verificationDetails?.rollNumberRemark ===
                            "Not Verified" && (
                            <InputText
                              className="form-control"
                              type="text"
                              name="reasonOfRollNumber"
                              placeholder="Enter details"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumeric",
                                  "reason"
                                )
                              }
                              value={item?.reason?.reasonOfRollNumber || ""}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row my-2">
                    <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <label className="small mb-1" htmlFor="reportNumber">
                        Contact Details
                      </label>
                      <InputText
                        className="form-control"
                        type="int"
                        name="contactDetails"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "numeric", "education")
                        }
                        value={item?.education?.contactDetails || ""}
                      />
                    </div>
                  </div>
                  {user.role !== "user" && (
                    <>
                      <div className="row my-2">
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                          <label
                            className="small mb-1"
                            htmlFor="modeOfVerification"
                          >
                            Mode of Verification
                          </label>

                          <Dropdown
                            value={item?.education?.modeOfVerification || ""}
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumeric",
                                "education"
                              )
                            }
                            options={modeOfVerification}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select"
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem"
                            inputId="modeOfVerification"
                            name="modeOfVerification"
                          />
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                          <label className="small mb-1" htmlFor="reportNumber">
                            Verified By
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="verifiedBy"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text", "education")
                            }
                            value={item?.education?.verifiedBy || ""}
                          />
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-md-8">
                          <label className="small mb-1" htmlFor="clientType">
                            Additional remarks if any
                          </label>
                          <InputTextarea
                            className="form-control"
                            type="text"
                            name="additionalRemarks"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumeric",
                                "education"
                              )
                            }
                            value={item?.education?.additionalRemarks || ""}
                            rows={5}
                            cols={30}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.education?.reportUrl?.length === 0 ||
                      editable === true ? (
                          <FileUpload
                            name="fileUpload[]"
                            multiple
                            auto
                            accept="image/*,application/pdf"
                            customUpload={true}
                            // maxFileSize={5000000}
                            uploadHandler={(e) => handleFileUpload(e, "file")}
                            itemTemplate={customItemTemplate}
                          />
                        ) : (
                          ""
                        )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.education?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[10px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>

                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>

                    {user?.role !== "user" && (
                      <>
                        <div className="col-md-8 mt-2">
                          <label className="small mb-1" htmlFor="remark">
                            Remark
                          </label>
                          <textarea
                            className="form-control h-[110px]"
                            name="remark"
                            onChange={(e) => {
                              // setRemarkForActivity(e.target.value);
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumeric",
                                "education"
                              );
                            }}
                            value={item?.education?.remark || ""}
                          />
                        </div>
                        <div className="col-md-12 my-2">
                          <label className="small mb-1" htmlFor="clientType">
                            Upload Proof{" "}
                            <span className="opacity-70">
                              (Please Choose JPEG,PNG Only)
                            </span>
                          </label>
                          {item?.education?.uploadProofUrl?.length === 0 ? (
                            <FileUpload
                              name="fileUpload[]"
                              auto
                              multiple
                              accept="image/*"
                              customUpload={true}
                              maxFileSize={10000000}
                              uploadHandler={(e) =>
                                handleFileUpload(e, "uploadProof")
                              }
                              itemTemplate={customItemTemplate}
                            />
                          ) : (
                            ""
                          )}
                          <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                            {(item?.education?.uploadProofUrl || []).map(
                              (fileUrl, proofIndex) => {
                                // Extract file name from the URL
                                const fileName = fileUrl.split("/").pop();
                                const fileExtension = fileName
                                  .split(".")
                                  .pop()
                                  .toLowerCase();

                                const isImage = ["jpeg", "png"].includes(
                                  fileExtension
                                );

                                return (
                                  <li
                                    key={proofIndex}
                                    className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                  >
                                    <div
                                      role="presentation"
                                      onClick={(e) =>
                                        removeImg(
                                          e,
                                          item._id,
                                          item,
                                          proofIndex,
                                          "proof"
                                        )
                                      } // Pass the `item` to removeImg
                                      className="cursor-pointer"
                                    >
                                      <i
                                        className="pi pi-times"
                                        style={{ color: "red" }}
                                      ></i>
                                    </div>
                                    <Button
                                      label="Download"
                                      onClick={(e) =>
                                        getImg(
                                          e,
                                          fileName,
                                          dataIndex,
                                          proofIndex,
                                          "proof"
                                        )
                                      }
                                      className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                    />
                                    <div className="text-center mt-2">
                                      {isImage &&
                                        proofImage[dataIndex]?.[proofIndex] && (
                                        <a
                                          href={
                                            proofImage[dataIndex][proofIndex]
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          download={`file_${dataIndex}_${proofIndex}`}
                                        >
                                            View Image
                                        </a>
                                      )}
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>{" "}
                      </>
                    )}
                  </div>
                  <div></div>

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.education?.status === "Pending" ? (
                        item?.education?.reportUrl?.length > 0 ? (
                          <Button
                            label="Edit"
                            type="button"
                            onClick={(e) => {
                              handleEdit(e);
                            }}
                            className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    {/* {item?.education?.status === "Pending" ? ( */}
                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateEducationDetails(e, item._id, dataIndex);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.education?.status)}</div>
                    </div>
                    {/* ) : ( */}
                    {/* <div>{getSeverity(item?.education?.status)}</div> */}
                    {/* )} */}
                  </div>
                </form>
              )}

              {process.env.REACT_APP_IS_GVS_REPORT_ENABLED === "true" && (
                <form>
                  {/* <div className="row my-2"> */}
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Qualification
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="qualification"
                        value={item?.education?.qualification || ""}
                        // onChange={(e) =>
                        //   onInputChange(e, dataIndex, "text", "education")
                        // }
                        disabled={true}
                      />
                    </div>
                    {localStorageData?.role !== "user" && (
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Remark
                        </label>
                        <Dropdown
                          value={item?.verificationDetails?.qualificationRemark}
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "text",
                              "verificationDetails"
                            )
                          }
                          options={statusRemark}
                          optionLabel="name"
                          optionValue="name"
                          placeholder="Select Remark"
                          filter
                          valueTemplate={selectedCountryTemplate}
                          itemTemplate={countryOptionTemplate}
                          className="w-full md:w-14rem"
                          inputId="qualificationRemark"
                          name="qualificationRemark"
                          readOnly={
                            flag === "false" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Institute Name
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="instituteName"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.instituteName || ""}
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    {localStorageData?.role !== "user" && (
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Remark
                        </label>
                        <Dropdown
                          value={item?.verificationDetails?.instituteNameRemark}
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "verificationDetails"
                            )
                          }
                          options={statusRemark}
                          optionLabel="name"
                          optionValue="name"
                          placeholder="Select Remark"
                          filter
                          valueTemplate={selectedCountryTemplate}
                          itemTemplate={countryOptionTemplate}
                          className="w-full md:w-14rem"
                          inputId="instituteNameRemark"
                          name="instituteNameRemark"
                          readOnly={
                            flag === "false" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4 my-1">
                      <label className="small mb-1" htmlFor="entityName">
                        Degree Name
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="degreeName"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.degreeName || ""}
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    {localStorageData?.role !== "user" && (
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Remark
                        </label>
                        <Dropdown
                          value={item?.verificationDetails?.degreeNameRemark}
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "verificationDetails"
                            )
                          }
                          options={statusRemark}
                          optionLabel="name"
                          optionValue="name"
                          placeholder="Select Remark"
                          filter
                          valueTemplate={selectedCountryTemplate}
                          itemTemplate={countryOptionTemplate}
                          className="w-full md:w-14rem"
                          inputId="degreeNameRemark"
                          name="degreeNameRemark"
                          readOnly={
                            flag === "false" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4 my-1">
                      <label className="small mb-1" htmlFor="entityName">
                        Affiliated to University / Board
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="unversityName"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.unversityName || ""}
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>

                    {localStorageData?.role !== "user" && (
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Remark
                        </label>
                        <Dropdown
                          value={
                            item?.verificationDetails?.universityNameRemark
                          }
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "verificationDetails"
                            )
                          }
                          options={statusRemark}
                          optionLabel="name"
                          optionValue="name"
                          placeholder="Select Remark"
                          filter
                          valueTemplate={selectedCountryTemplate}
                          itemTemplate={countryOptionTemplate}
                          className="w-full md:w-14rem"
                          inputId="universityNameRemark"
                          name="universityNameRemark"
                          readOnly={
                            flag === "false" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="yearOfPassing">
                        Year Of Passing
                      </label>
                      <InputText
                        className="form-control"
                        type="number"
                        name="yearOfPassing"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "numeric", "education")
                        }
                        value={item?.education?.yearOfPassing || ""}
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    {localStorageData?.role !== "user" && (
                      <>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Remark
                          </label>

                          <Dropdown
                            value={
                              item?.verificationDetails?.yearOfPassingRemark
                            }
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "verificationDetails"
                              )
                            }
                            options={statusRemark}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select Remark"
                            filter
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem"
                            inputId="yearOfPassingRemark"
                            name="yearOfPassingRemark"
                            readOnly={
                              flag === "false" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Select
                          </label>
                          <Dropdown
                            value={
                              item?.verificationDetails?.selectRemark || ""
                            }
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "verificationDetails"
                              )
                            }
                            options={selectRemark}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select"
                            filter
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem"
                            inputId="selectRemark"
                            name="selectRemark"
                            readOnly={
                              flag === "false" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4 my-1">
                      <label className="small mb-1" htmlFor="entityName">
                        Roll Number
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="rollNumber"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "numeric", "education")
                        }
                        value={item?.education?.rollNumber || ""}
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>

                    {localStorageData?.role !== "user" && (
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Remark
                        </label>
                        <Dropdown
                          value={item?.verificationDetails?.rollNumberRemark}
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "verificationDetails"
                            )
                          }
                          options={statusRemark}
                          optionLabel="name"
                          optionValue="name"
                          placeholder="Select Remark"
                          filter
                          valueTemplate={selectedCountryTemplate}
                          itemTemplate={countryOptionTemplate}
                          className="w-full md:w-14rem"
                          inputId="rollNumberRemark"
                          name="rollNumberRemark"
                          readOnly={
                            flag === "false" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4 my-1">
                      <label className="small mb-1" htmlFor="entityName">
                        Enrollment Number
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="enrollmentNumber"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumeric",
                            "education"
                          )
                        }
                        value={item?.education?.enrollmentNumber || ""}
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>

                    {localStorageData?.role !== "user" && (
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Remark
                        </label>
                        <Dropdown
                          value={
                            item?.verificationDetails?.enrollmentNumberRemark
                          }
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "verificationDetails"
                            )
                          }
                          options={statusRemark}
                          optionLabel="name"
                          optionValue="name"
                          placeholder="Select Remark"
                          filter
                          valueTemplate={selectedCountryTemplate}
                          itemTemplate={countryOptionTemplate}
                          className="w-full md:w-14rem"
                          inputId="enrollmentNumberRemark"
                          name="enrollmentNumberRemark"
                          readOnly={
                            flag === "false" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4 my-1">
                      <label className="small mb-1" htmlFor="entityName">
                        Regestration Number
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="regestrationNumber"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumeric",
                            "education"
                          )
                        }
                        value={item?.education?.regestrationNumber || ""}
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>

                    {localStorageData?.role !== "user" && (
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Remark
                        </label>
                        <Dropdown
                          value={
                            item?.verificationDetails?.regestrationNumberRemark
                          }
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "verificationDetails"
                            )
                          }
                          options={statusRemark}
                          optionLabel="name"
                          optionValue="name"
                          placeholder="Select Remark"
                          filter
                          valueTemplate={selectedCountryTemplate}
                          itemTemplate={countryOptionTemplate}
                          className="w-full md:w-14rem"
                          inputId="regestrationNumberRemark"
                          name="regestrationNumberRemark"
                          readOnly={
                            flag === "false" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4 my-1">
                      <label className="small mb-1" htmlFor="entityName">
                        Last exam Hall Ticket Number
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="lastExamHallTicketNumber"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumeric",
                            "education"
                          )
                        }
                        value={item?.education?.lastExamHallTicketNumber || ""}
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>

                    {localStorageData?.role !== "user" && (
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Remark
                        </label>
                        <Dropdown
                          value={
                            item?.verificationDetails
                              ?.lastExamHallTicketNumberRemark
                          }
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "verificationDetails"
                            )
                          }
                          options={statusRemark}
                          optionLabel="name"
                          optionValue="name"
                          placeholder="Select Remark"
                          filter
                          valueTemplate={selectedCountryTemplate}
                          itemTemplate={countryOptionTemplate}
                          className="w-full md:w-14rem"
                          inputId="lastExamHallTicketNumberRemark"
                          name="lastExamHallTicketNumberRemark"
                          readOnly={
                            flag === "false" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      </div>
                    )}
                  </div>

                  {localStorageData?.role !== "user" && (
                    <>
                      {" "}
                      <div className="row my-2">
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="entityName">
                            Mode of Verification
                          </label>
                          <Dropdown
                            value={item?.education?.modeOfVerification}
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text", "education")
                            }
                            options={modeOfVerificationRemark}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select Remark"
                            filter
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem"
                            inputId="modeOfVerification"
                            name="modeOfVerification"
                            readOnly={
                              flag === "false" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>

                        {localStorageData?.role !== "user" && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Remark
                            </label>
                            <Dropdown
                              value={
                                item?.verificationDetails
                                  ?.modeOfVerificationRemark
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="modeOfVerificationRemark"
                              name="modeOfVerificationRemark"
                              readOnly={
                                flag === "false" ||
                                localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className="row my-2">
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="entityName">
                            Verified BY
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="verifiedBy"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text", "education")
                            }
                            value={item?.education?.verifiedBy || ""}
                            readOnly={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>

                        {localStorageData?.role !== "user" && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Remark
                            </label>
                            <Dropdown
                              value={
                                item?.verificationDetails?.verifiedByRemark
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="schoolNameRemark"
                              name="schoolNameRemark"
                              readOnly={
                                flag === "false" ||
                                localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>{" "}
                    </>
                  )}

                  <div className="row my-2">
                    <div className="col-md-4 my-1">
                      <label className="small mb-1" htmlFor="entityName">
                        Specilisation / Major
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="specilisation"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.specilisation || ""}
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>

                    <div className="col-md-4 my-1">
                      <label className="small mb-1" htmlFor="entityName">
                        Course Type
                      </label>
                      <Dropdown
                        value={item?.education?.courseType}
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        options={CourseRemark}
                        optionLabel="name"
                        optionValue="name"
                        placeholder="Select Course Type"
                        filter
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                        className="w-full md:w-14rem"
                        inputId="courseType"
                        name="courseType"
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>

                    <div className="col-md-4 my-1">
                      <label className="small mb-1" htmlFor="entityName">
                        Grade/Cgpa/percentage/Final marks/division
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="grade"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols",
                            "education"
                          )
                        }
                        value={item?.education?.grade || ""}
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>

                    <div className="col-md-4 my-1">
                      <label className="small mb-1" htmlFor="entityName">
                        Course Start Date
                      </label>
                      <br />

                      {item?.education?.courseStartDate !== "" &&
                        editStartDate === false && (
                        <i
                          className="pi pi-pencil"
                          style={{
                            fontSize: "1.2rem",
                            color: "#075B86",
                            cursor: "pointer",
                            marginLeft: 5,
                          }}
                          onClick={() => setEditStartDate(!editStartDate)}
                          role="button"
                          tabIndex="0"
                          aria-label="Edit Date"
                        ></i>
                      )}
                      <br />
                      {item?.education?.courseStartDate === "" ||
                      editStartDate === true ? (
                          <>
                            <Calendar
                              value={item?.education?.courseStartDate || ""}
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "education"
                                )
                              }
                              name="courseStartDate"
                              placeholder="DD/MM/YYYY"
                              dateFormat="dd/mm/yy"
                              // readOnlyInput
                              className="!w-[350px]"
                            />
                          </>
                        ) : (
                          <>
                            <InputText
                              className="form-control"
                              type="text"
                              name="courseStartDate"
                              // onChange={(e) =>
                              //   onInputChange(e, dataIndex, "text", "education")
                              // }
                              value={formatDateToDDMMYY(
                                item?.education?.courseStartDate
                              )}
                              readOnly={true}
                            />
                          </>
                        )}
                    </div>
                  </div>

                  <label className="large font-bold mb-1" htmlFor="entityName">
                    Institute Details
                  </label>

                  <div className="row my-2">
                    <div className="col-md-4 my-1">
                      <label className="small mb-1" htmlFor="entityName">
                        Institute address
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="instituteAddress"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.instituteAddress || ""}
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>

                    <div className="col-md-4 my-1">
                      <label className="small mb-1" htmlFor="entityName">
                        Pincode
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="pincode"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "numeric", "education")
                        }
                        value={item?.education?.pincode || ""}
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>

                    <div className="col-md-4 my-1">
                      <label className="small mb-1" htmlFor="entityName">
                        City/town/district
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="city"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.city || ""}
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>

                    <div className="col-md-4 my-1">
                      <label className="small mb-1" htmlFor="entityName">
                        State
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="state"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.state || ""}
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>

                    <div className="col-md-4 my-1">
                      <label className="small mb-1" htmlFor="entityName">
                        Country
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="countryName"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.countryName || ""}
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    {localStorageData?.role !== "user" && (
                      <>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Remark
                          </label>
                          <Dropdown
                            value={
                              item?.verificationDetails?.qualificationRemark
                            }
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "text",
                                "verificationDetails"
                              )
                            }
                            options={statusRemark}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select Remark"
                            filter
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem"
                            inputId="qualificationRemark"
                            name="qualificationRemark"
                            readOnly={
                              flag === "false" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Remark Date
                          </label>
                          {/* <InputText
                            className="form-control"
                            type="Date"
                            name="remarkDate"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "verificationDetails"
                              )
                            }
                            value={item?.verificationDetails?.remarkDate || ""}
                            readOnly={
                              flag === "false" ||
                              localStorageData?.role === "Auditor"
                            }
                          /> */}

                          {item?.verificationDetails?.remarkDate !== "" &&
                            editRemarkDate === false && (
                            <i
                              className="pi pi-pencil"
                              style={{
                                fontSize: "1.2rem",
                                color: "#075B86",
                                cursor: "pointer",
                                marginLeft: 5,
                              }}
                              onClick={() =>
                                setEditRemarkDate(!editRemarkDate)
                              }
                              role="button"
                              tabIndex="0"
                              aria-label="Edit Date"
                            ></i>
                          )}
                          <br />
                          {item?.verificationDetails?.remarkDate === "" ||
                          editRemarkDate === true ? (
                              <>
                                <Calendar
                                  value={
                                    item?.verificationDetails?.remarkDate || ""
                                  }
                                  onChange={(e) =>
                                    onInputChange(
                                      e,
                                      dataIndex,
                                      "alphanumericWithAllowedSymbols",
                                      "verificationDetails"
                                    )
                                  }
                                  name="remarkDate"
                                  placeholder="DD/MM/YYYY"
                                  dateFormat="dd/mm/yy"
                                  // readOnlyInput
                                  className="!w-[350px]"
                                  readOnly={
                                    flag === "false" ||
                                  localStorageData?.role === "Auditor"
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <InputText
                                  className="form-control"
                                  type="text"
                                  name="remarkDate"
                                  // onChange={(e) =>
                                  //   onInputChange(e, dataIndex, "text", "education")
                                  // }
                                  value={formatDateToDDMMYY(
                                    item?.education?.courseStartDate
                                  )}
                                  readOnly={true}
                                />
                              </>
                            )}
                        </div>
                      </>
                    )}
                  </div>

                  {localStorageData?.role !== "user" && (
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Respondent Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="respondentName"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "text", "education")
                          }
                          value={item?.education?.respondentName || ""}
                          readOnly={
                            flag === "true" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Respondent Designation
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="respondentDesignation"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "text", "education")
                          }
                          value={item?.education?.respondentDesignation || ""}
                          readOnly={
                            flag === "true" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      </div>
                    </div>
                  )}

                  <div className="row my-2">
                    <div className="col-md-8">
                      <label className="small mb-1" htmlFor="clientType">
                        Additional Remarks
                      </label>
                      <InputTextarea
                        className="form-control"
                        type="text"
                        name="additionalRemarks"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumeric",
                            "education"
                          )
                        }
                        value={item?.education?.additionalRemarks || ""}
                        readOnly={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                        rows={5}
                        cols={30}
                      />
                    </div>
                    {localStorageData?.role !== "user" && (
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Final Remark
                        </label>

                        <Dropdown
                          value={item?.verificationDetails?.reportRemark || ""}
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "verificationDetails"
                            )
                          }
                          options={finalRemark}
                          optionLabel="name"
                          optionValue="name"
                          placeholder="Select Remark"
                          filter
                          valueTemplate={selectedCountryTemplate}
                          itemTemplate={countryOptionTemplate}
                          className="w-full md:w-14rem"
                          inputId="reportRemark"
                          name="reportRemark"
                          readOnly={
                            flag === "false" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      </div>
                    )}
                    {localStorageData?.role !== "user" && (
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Annexure
                        </label>

                        <Dropdown
                          value={item?.verificationDetails?.annexure || ""}
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "verificationDetails"
                            )
                          }
                          options={educationAnnexure}
                          optionLabel="name"
                          optionValue="name"
                          placeholder="Select Annexure"
                          filter
                          valueTemplate={selectedCountryTemplate}
                          itemTemplate={countryOptionTemplate}
                          className="w-full md:w-14rem"
                          inputId="annexure"
                          name="annexure"
                          readOnly={
                            flag === "false" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.education?.reportUrl?.length === 0 ||
                      editable === true ? (
                          <FileUpload
                            name="fileUpload[]"
                            multiple
                            auto
                            accept="image/*,application/pdf"
                            customUpload={true}
                            // maxFileSize={5000000}
                            uploadHandler={(e) => handleFileUpload(e, "file")}
                            itemTemplate={customItemTemplate}
                            readOnly={localStorageData?.role === "BCAStaff"}
                          />
                        ) : (
                          ""
                        )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.education?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow readOnly:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>

                    {user?.role !== "user" && (
                      <>
                        <div className="col-md-8 mt-2">
                          <label className="small mb-1" htmlFor="remark">
                            Remark
                          </label>
                          <textarea
                            className="form-control h-[110px]"
                            name="remark"
                            onChange={(e) => {
                              // setRemarkForActivity(e.target.value);
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumeric",
                                "education"
                              );
                            }}
                            value={item?.education?.remark || ""}
                            readOnly={
                              flag === "false" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>
                        <div className="col-md-12 my-2">
                          <label className="small mb-1" htmlFor="clientType">
                            Upload Proof{" "}
                            <span className="opacity-70">
                              (Please Choose PDF less than 10MB)
                            </span>
                          </label>
                          {item?.education?.uploadProofUrl?.length === 0 ? (
                            <FileUpload
                              name="fileUpload[]"
                              auto
                              multiple
                              accept="image/*"
                              customUpload={true}
                              maxFileSize={10000000}
                              uploadHandler={(e) =>
                                handleFileUpload(e, "uploadProof")
                              }
                              itemTemplate={customItemTemplate}
                              readOnly={
                                flag === "false" ||
                                localStorageData?.role === "Auditor"
                              }
                            />
                          ) : (
                            ""
                          )}
                          <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                            {(item?.education?.uploadProofUrl || []).map(
                              (fileUrl, proofIndex) => {
                                // Extract file name from the URL
                                const fileName = fileUrl.split("/").pop();
                                const fileExtension = fileName
                                  .split(".")
                                  .pop()
                                  .toLowerCase();

                                const isImage = ["jpeg", "png"].includes(
                                  fileExtension
                                );

                                return (
                                  <li
                                    key={proofIndex}
                                    className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                  >
                                    <div
                                      role="presentation"
                                      onClick={(e) =>
                                        removeImg(
                                          e,
                                          item._id,
                                          item,
                                          proofIndex,
                                          "proof"
                                        )
                                      } // Pass the `item` to removeImg
                                      className="cursor-pointer"
                                    >
                                      <i
                                        className="pi pi-times"
                                        style={{ color: "red" }}
                                      ></i>
                                    </div>
                                    <Button
                                      label="Download"
                                      onClick={(e) =>
                                        getImg(
                                          e,
                                          fileName,
                                          dataIndex,
                                          proofIndex,
                                          "proof"
                                        )
                                      }
                                      className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow readOnly:opacity-60"
                                    />
                                    <div className="text-center mt-2">
                                      {isImage &&
                                        proofImage[dataIndex]?.[proofIndex] && (
                                        <a
                                          href={
                                            proofImage[dataIndex][proofIndex]
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          download={`file_${dataIndex}_${proofIndex}`}
                                        >
                                            View Image
                                        </a>
                                      )}
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>{" "}
                      </>
                    )}
                  </div>
                  <div></div>

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.education?.status === "Pending" ? (
                        item?.education?.reportUrl?.length > 0 ? (
                          <Button
                            label="Edit"
                            type="button"
                            onClick={(e) => {
                              handleEdit(e);
                            }}
                            className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow readOnly:opacity-60"
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    {item?.education?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateEducationDetails(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow readOnly:opacity-60"
                        />
                        <div>{getSeverity(item?.education?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.education?.status)}</div>
                    )}
                  </div>
                </form>
              )}
              {process.env.REACT_APP_IS_SIRIUS_REPORT_ENABLED === "true" && (
                <form>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Qualification
                    </label>
                    <Dropdown
                      value={item?.education?.qualification || ""}
                      onChange={(e) =>
                        onInputChange(e, dataIndex, " ", "education")
                      }
                      options={educationCategories}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select Qualification"
                      filter
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                      className="w-full md:w-14rem"
                      inputId="qualification"
                      name="qualification"
                    />
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Name of the candidate
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="candidateName"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.candidateName || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Remark
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="candidateNameRemark"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "text",
                            "verificationDetails"
                          )
                        }
                        value={
                          item?.verificationDetails?.candidateNameRemark || ""
                        }
                        disabled={flag === "false"}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Name of the university
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="unversityName"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.unversityName || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Remark
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="unversityNameRemark"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "text",
                            "verificationDetails"
                          )
                        }
                        value={
                          item?.verificationDetails?.unversityNameRemark || ""
                        }
                        disabled={flag === "false"}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Degree
                      </label>
                      {/* <Dropdown
                        value={item?.education?.qualification || ""}
                        onChange={(e) => onInputChange(e, dataIndex)}
                        options={educationCategories}
                        optionLabel="name"
                        optionValue="name"
                        placeholder="Select Qualification"
                        filter
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                        className="w-full md:w-14rem"
                        inputId="qualification"
                        name="qualification"
                        disabled={flag === "true"}
                      /> */}
                      <InputText
                        className="form-control"
                        type="text"
                        name="degreeName"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.degreeName || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Remark
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="degreeNameRemark"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "text",
                            "verificationDetails"
                          )
                        }
                        value={
                          item?.verificationDetails?.degreeNameRemark || ""
                        }
                        disabled={flag === "false"}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Roll Number
                      </label>
                      <InputText
                        className="form-control"
                        type="int"
                        name="rollNumber"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumeric",
                            "education"
                          )
                        }
                        value={item?.education?.rollNumber || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Remark
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="rollNumberRemark"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumeric",
                            "verificationDetails"
                          )
                        }
                        value={
                          item?.verificationDetails?.rollNumberRemark || ""
                        }
                        disabled={flag === "false"}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="yearOfPassing">
                        Year Of Passing
                      </label>
                      <InputText
                        className="form-control"
                        type="number"
                        name="yearOfPassing"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumeric",
                            "education"
                          )
                        }
                        value={item?.education?.yearOfPassing || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Remark
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="yearOfPassingRemark"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumeric",
                            "verificationDetails"
                          )
                        }
                        value={
                          item?.verificationDetails?.yearOfPassingRemark || ""
                        }
                        disabled={flag === "false"}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label
                        className="small mb-1"
                        htmlFor="remarkAboutUniversity"
                      >
                        Remarks about university
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="remarkAboutUniversity"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols",
                            "education"
                          )
                        }
                        value={item?.education?.remarkAboutUniversity || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Remark
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="remarkAboutUniversityRemark"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols",
                            "verificationDetails"
                          )
                        }
                        value={
                          item?.verificationDetails
                            ?.remarkAboutUniversityRemark || ""
                        }
                        disabled={flag === "false"}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-8">
                      <label className="small mb-1" htmlFor="clientType">
                        Final remarks
                      </label>
                      <InputTextarea
                        className="form-control"
                        type="text"
                        name="additionalRemarks"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols",
                            "education"
                          )
                        }
                        value={item?.education?.additionalRemarks || ""}
                        disabled={flag === "true"}
                        rows={5}
                        cols={30}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Remark
                      </label>
                      <InputTextarea
                        className="form-control"
                        type="text"
                        name="additionalRemarksRemark"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols",
                            "verificationDetails"
                          )
                        }
                        value={
                          item?.verificationDetails?.additionalRemarksRemark ||
                          ""
                        }
                        disabled={flag === "false"}
                        rows={5}
                        cols={30}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.education?.reportUrl?.length === 0 ||
                      editable === true ? (
                          <FileUpload
                            name="fileUpload[]"
                            multiple
                            auto
                            accept="image/*,application/pdf"
                            customUpload={true}
                            // maxFileSize={5000000}
                            uploadHandler={(e) => handleFileUpload(e, "file")}
                            itemTemplate={customItemTemplate}
                            disabled={flag === "true"}
                          />
                        ) : (
                          ""
                        )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.education?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[10px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>

                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>

                    {user?.role !== "user" && (
                      <>
                        <div className="col-md-8 mt-2">
                          <label className="small mb-1" htmlFor="remark">
                            Remark
                          </label>
                          <textarea
                            className="form-control h-[110px]"
                            name="remark"
                            onChange={(e) => {
                              // setRemarkForActivity(e.target.value);
                              onInputChange(e, dataIndex, "alphanumeric");
                            }}
                            value={item?.education?.remark || ""}
                            disabled={flag === "false"}
                          />
                        </div>
                        <div className="col-md-12 my-2">
                          <label className="small mb-1" htmlFor="clientType">
                            Upload Proof{" "}
                            <span className="opacity-70">
                              (Please Choose PDF less than 10MB)
                            </span>
                          </label>
                          {item?.education?.uploadProofUrl?.length === 0 ? (
                            <FileUpload
                              name="fileUpload[]"
                              auto
                              multiple
                              accept="image/*"
                              customUpload={true}
                              maxFileSize={10000000}
                              uploadHandler={(e) =>
                                handleFileUpload(e, "uploadProof")
                              }
                              itemTemplate={customItemTemplate}
                              disabled={flag === "false"}
                            />
                          ) : (
                            ""
                          )}
                          <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                            {(item?.education?.uploadProofUrl || []).map(
                              (fileUrl, proofIndex) => {
                                // Extract file name from the URL
                                const fileName = fileUrl.split("/").pop();
                                const fileExtension = fileName
                                  .split(".")
                                  .pop()
                                  .toLowerCase();

                                const isImage = ["jpeg", "png"].includes(
                                  fileExtension
                                );

                                return (
                                  <li
                                    key={proofIndex}
                                    className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                  >
                                    <div
                                      role="presentation"
                                      onClick={(e) =>
                                        removeImg(
                                          e,
                                          item._id,
                                          item,
                                          proofIndex,
                                          "proof"
                                        )
                                      } // Pass the `item` to removeImg
                                      className="cursor-pointer"
                                    >
                                      <i
                                        className="pi pi-times"
                                        style={{ color: "red" }}
                                      ></i>
                                    </div>
                                    <Button
                                      label="Download"
                                      onClick={(e) =>
                                        getImg(
                                          e,
                                          fileName,
                                          dataIndex,
                                          proofIndex,
                                          "proof"
                                        )
                                      }
                                      className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                    />
                                    <div className="text-center mt-2">
                                      {isImage &&
                                        proofImage[dataIndex]?.[proofIndex] && (
                                        <a
                                          href={
                                            proofImage[dataIndex][proofIndex]
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          download={`file_${dataIndex}_${proofIndex}`}
                                        >
                                            View Image
                                        </a>
                                      )}
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>{" "}
                      </>
                    )}
                  </div>
                  <div></div>

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.education?.status === "Pending" ? (
                        item?.education?.reportUrl?.length > 0 ? (
                          <Button
                            label="Edit"
                            type="button"
                            onClick={(e) => {
                              handleEdit(e);
                            }}
                            className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    {/* {item?.education?.status === "Pending" ? ( */}
                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateEducationDetails(e, item._id, dataIndex);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.education?.status)}</div>
                    </div>
                    {/* ) : (
                      <div>{getSeverity(item?.education?.status)}</div>
                    )} */}
                  </div>
                </form>
              )}

              {process.env.REACT_APP_IS_VLEADER_REPORT_ENABLED === "true" && (
                <form>
                  <div className="col-md-4 my-2">
                    <label className="small mb-1" htmlFor="entityName">
                      Qualification
                    </label>

                    <InputText
                      className="form-control"
                      type="text"
                      name="qualification"
                      value={item?.education?.qualification || ""}
                      // readOnly
                    />
                  </div>

                  <div className="flex items-center my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        College Name & Address
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="instituteName"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.instituteName || ""}
                        // readOnly={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>

                    {localStorageData?.role !== "user" &&
                      localStorageData?.role !== "DataEntry" && (
                      <>
                        <div className="mt-4 ml-12 mr-12">
                          <Checkbox
                            inputId="collegeName"
                            name="collegeName"
                            checked={
                              isEdit.collegeName === true ||
                                item?.verificationDetails
                                  ?.instituteNameRemark !== ""
                            }
                            onChange={() => toggleEdit("collegeName")}
                          />
                        </div>
                        {(isEdit.collegeName ||
                            item?.verificationDetails?.instituteNameRemark) && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="entityName"
                            >
                                Remark
                            </label>

                            <InputText
                              className="form-control"
                              type="text"
                              name="instituteNameRemark"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "text",
                                  "verificationDetails"
                                )
                              }
                              value={
                                item?.verificationDetails
                                  ?.instituteNameRemark || ""
                              }

                              // readOnly={

                              //   flag === "true" ||

                              //   localStorageData?.role === "Auditor"

                              // }
                            />
                          </div>
                        )}{" "}
                      </>
                    )}
                  </div>
                  <div className="flex items-center my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        University Name & Address
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="unversityName"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.unversityName || ""}
                        // readOnly={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>

                    {localStorageData?.role !== "user" &&
                      localStorageData?.role !== "DataEntry" && (
                      <>
                        <div className="mt-4 ml-12 mr-12">
                          <Checkbox
                            inputId="universityName"
                            name="universityName"
                            checked={
                              isEdit.universityName === true ||
                                item?.verificationDetails
                                  ?.universityNameRemark !== ""
                            }
                            onChange={() => toggleEdit("universityName")}
                          />
                        </div>

                        {(isEdit.universityName ||
                            item?.verificationDetails
                              ?.universityNameRemark) && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="entityName"
                            >
                                Remark
                            </label>

                            <InputText
                              className="form-control"
                              type="text"
                              name="universityNameRemark"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "text",
                                  "verificationDetails"
                                )
                              }
                              value={
                                item?.verificationDetails
                                  ?.universityNameRemark || ""
                              }

                              // readOnly={

                              //   flag === "true" ||

                              //   localStorageData?.role === "Auditor"

                              // }
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="flex items-center my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Start Date (From)
                      </label>
                      {/* {item?.education?.courseStartDate !== "" &&
                        editStartDate === false && (
                        <i
                          className="pi pi-pencil"
                          style={{
                            fontSize: "1.2rem",
                            color: "#075B86",
                            cursor: "pointer",
                            marginLeft: 5,
                          }}
                          onClick={() => setEditStartDate(!editStartDate)}
                          role="button"
                          tabIndex="0"
                          aria-label="Edit Date"
                        ></i>
                      )}
                      <br />
                      {item?.education?.courseStartDate === "" ||
                      editStartDate === true ? (
                          <>
                            <Calendar
                              value={item?.education?.courseStartDate || ""}
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "education"
                                )
                              }
                              name="courseStartDate"
                              dateFormat="dd/mm/yy"
                              placeholder="DD/MM/YYYY"
                              showIcon
                              // readOnlyInput
                              className="!w-[350px]"
                            />
                          </>
                        ) : (
                          <> */}
                      <InputText
                        className="form-control"
                        type="Date"
                        name="courseStartDate"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, " ", "education")
                        }
                        value={item?.education?.courseStartDate || ""}
                        // readOnly={true}
                      />
                      {/* </>
                        )} */}
                    </div>

                    {localStorageData?.role !== "user" &&
                      localStorageData?.role !== "DataEntry" && (
                      <>
                        <div className="mt-4 ml-12 mr-12">
                          <Checkbox
                            inputId="startDate"
                            name="startDate"
                            checked={
                              isEdit.startDate === true ||
                                item?.verificationDetails?.startDateRemark !==
                                  ""
                            }
                            onChange={() => toggleEdit("startDate")}
                          />
                        </div>

                        {(isEdit.startDate ||
                            item?.verificationDetails?.startDateRemark) && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="entityName"
                            >
                                Remark
                            </label>

                            <InputText
                              className="form-control"
                              type="text"
                              name="startDateRemark"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "text",
                                  "verificationDetails"
                                )
                              }
                              value={
                                item?.verificationDetails?.startDateRemark ||
                                  ""
                              }
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  <div className="flex items-center my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        End Date (To)
                      </label>
                      {/* {item?.education?.courseEndDate !== "" &&
                        editEndDate === false && (
                          <i
                            className="pi pi-pencil"
                            style={{
                              fontSize: "1.2rem",
                              color: "#075B86",
                              cursor: "pointer",
                              marginLeft: 5,
                            }}
                            onClick={() => setEditEndDate(!editEndDate)}
                            role="button"
                            tabIndex="0"
                            aria-label="Edit Date"
                          ></i>
                        )}
                      <br />
                      {item?.education?.courseEndDate === "" ||
                      editEndDate === true ? (
                        <>
                          <Calendar
                            value={item?.education?.courseEndDate || ""}
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "education"
                              )
                            }
                            name="courseEndDate"
                            placeholder="DD/MM/YYYY"
                            dateFormat="dd/mm/yy"
                            showIcon
                            // readOnlyInput
                            className="!w-[350px]"
                          />
                        </>
                      ) : (
                        <> */}
                      <InputText
                        className="form-control"
                        type="Date"
                        name="courseEndDate"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, " ", "education")
                        }
                        value={item?.education?.courseEndDate || ""}
                        // readOnly={true}
                      />
                      {/* </>
                      )} */}
                    </div>
                    {localStorageData?.role !== "user" &&
                      localStorageData?.role !== "DataEntry" && (
                      <>
                        <div className="mt-4 ml-12 mr-12">
                          <Checkbox
                            inputId="endDate"
                            name="endDate"
                            checked={
                              isEdit.endDate === true ||
                                item?.verificationDetails?.endDateRemark !== ""
                            }
                            onChange={() => toggleEdit("endDate")}
                          />
                        </div>

                        {(isEdit.endDate ||
                            item?.verificationDetails?.endDateRemark) && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="entityName"
                            >
                                Remark
                            </label>

                            <InputText
                              className="form-control"
                              type="text"
                              name="endDateRemark"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "text",
                                  "verificationDetails"
                                )
                              }
                              value={
                                item?.verificationDetails?.endDateRemark || ""
                              }
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  <div className="col-md-4 my-2">
                    <label className="small mb-1" htmlFor="entityName">
                      Graduated
                    </label>
                    <div className="p-formgroup-inline flex ">
                      <div className="p-field-checkbox mr-8">
                        <Checkbox
                          inputId="graduatedYes"
                          name="isGraduated"
                          value="Yes"
                          checked={item?.education?.isGraduated === "Yes"}
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "text", "education")
                          }
                        />
                        <label htmlFor="graduatedYes" className="ml-2">
                          Yes
                        </label>
                      </div>
                      <div className="p-field-checkbox ">
                        <Checkbox
                          inputId="graduatedNo"
                          name="isGraduated"
                          value="No"
                          checked={item?.education?.isGraduated === "No"}
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "text", "education")
                          }
                        />
                        <label htmlFor="graduatedNo" className="ml-2">
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 my-2">
                    <label className="small mb-1" htmlFor="entityName">
                      Program
                    </label>
                    <div className="p-formgroup-inline flex ">
                      <div className="p-field-checkbox mr-8">
                        <Checkbox
                          inputId="courseType"
                          name="courseType"
                          value="Full Time"
                          checked={item?.education?.courseType === "Full Time"}
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "text", "education")
                          }
                        />
                        <label htmlFor="graduatedYes" className="ml-2">
                          Full Time
                        </label>
                      </div>
                      <div className="p-field-checkbox ">
                        <Checkbox
                          inputId="courseType"
                          name="courseType"
                          value="Part Time"
                          checked={item?.education?.courseType === "Part Time"}
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "text", "education")
                          }
                        />
                        <label htmlFor="courseType" className="ml-2">
                          Part Time
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center my-2">
                    <div className="col-md-4 ">
                      <label className="small mb-1" htmlFor="entityName">
                        Student ID No / Enrollment No.
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="enrollmentNumber"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumeric",
                            "education"
                          )
                        }
                        value={item?.education?.enrollmentNumber || ""}
                        // readOnly={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    {localStorageData?.role !== "user" &&
                      localStorageData?.role !== "DataEntry" && (
                      <>
                        <div className="mt-4 ml-12 mr-12">
                          <Checkbox
                            inputId="enrollmentNumber"
                            name="enrollmentNumber"
                            checked={
                              isEdit.enrollmentNumber === true ||
                                item?.verificationDetails
                                  ?.enrollmentNumberRemark !== ""
                            }
                            onChange={() => toggleEdit("enrollmentNumber")}
                          />
                        </div>

                        {(isEdit.enrollmentNumber ||
                            item?.verificationDetails
                              ?.enrollmentNumberRemark) && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="entityName"
                            >
                                Remark
                            </label>

                            <InputText
                              className="form-control"
                              type="text"
                              name="enrollmentNumberRemark"
                              onChange={(e) =>
                                onInputChange(
                                  e,

                                  dataIndex,

                                  "alphanumeric",

                                  "verificationDetails"
                                )
                              }
                              value={
                                item?.verificationDetails
                                  ?.enrollmentNumberRemark || ""
                              }

                              // readOnly={

                              //   flag === "true" ||

                              //   localStorageData?.role === "Auditor"

                              // }
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  <div className="flex items-center my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Course Name
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="degreeName"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.degreeName || ""}
                        // readOnly={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>

                    {localStorageData?.role !== "user" &&
                      localStorageData?.role !== "DataEntry" && (
                      <>
                        {" "}
                        <div className="mt-4 ml-12 mr-12">
                          <Checkbox
                            inputId="degreeName"
                            name="degreeName"
                            checked={
                              isEdit.degreeName === true ||
                                item?.verificationDetails?.degreeNameRemark !==
                                  ""
                            }
                            onChange={() => toggleEdit("degreeName")}
                          />
                        </div>
                        {(isEdit.degreeName ||
                            item?.verificationDetails?.degreeNameRemark) && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="entityName"
                            >
                                Remark
                            </label>

                            <InputText
                              className="form-control"
                              type="text"
                              name="degreeNameRemark"
                              onChange={(e) =>
                                onInputChange(
                                  e,

                                  dataIndex,

                                  "text",

                                  "verificationDetails"
                                )
                              }
                              value={
                                item?.verificationDetails?.degreeNameRemark ||
                                  ""
                              }

                              // readOnly={

                              //   flag === "true" ||

                              //   localStorageData?.role === "Auditor"

                              // }
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {/* <div className="flex items-center my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="yearOfPassing">
                        Graduation date
                      </label>

                      {item?.education?.yearOfPassing !== "" &&
                        editGraduationDate === false && (
                          <i
                            className="pi pi-pencil"
                            style={{
                              fontSize: "1rem",
                              color: "#075B86",
                              cursor: "pointer",
                              marginLeft: 5,
                            }}
                            onClick={() =>
                              setEditGradutaionDate(!editGraduationDate)
                            }
                            role="button"
                            tabIndex="0"
                            aria-label="Edit Date"
                          ></i>
                        )}
                      <br />
                      {item?.education?.yearOfPassing === "" ||
                      editGraduationDate === true ? (
                        <>
                          <Calendar
                            value={item?.education?.yearOfPassing || ""}
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "education"
                              )
                            }
                            name="yearOfPassing"
                            placeholder="DD/MM/YYYY"
                            dateFormat="dd/mm/yy"
                            showIcon
                            // readOnlyInput
                            className="!w-[350px]"
                          />
                        </>
                      ) : (
                        <>
                          <InputText
                            className="form-control"
                            type="text"
                            name="yearOfPassing"
                            // onChange={(e) =>
                            //   onInputChange(e, dataIndex, "text", "education")
                            // }
                            value={formatDateToDDMMYY(
                              item?.education?.yearOfPassing
                            )}
                            readOnly={true}
                          />
                        </>
                      )}
                    </div>

                    {localStorageData?.role !== "user" &&
                      localStorageData?.role !== "DataEntry" && (
                        <>
                          {" "}
                          <div className="mt-4 ml-12 mr-12">
                            <Checkbox
                              inputId="yearOfPassing"
                              name="yearOfPassing"
                              checked={
                                isEdit.yearOfPassing === true ||
                                item?.verificationDetails
                                  ?.yearOfPassingRemark !== ""
                              }
                              onChange={() => toggleEdit("yearOfPassing")}
                            />
                          </div>
                          {(isEdit.yearOfPassing ||
                            item?.verificationDetails?.yearOfPassingRemark) && (
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="entityName"
                              >
                                Remark
                              </label>

                              {item?.verificationDetails
                                ?.yearOfPassingRemark !== "" &&
                                editGraduationRemarkDate === false && (
                                  <i
                                    className="pi pi-pencil"
                                    style={{
                                      fontSize: "1rem",
                                      color: "#075B86",
                                      cursor: "pointer",
                                      marginLeft: 5,
                                    }}
                                    onClick={() =>
                                      setEditGradutaionRemarkDate(
                                        !editGraduationRemarkDate
                                      )
                                    }
                                    role="button"
                                    tabIndex="0"
                                    aria-label="Edit Date"
                                  ></i>
                                )}
                              <br />
                              {item?.verificationDetails
                                ?.yearOfPassingRemark === "" ||
                              editGraduationRemarkDate === true ? (
                                <>
                                  <Calendar
                                    value={
                                      item?.verificationDetails
                                        ?.yearOfPassingRemark || ""
                                    }
                                    onChange={(e) =>
                                      onInputChange(
                                        e,
                                        dataIndex,
                                        "alphanumericWithAllowedSymbols",
                                        "verificationDetails"
                                      )
                                    }
                                    name="yearOfPassingRemark"
                                    placeholder="DD/MM/YYYY"
                                    dateFormat="dd/mm/yy"
                                    showIcon
                                    // readOnlyInput
                                    className="!w-[350px]"
                                  />
                                </>
                              ) : (
                                <>
                                  <InputText
                                    className="form-control"
                                    type="text"
                                    name="yearOfPassingRemark"
                                    // onChange={(e) =>
                                    //   onInputChange(e, dataIndex, "text", "education")
                                    // }
                                    value={formatDateToDDMMYY(
                                      item?.verificationDetails
                                        ?.yearOfPassingRemark
                                    )}
                                    readOnly={true}
                                  />
                                </>
                              )}
                            </div>
                          )}{" "}
                        </>
                      )}
                  </div> */}
                  <div className="col-md-4 my-2">
                    <label className="small mb-1" htmlFor="entityName">
                      Subject Major
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="specilisation"
                      onChange={(e) =>
                        onInputChange(e, dataIndex, "text", "education")
                      }
                      value={item?.education?.specilisation || ""}
                      // readOnly={
                      //   flag === "true" ||
                      //   localStorageData?.role === "Auditor"
                      // }
                    />
                  </div>
                  <div className="flex items-center my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Year of Passing
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="yearOfPassing"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "numeric", "education")
                        }
                        value={item?.education?.yearOfPassing || ""}
                        // readOnly={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    {localStorageData?.role !== "user" &&
                      localStorageData?.role !== "DataEntry" && (
                      <>
                        {" "}
                        <div className="mt-4 ml-12 mr-12">
                          <Checkbox
                            inputId="yearOfPassing"
                            name="yearOfPassing"
                            checked={
                              isEdit.yearOfPassing === true ||
                                item?.verificationDetails
                                  ?.yearOfPassingRemark !== ""
                            }
                            onChange={() => toggleEdit("yearOfPassing")}
                          />
                        </div>
                        {(isEdit.yearOfPassing ||
                            item?.verificationDetails?.yearOfPassingRemark) && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="entityName"
                            >
                                Remark
                            </label>

                            <InputText
                              className="form-control"
                              type="text"
                              name="yearOfPassingRemark"
                              onChange={(e) =>
                                onInputChange(
                                  e,

                                  dataIndex,

                                  "alphanumeric",

                                  "verificationDetails"
                                )
                              }
                              value={
                                item?.verificationDetails
                                  ?.yearOfPassingRemark || ""
                              }

                              // readOnly={

                              //   flag === "true" ||

                              //   localStorageData?.role === "Auditor"

                              // }
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {localStorageData?.role !== "user" &&
                    localStorageData?.role !== "DataEntry" && (
                    <div className="col-md-4 my-2">
                      <label className="small mb-1" htmlFor="entityName">
                          Mode of Verification
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="modeOfVerification"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "text", "education")
                        }
                        value={item?.education?.modeOfVerification || ""}
                        // readOnly={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                  )}
                  {localStorageData?.role !== "user" &&
                    localStorageData?.role !== "DataEntry" && (
                    <div className="col-md-4 my-2">
                      <label className="small mb-1" htmlFor="entityName">
                          Remarks from verifier (if any)
                      </label>
                      <InputTextarea
                        className="form-control"
                        type="text"
                        name="remarkFromVerifier"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols",
                            "education"
                          )
                        }
                        value={item?.education?.remarkFromVerifier || ""}
                        // readOnly={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                  )}
                  {localStorageData?.role !== "user" &&
                    localStorageData?.role !== "DataEntry" && (
                    <div className="col-md-4 my-2">
                      <label className="small mb-1" htmlFor="entityName">
                          Name and Designation of the Referee
                      </label>
                      <InputTextarea
                        className="form-control"
                        type="text"
                        name="nameAndDesignationOfTheReferee"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols",
                            "education"
                          )
                        }
                        value={
                          item?.education?.nameAndDesignationOfTheReferee ||
                            ""
                        }
                        // readOnly={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                  )}

                  {localStorageData?.role !== "user" &&
                    localStorageData?.role !== "DataEntry" && (
                    <div className="col-md-4 my-2">
                      <label className="small mb-1" htmlFor="entityName">
                          Additional Comments (If any)
                      </label>
                      <InputTextarea
                        className="form-control"
                        type="text"
                        name="additionalRemarks"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols",
                            "education"
                          )
                        }
                        value={item?.education?.additionalRemarks || ""}
                        // readOnly={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                  )}

                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.education?.reportUrl?.length === 0 ||
                      editable === true ? (
                          <FileUpload
                            name="fileUpload[]"
                            multiple
                            auto
                            accept="image/*,application/pdf"
                            customUpload={true}
                            // maxFileSize={5000000}
                            uploadHandler={(e) => handleFileUpload(e, "file")}
                            itemTemplate={customItemTemplate}
                          // readOnly={
                          //   flag === "true" ||
                          //   localStorageData?.role === "Auditor"
                          // }
                          />
                        ) : (
                          ""
                        )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.education?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow readOnly:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>

                    {localStorageData?.role !== "user" &&
                      localStorageData?.role !== "DataEntry" && (
                      <>
                        <div className="col-md-8 mt-2">
                          <label className="small mb-1" htmlFor="remark">
                              Remark
                          </label>
                          <textarea
                            className="form-control h-[110px]"
                            name="remark"
                            onChange={(e) => {
                              // setRemarkForActivity(e.target.value);
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumeric",
                                "education"
                              );
                            }}
                            value={item?.education?.remark || ""}
                            // readOnly={
                            //   flag === "false" ||
                            //   localStorageData?.role === "Auditor"
                            // }
                          />
                        </div>
                        <div className="col-md-12 my-2">
                          <label className="small mb-1" htmlFor="clientType">
                              Upload Proof{" "}
                            <span className="opacity-70">
                                (Please Choose JPEG,PNG,PDF less than 5MB)
                            </span>
                          </label>
                          {item?.education?.uploadProofUrl?.length === 0 ? (
                            <FileUpload
                              name="fileUpload[]"
                              auto
                              multiple
                              accept="image/*,application/pdf"
                              customUpload={true}
                              maxFileSize={10000000}
                              uploadHandler={(e) =>
                                handleFileUpload(e, "uploadProof")
                              }
                              itemTemplate={customItemTemplate}
                              // readOnly={
                              //   flag === "false" ||
                              //   localStorageData?.role === "Auditor"
                              // }
                            />
                          ) : (
                            ""
                          )}
                          <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                            {(item?.education?.uploadProofUrl || []).map(
                              (fileUrl, proofIndex) => {
                                // Extract file name from the URL
                                const fileName = fileUrl.split("/").pop();
                                const fileExtension = fileName
                                  .split(".")
                                  .pop()
                                  .toLowerCase();

                                const isImage = ["jpeg", "png"].includes(
                                  fileExtension
                                );
                                const isPdf = fileExtension === "pdf";
                                return (
                                  <li
                                    key={proofIndex}
                                    className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                  >
                                    <div
                                      role="presentation"
                                      onClick={(e) =>
                                        removeImg(
                                          e,
                                          item._id,
                                          item,
                                          proofIndex,
                                          "proof"
                                        )
                                      } // Pass the `item` to removeImg
                                      className="cursor-pointer"
                                    >
                                      <i
                                        className="pi pi-times"
                                        style={{ color: "red" }}
                                      ></i>
                                    </div>
                                    <Button
                                      label="Download"
                                      onClick={(e) =>
                                        getImg(
                                          e,
                                          fileName,
                                          dataIndex,
                                          proofIndex,
                                          "proof"
                                        )
                                      }
                                      className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow readOnly:opacity-60"
                                    />
                                    <div className="text-center mt-2">
                                      {isImage &&
                                          proofImage[dataIndex]?.[
                                            proofIndex
                                          ] && (
                                        <a
                                          href={
                                            proofImage[dataIndex][
                                              proofIndex
                                            ]
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          download={`file_${dataIndex}_${proofIndex}`}
                                        >
                                              View Image
                                        </a>
                                      )}
                                      {isPdf &&
                                          proofImage[dataIndex]?.[
                                            proofIndex
                                          ] && (
                                        <a
                                          href={
                                            proofImage[dataIndex][
                                              proofIndex
                                            ]
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-center"
                                        >
                                              View PDF
                                        </a>
                                      )}
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                  <div></div>

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {
                      flag === "false" &&
                      // item?.education?.status === "Pending" ? (
                      item?.education?.reportUrl?.length > 0 ? (
                          <Button
                            label="Edit"
                            type="button"
                            onClick={(e) => {
                              handleEdit(e);
                            }}
                            className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow readOnly:opacity-60"
                          />
                        ) : (
                          ""
                        )
                      // ) : (
                      //   ""
                      // )
                    }
                    {/* {item?.education?.status === "Pending" ? ( */}
                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateEducationDetails(e, item._id, dataIndex);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow readOnly:opacity-60"
                      />
                      <div>{getSeverity(item?.education?.status)}</div>
                    </div>
                    {/* ) : (
                      <div>{getSeverity(item?.education?.status)}</div>
                    )} */}
                  </div>
                </form>
              )}
            </div>
          </Card>

          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
              checkName="Education"
            />
          </div>
        </>
      ))}
      {user?.role === "user" && <ConfirmDialog visible={isModalOpen} />}

      <Modal
        width="50%"
        title="Create WebForm"
        open={showAddStaff}
        footer={null}
        onCancel={() => setShowaddStaff(false)}
      >
        <form>
          <p className="mt-2"> Let’s add Email to send verification form</p>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="Candidate Name"
                id="name"
                value={`${
                  taskDetail?.userVerificationRequest?.userInfo?.firstName || ""
                } ${
                  taskDetail?.userVerificationRequest?.userInfo?.lastName || ""
                }`}
                disabled
              />
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="Verifier Email ID"
                id="email"
                value={emailForVerification}
                onChange={(e) => setEmailForVerification(e.target.value)}
              />
            </div>
          </div>

          <div className="mt-[20px] text-end">
            <button
              onClick={(e) => {
                sendEmailForVerification(e, emailForVerification, details);
              }}
              className="w-[141px] text-white text-xs font-medium h-[37px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
            >
              Send
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EducationDetail;
