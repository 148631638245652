import React, { useEffect, useState } from "react";
import logo from "../../assets/images/reportLogo.png";
import { useLocation } from "react-router";
import ApiService from "../../services/APIServices";
import useAsyncImageSrc from "../../Helper/useAsyncImageSrc.jsx";
export const VerificationReport = () => {
  const location = useLocation();
  const [verificationInfo, setVerificationInfo] = useState(null);
  const [experienceInfo, setExperienceInfo] = useState([]);
  const [educationInfo, setEducationInfo] = useState([]);
  const [referenceInfo, setReferenceInfo] = useState([]);

  const title = process.env.REACT_APP_REFERENCE_TITLE;

  const getUserVerificationReqInfo = async (userVerificationReqId) => {
    await ApiService.get(`userVerificationRequest/${userVerificationReqId}`)
      .then((res) => {
        setVerificationInfo(res?.data[0]);
      })
      .catch(() => {});
  };

  const getExperienceInfo = (id) => {
    ApiService.get(`experienceCheck/${id}`)
      .then((res) => {
        setExperienceInfo(res?.data?.docs);
      })
      .catch(() => {});
  };

  const getEducationInfo = (id) => {
    ApiService.get(`educationCheck/${id}`)
      .then((res) => {
        setEducationInfo(res?.data?.docs);
      })
      .catch(() => {});
  };

  const getReferenceInfo = (id) => {
    ApiService.get(`referenceCheck/${id}`)
      .then((res) => {
        setReferenceInfo(res?.data?.docs);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getUserVerificationReqInfo(location?.state?.id);
    getEducationInfo(location?.state?.id);
  }, [location?.state?.id]);

  useEffect(() => {
    getExperienceInfo(location?.state?.id);
    getReferenceInfo(location?.state?.id);
  }, [location?.state?.id]);

  const formatDate = (dateString) => {
    let date;
    if (dateString) {
      date = new Date(dateString);
    } else {
      date = new Date(); // Current date if finalReportDate is null
    }
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      <img src={imgSrc} alt={alt || "image"} className={className || ""} />
    );
  };

  return (
    <>
      {verificationInfo !== null && (
        <>
          <div
            className="page-header"
            style={{
              height: "190px",
            }}
          >
            <div
              className="flex flex-col justify-center items-center mt-[30px]"
              style={{ height: "100px", padding: "10px" }}
            >
              <img
                src={logo}
                style={{ height: "70px", width: "206px", margin: "auto" }}
                alt="Logo"
              />
              <div
                style={{
                  wordBreak: "break-word",
                  textAlign: "center",
                  width: "343px",
                }}
                className="font-medium text-[16px] text-[#413C3C]"
              >
                A Brand of Verify Kart Screening Services <br />
                588 Sector A Mahalaxmi Nagar Indore M.P. Email
                Id:info@verifycheck.in Website:verifycheck.in
              </div>

              <button
                type="button"
                className="h-[190px] w-[200px]"
                onClick={() => window.print()}
                style={{ background: "pink" }}
              >
                PRINT ME!
              </button>
            </div>
          </div>

          <table className="mt-[66px]">
            <thead>
              <tr>
                <td>
                  <div className="page-header-space"></div>
                </td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  {/* *** CONTENT GOES HERE *** */}
                  <div className="page">
                    <div className="flex flex-row mt-7 p-[15px] bg-gray-300 justify-center items-center border border-black">
                      <h3>FINAL BACKGROUND REPORT</h3>
                    </div>

                    <table
                      style={{ borderCollapse: "collapse", width: "100%" }}
                      className="mt-2 border border-black"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              fontWeight: "600",
                            }}
                            className=""
                          >
                            Name of the Client
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            :
                          </td>
                          <td
                            style={{
                              padding: "8px",
                            }}
                          >
                            {verificationInfo?.companyInfo?.name}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            Name of the Candidate
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            :
                          </td>
                          <td
                            style={{
                              padding: "8px",
                            }}
                          >
                            {`${verificationInfo?.userInfo.firstName} ${verificationInfo?.userInfo.lastName}`}
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            Case Ref. Number
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                            }}
                            className=""
                          >
                            :
                          </td>
                          <td
                            style={{
                              padding: "8px",
                            }}
                          >
                            {`${title}-${verificationInfo?.case}`}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            Date Of Report
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                            }}
                            className=""
                          >
                            :
                          </td>
                          <td
                            style={{
                              padding: "8px",
                            }}
                          >
                            {formatDate(verificationInfo?.finalReportDate)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            Verification Status
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                            }}
                            className=""
                          >
                            :
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              color: "green",
                            }}
                          >
                            {verificationInfo?.status === "Approved"
                              ? "Clear"
                              : verificationInfo?.status}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h6 className="text-center mt-4">Color Code</h6>
                    <table
                      style={{
                        borderCollapse: "collapse",
                        width: "100%",
                      }}
                      className="mt-[5%]"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "green",
                              fontWeight: "600",
                            }}
                            className=""
                          >
                            Green
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Clear - All Information provided by the Candidate is
                            accurate
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "red",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            Red
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Authentication status is negative / Major
                            discrepancy
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "#FFD700",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            Amber
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Insufficient Information / Inaccessible for
                            verification / Unable to Verify
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "#04359b",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            Blue
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            WIP
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="page">
                    <h6 className="text-center mt-[15%]">Executive Summary</h6>
                    <table
                      style={{
                        borderCollapse: "collapse",
                        width: "100%",
                      }}
                      className="mt-[30px] border border-black"
                    >
                      <tbody>
                        <th
                          style={{
                            border: "2px solid black",
                            padding: "8px",
                            textAlign: "center",
                            backgroundColor: "#f2f2f2",
                            fontWeight: "600",
                          }}
                        >
                          Checks
                        </th>{" "}
                        <th
                          style={{
                            border: "2px solid black",
                            padding: "8px",
                            textAlign: "center",
                            backgroundColor: "#f2f2f2",
                            fontWeight: "600",
                          }}
                        >
                          Status
                        </th>{" "}
                        {verificationInfo?.finalReportInfo?.aadhaarAvidence
                          ?.proofUrl?.length !== 0 ? (
                            <tr>
                              <td
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                  fontWeight: "600",
                                }}
                                className="border border-black"
                              >
                              Aadhar Card Verification
                              </td>
                              <td
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                                className={
                                  verificationInfo?.finalReportInfo
                                    ?.aadhaarAvidence?.status === "Clear"
                                    ? "border border-black bg-green-700"
                                    : "border border-black bg-red-500"
                                }
                              >
                                <span
                                  className={
                                    verificationInfo?.finalReportInfo
                                      ?.aadhaarAvidence?.status === "Clear"
                                      ? "text-black-800	font-bold"
                                      : "text-black-800 font-bold"
                                  }
                                >
                                  {
                                    verificationInfo?.finalReportInfo
                                      ?.aadhaarAvidence?.status
                                  }
                                </span>
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        {verificationInfo?.finalReportInfo?.panAvidence
                          ?.proofUrl?.length !== 0 ? (
                            <tr>
                              <td
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                  fontWeight: "600",
                                }}
                                className="border border-black"
                              >
                              Pan Record Check
                              </td>
                              <td
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                                className={
                                  verificationInfo?.finalReportInfo?.panAvidence
                                    ?.status === "Clear"
                                    ? "border border-black bg-green-700"
                                    : "border border-black bg-red-500"
                                }
                              >
                                <span
                                  className={
                                    verificationInfo?.finalReportInfo?.panAvidence
                                      ?.status === "Clear"
                                      ? "text-black-800	font-bold"
                                      : "text-black-800 font-bold"
                                  }
                                >
                                  {
                                    verificationInfo?.finalReportInfo?.panAvidence
                                      ?.status
                                  }
                                </span>
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        {verificationInfo?.finalReportInfo?.courtAvidence
                          ?.proofUrl?.length !== 0 ? (
                            <tr>
                              <td
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                  fontWeight: "600",
                                }}
                                className="border border-black"
                              >
                              Court Record Check
                              </td>
                              <td
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                                className={
                                  verificationInfo?.finalReportInfo?.courtAvidence
                                    ?.status === "Clear"
                                    ? "border border-black bg-green-700"
                                    : "border border-black bg-red-500"
                                }
                              >
                                <span
                                  className={
                                    verificationInfo?.finalReportInfo
                                      ?.courtAvidence?.status === "Clear"
                                      ? "text-black-800	font-bold"
                                      : "text-black-800 font-bold"
                                  }
                                >
                                  {
                                    verificationInfo?.finalReportInfo
                                      ?.courtAvidence?.status
                                  }
                                </span>
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        {verificationInfo?.finalReportInfo?.educationAvidence
                          ?.length !== 0 &&
                        verificationInfo?.finalReportInfo?.educationAvidence?.every(
                          (educationEvidence) =>
                            educationEvidence?.proofUrl?.length !== 0 &&
                            educationEvidence?.status
                        ) ? (
                            verificationInfo?.finalReportInfo?.educationAvidence?.map(
                              (educationEvidence, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      padding: "8px",
                                      textAlign: "center",
                                      fontWeight: "600",
                                    }}
                                    className="border border-black"
                                  >
                                  Education Check
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                    className={
                                      educationEvidence?.status === "Clear"
                                        ? "border border-black bg-green-700"
                                        : "border border-black bg-red-500"
                                    }
                                  >
                                    <span
                                      className={
                                        educationEvidence?.status === "Clear"
                                          ? "text-black-800	font-bold"
                                          : "text-black-800 font-bold"
                                      }
                                    >
                                      {educationEvidence?.status}
                                    </span>
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <></>
                          )}
                        {verificationInfo?.finalReportInfo?.addressAvidence
                          ?.proofUrl?.length !== 0 ? (
                            <tr>
                              <td
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                  fontWeight: "600",
                                }}
                                className="border border-black"
                              >
                              Address Record Check
                              </td>
                              <td
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                                className={
                                  verificationInfo?.finalReportInfo
                                    ?.addressAvidence
                                    ?.statusOfPermanentAddress === "Clear"
                                    ? "border border-black bg-green-700"
                                    : "border border-black bg-red-500"
                                }
                              >
                                <span
                                  className={
                                    verificationInfo?.finalReportInfo
                                      ?.addressAvidence
                                      ?.statusOfPermanentAddress === "Clear"
                                      ? "text-black-800	font-bold"
                                      : "text-black-800 font-bold"
                                  }
                                >
                                  {
                                    verificationInfo?.finalReportInfo
                                      ?.addressAvidence?.statusOfPermanentAddress
                                  }
                                </span>
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        {verificationInfo?.finalReportInfo
                          ?.currentAddressAvidence?.proofUrl?.length !== 0 ? (
                            <tr>
                              <td
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                  fontWeight: "600",
                                }}
                                className="border border-black"
                              >
                              Current Address Record Check
                              </td>
                              <td
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                                className={
                                  verificationInfo?.finalReportInfo
                                    ?.currentAddressAvidence
                                    ?.statusOfCurrentAddress === "Clear"
                                    ? "border border-black bg-green-700"
                                    : "border border-black bg-red-500"
                                }
                              >
                                <span
                                  className={
                                    verificationInfo?.finalReportInfo
                                      ?.currentAddressAvidence
                                      ?.statusOfCurrentAddress === "Clear"
                                      ? "text-black-800	font-bold"
                                      : "text-black-800 font-bold"
                                  }
                                >
                                  {
                                    verificationInfo?.finalReportInfo
                                      ?.currentAddressAvidence
                                      ?.statusOfCurrentAddress
                                  }
                                </span>
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        {verificationInfo?.finalReportInfo
                          ?.previousEmployementAvidence?.length !== 0 &&
                        verificationInfo?.finalReportInfo?.previousEmployementAvidence?.every(
                          (evidence) => evidence?.status
                        ) ? (
                            verificationInfo?.finalReportInfo?.previousEmployementAvidence?.map(
                              (evidence, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      padding: "8px",
                                      textAlign: "center",
                                      fontWeight: "600",
                                    }}
                                    className="border border-black"
                                  >
                                  Previous Employment Record Check
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                    className={
                                      evidence?.status === "Clear"
                                        ? "border border-black bg-green-700"
                                        : "border border-black bg-red-500"
                                    }
                                  >
                                    <span className="text-black-800 font-bold">
                                      {evidence?.status}
                                    </span>
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <></>
                          )}
                        {verificationInfo?.finalReportInfo
                          ?.drivingLicenceAvidence?.proofUrl?.length !== 0 ? (
                            <tr>
                              <td
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                  fontWeight: "600",
                                }}
                                className="border border-black"
                              >
                              Driving Licence Record Check
                              </td>
                              <td
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                                className={
                                  verificationInfo?.finalReportInfo
                                    ?.drivingLicenceAvidence?.status === "Clear"
                                    ? "border border-black bg-green-700"
                                    : "border border-black bg-red-500"
                                }
                              >
                                <span
                                  className={
                                    verificationInfo?.finalReportInfo
                                      ?.drivingLicenceAvidence?.status === "Clear"
                                      ? "text-black-800	font-bold"
                                      : "text-black-800 font-bold"
                                  }
                                >
                                  {
                                    verificationInfo?.finalReportInfo
                                      ?.drivingLicenceAvidence?.status
                                  }
                                </span>
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        {verificationInfo?.finalReportInfo?.referenceAvidence
                          ?.length !== 0 &&
                        verificationInfo?.finalReportInfo?.referenceAvidence?.every(
                          (referenceEvidence) => referenceEvidence?.status
                        ) ? (
                            verificationInfo?.finalReportInfo?.referenceAvidence?.map(
                              (referenceEvidence, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      padding: "8px",
                                      textAlign: "center",
                                      fontWeight: "600",
                                    }}
                                    className="border border-black"
                                  >
                                  Reference Check
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                    className={
                                      referenceEvidence?.status === "Clear"
                                        ? "border border-black bg-green-700"
                                        : "border border-black bg-red-500"
                                    }
                                  >
                                    <span
                                      className={
                                        referenceEvidence?.status === "Clear"
                                          ? "text-black-800	font-bold"
                                          : "text-black-800 font-bold"
                                      }
                                    >
                                      {referenceEvidence?.status}
                                    </span>
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <></>
                          )}
                        {verificationInfo?.finalReportInfo?.sexOffenderAvidence
                          ?.proofUrl?.length !== 0 ? (
                            <tr>
                              <td
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                  fontWeight: "600",
                                }}
                                className="border border-black"
                              >
                              Sex Offender Check
                              </td>
                              <td
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                                className={
                                  verificationInfo?.finalReportInfo
                                    ?.sexOffenderAvidence?.status === "Clear"
                                    ? "border border-black bg-green-700"
                                    : "border border-black bg-red-500"
                                }
                              >
                                <span
                                  className={
                                    verificationInfo?.finalReportInfo
                                      ?.sexOffenderAvidence?.status === "Clear"
                                      ? "text-black-800	font-bold"
                                      : "text-black-800 font-bold"
                                  }
                                >
                                  {
                                    verificationInfo?.finalReportInfo
                                      ?.sexOffenderAvidence?.status
                                  }
                                </span>
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        {verificationInfo?.finalReportInfo
                          ?.globalDatabaseAvidence?.proofUrl?.length !== 0 ? (
                            <tr>
                              <td
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                  fontWeight: "600",
                                }}
                                className="border border-black"
                              >
                              Global Database Check
                              </td>
                              <td
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                                className={
                                  verificationInfo?.finalReportInfo
                                    ?.globalDatabaseAvidence?.status === "Clear"
                                    ? "border border-black bg-green-500"
                                    : "border border-black bg-red-500"
                                }
                              >
                                <span
                                  className={
                                    verificationInfo?.finalReportInfo
                                      ?.globalDatabaseAvidence?.status === "Clear"
                                      ? "text-black-800	font-bold"
                                      : "text-black-800 font-bold"
                                  }
                                >
                                  {
                                    verificationInfo?.finalReportInfo
                                      ?.globalDatabaseAvidence?.status
                                  }
                                </span>
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                      </tbody>
                    </table>
                  </div>
                  {/* aadhar Verification report ui */}
                  {verificationInfo?.finalReportInfo?.aadhaarAvidence?.proofUrl
                    ?.length > 0 ? (
                      <div className="page">
                        <h3 className="text-center mt-[100px]">
                        Evidence Documents for Aadhar Verification
                        </h3>
                        {verificationInfo?.finalReportInfo?.aadhaarAvidence?.proofUrl?.map(
                          (item) => {
                            return (
                              <>
                                <div className="mx-auto mt-[60px]">
                                  <AsyncImage
                                    className="h-[600px] w-full m-auto p-[90px]"
                                    fileUrl={item}
                                    alt="education evidence"
                                    url
                                  />
                                </div>
                              </>
                            );
                          }
                        )}
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                          Status :
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                          >
                            <span
                              className={
                                verificationInfo?.finalReportInfo?.aadhaarAvidence
                                  ?.status === "Clear"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {verificationInfo?.finalReportInfo?.aadhaarAvidence
                                ?.status === "Clear"
                                ? "Clear"
                                : "text-red-600 font-bold"}
                            </span>
                          </td>
                        </tr>
                      </div>
                    ) : (
                      ""
                    )}
                  {/* pan */}

                  {verificationInfo?.finalReportInfo?.panAvidence?.proofUrl
                    ?.length > 0 ? (
                      <div className="page">
                        <h3 className="text-center mt-[100px]">
                        Evidence Documents for Pan Verification
                        </h3>
                        {verificationInfo?.finalReportInfo?.panAvidence?.proofUrl?.map(
                          (item) => {
                            return (
                              <>
                                <div className="mx-auto mt-5">
                                  <AsyncImage
                                    className="h-[600px] w-full m-auto p-[90px]"
                                    fileUrl={item}
                                    alt="education evidence"
                                    url
                                  />
                                </div>
                              </>
                            );
                          }
                        )}
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                          Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                verificationInfo?.finalReportInfo?.panAvidence
                                  ?.status === "Clear"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {verificationInfo?.finalReportInfo?.panAvidence
                                ?.status === "Clear"
                                ? "Green"
                                : verificationInfo?.finalReportInfo?.panAvidence
                                  ?.status}
                            </span>
                          </td>
                        </tr>
                      </div>
                    ) : (
                      ""
                    )}
                  {/* Criminal Record Verification report ui */}

                  {/* court */}
                  {verificationInfo?.finalReportInfo?.courtAvidence?.proofUrl
                    ?.length > 0 ? (
                      <div className="page">
                        <h3 className="text-center mt-[100px]">
                        Evidence of Court Record Verification
                        </h3>
                        {verificationInfo?.finalReportInfo?.courtAvidence?.proofUrl?.map(
                          (item) => {
                            return (
                              <>
                                <div className="mx-auto mt-5">
                                  <AsyncImage
                                    className="h-[600px] w-full m-auto p-[90px]"
                                    fileUrl={item}
                                    alt="education evidence"
                                    url
                                  />
                                </div>
                              </>
                            );
                          }
                        )}

                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                          Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                verificationInfo?.finalReportInfo?.courtAvidence
                                  ?.status === "Clear"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {verificationInfo?.finalReportInfo?.courtAvidence
                                ?.status === "Clear"
                                ? "Green"
                                : verificationInfo?.finalReportInfo?.courtAvidence
                                  ?.status}
                            </span>
                          </td>
                        </tr>
                      </div>
                    ) : (
                      ""
                    )}
                  {/* Education report ui */}
                  {verificationInfo?.finalReportInfo?.educationAvidence
                    ?.length > 0 &&
                  verificationInfo?.finalReportInfo?.educationAvidence?.every(
                    (educationEvidence) =>
                      educationEvidence?.proofUrl?.length !== 0 &&
                      educationEvidence?.status
                  )
                    ? verificationInfo?.finalReportInfo?.educationAvidence?.map(
                      (educationEvidence, index) => (
                        <div key={index} className="page">
                          <h3 className="text-center mt-[100px]">
                              Evidence Document for Education
                          </h3>
                          <table
                            style={{
                              borderCollapse: "collapse",
                              width: "100%",
                            }}
                            className="mt-5"
                          >
                            <tbody>
                              <th
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                              >
                                  Details
                              </th>{" "}
                              <th
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                              >
                                  Information Provided
                              </th>{" "}
                              <th
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                              >
                                  Information Verified
                              </th>{" "}
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className=""
                                >
                                    Qualification
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {
                                    educationInfo[index]?.education
                                      ?.qualification
                                  }
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {educationEvidence?.qualification}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    Register No./Roll No.
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {
                                    educationInfo[index]?.education
                                      ?.enrollmentNumber
                                  }
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {educationEvidence?.enrollmentNumber}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    College/Insitute/School
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {
                                    educationInfo[index]?.education
                                      ?.schoolName
                                  }
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {educationEvidence?.schoolName}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    City
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {
                                    educationInfo[index]?.education
                                      ?.schoolAddress
                                  }
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {educationEvidence?.schoolAddress}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    University/Board
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {
                                    educationInfo[index]?.education
                                      ?.unversityName
                                  }
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {educationEvidence?.unversityName}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    Period Of Study
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {
                                    educationInfo[index]?.education
                                      ?.periodOfStudy
                                  }
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {educationEvidence?.periodOfStudy}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    Class / Divison / %
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {educationInfo[index]?.education?.grade}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {educationEvidence?.grade}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    Additional Comments
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderLeft: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "end",
                                  }}
                                >
                                  {educationEvidence?.additionalComments}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderRight: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    Status
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderLeft: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "end",
                                  }}
                                >
                                  <span
                                    className={
                                      educationEvidence?.status === "Clear"
                                        ? "text-green-600	font-bold"
                                        : "text-red-600 font-bold"
                                    }
                                  >
                                    {educationEvidence?.status}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderRight: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    Authenticated by
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderLeft: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "end",
                                  }}
                                >
                                  {educationEvidence?.authenticatedBy}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderRight: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderRight: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    Mode of Verification
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderLeft: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "end",
                                  }}
                                >
                                  {educationEvidence?.modeOfVerification}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderRight: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                ></td>
                              </tr>
                            </tbody>
                          </table>
                          <tr>
                            <td
                              style={{
                                padding: "8px",
                                textAlign: "center",
                                fontWeight: "600",
                                color: "green",
                              }}
                              className="employee-label"
                            >
                                Status :
                            </td>
                            <td
                              className="text-green-600	font-bold"
                              style={{
                                padding: "8px",
                                textAlign: "center",
                              }}
                            >
                              <span
                                className={
                                  educationEvidence?.status === "Clear"
                                    ? "text-green-600	font-bold"
                                    : "text-red-600 font-bold"
                                }
                              >
                                {educationEvidence?.status === "Clear"
                                  ? "Clear"
                                  : "Reject"}
                              </span>
                            </td>
                          </tr>
                          {educationEvidence?.proofUrl?.map((url, index) => (
                            <div key={index} className="mx-auto mt-[250px]">
                              <AsyncImage
                                className="h-[700px] w-full m-auto p-[90px]"
                                fileUrl={url}
                                alt="Proof URL for education evidence"
                              />
                            </div>
                          ))}
                          <tr>
                            <td
                              style={{
                                padding: "8px",
                                textAlign: "center",
                                fontWeight: "600",
                                color: "green",
                              }}
                              className="employee-label"
                            >
                                Status :
                            </td>
                            <td
                              className="text-green-600	font-bold"
                              style={{
                                padding: "8px",
                                textAlign: "center",
                              }}
                            >
                              <span
                                className={
                                  educationEvidence?.status === "Clear"
                                    ? "text-green-600	font-bold"
                                    : "text-red-600 font-bold"
                                }
                              >
                                {educationEvidence?.status === "Clear"
                                  ? "Clear"
                                  : educationEvidence?.status}
                              </span>
                            </td>
                          </tr>
                        </div>
                      )
                    )
                    : ""}

                  {/* address */}
                  {verificationInfo?.finalReportInfo?.addressAvidence?.proofUrl
                    ?.length > 0 ? (
                      <div className="page">
                        <h3 className="text-center mt-[100px]">
                        Evidence Document for Address
                        </h3>
                        <table
                          style={{
                            borderCollapse: "collapse",
                            width: "100%",
                          }}
                          className="mt-5"
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className=""
                              >
                              Name of Candidate
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.addressAvidence?.NameOfCandidate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Father Name
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.addressAvidence?.fatherName
                                }
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Address and contact number
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.addressAvidence?.addressAndContact
                                }
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Period Of Stay
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.addressAvidence?.periodOfStay
                                }
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Type of House
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.addressAvidence?.typeOfHouse
                                }
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Localty
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.addressAvidence?.localty
                                }
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Respondent`s name
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.addressAvidence?.respondentName
                                }
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Respondent`s contact No.
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.addressAvidence?.RespondentContact
                                }
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Respondent`s Relationship to candidate
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.addressAvidence?.RespondentCandidate
                                }
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Building Ownership
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.addressAvidence?.buildingOwner
                                }
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              If rented/lease,Owner`s Contact details
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.addressAvidence?.ownderContactDetail
                                }
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Landmark
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.addressAvidence?.landMark
                                }
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {verificationInfo?.finalReportInfo?.addressAvidence?.proofUrl?.map(
                          (item) => {
                            return (
                              <>
                                <div className="mx-auto mt-[250px]">
                                  <AsyncImage
                                    className="h-[600px] w-full m-auto p-[90px]"
                                    fileUrl={item}
                                    alt="education evidence"
                                    url
                                  />
                                </div>
                              </>
                            );
                          }
                        )}
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                          Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                verificationInfo?.finalReportInfo?.addressAvidence
                                  ?.statusOfPermanentAddress === "Clear"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {verificationInfo?.finalReportInfo?.addressAvidence
                                ?.statusOfPermanentAddress === "Clear"
                                ? "Green"
                                : verificationInfo?.finalReportInfo
                                  ?.addressAvidence?.statusOfPermanentAddress}
                            </span>
                          </td>
                        </tr>
                      </div>
                    ) : (
                      ""
                    )}

                  {/*current address */}
                  {verificationInfo?.finalReportInfo?.currentAddressAvidence
                    ?.proofUrl?.length > 0 ? (
                      <div className="page">
                        <h3 className="text-center mt-[100px]">
                        Evidence Document for Current Address
                        </h3>
                        <table
                          style={{
                            borderCollapse: "collapse",
                            width: "100%",
                          }}
                          className="mt-5"
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className=""
                              >
                              Name of Candidate
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.currentAddressAvidence?.NameOfCandidate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Father Name
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.currentAddressAvidence?.fatherName
                                }
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Address and contact number
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.currentAddressAvidence?.addressAndContact
                                }
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Period Of Stay
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.currentAddressAvidence?.periodOfStay
                                }
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Type of House
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.currentAddressAvidence?.typeOfHouse
                                }
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Localty
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.currentAddressAvidence?.localty
                                }
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Respondent`s name
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.currentAddressAvidence?.respondentName
                                }
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Respondent`s contact No.
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.currentAddressAvidence?.RespondentContact
                                }
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Respondent`s Relationship to candidate
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.currentAddressAvidence?.RespondentCandidate
                                }
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Building Ownership
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.currentAddressAvidence?.buildingOwner
                                }
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              If rented/lease,Owner`s Contact details
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.currentAddressAvidence?.ownderContactDetail
                                }
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                              Landmark
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.currentAddressAvidence?.landMark
                                }
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {verificationInfo?.finalReportInfo?.currentAddressAvidence?.proofUrl?.map(
                          (item) => {
                            return (
                              <>
                                <div className="mx-auto mt-[250px]">
                                  <AsyncImage
                                    className="h-[600px] w-full m-auto p-[90px]"
                                    fileUrl={item}
                                    alt="education evidence"
                                    url
                                  />
                                </div>
                              </>
                            );
                          }
                        )}
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                          Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                verificationInfo?.finalReportInfo
                                  ?.currentAddressAvidence
                                  ?.statusOfPermanentAddress === "Clear"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {verificationInfo?.finalReportInfo
                                ?.currentAddressAvidence
                                ?.statusOfPermanentAddress === "Clear"
                                ? "Green"
                                : verificationInfo?.finalReportInfo
                                  ?.currentAddressAvidence
                                  ?.statusOfPermanentAddress}
                            </span>
                          </td>
                        </tr>
                      </div>
                    ) : (
                      ""
                    )}

                  {/* previous employment */}
                  {verificationInfo?.finalReportInfo
                    ?.previousEmployementAvidence?.length > 0 &&
                  verificationInfo?.finalReportInfo?.previousEmployementAvidence?.every(
                    (evidence) => evidence.status
                  )
                    ? verificationInfo?.finalReportInfo?.previousEmployementAvidence?.map(
                      (evidence, index) => (
                        <div key={index} className="page">
                          <h3 className="text-center mt-[100px]">
                              Evidence Document for Previous Employment Evidence
                          </h3>
                          <table
                            style={{
                              borderCollapse: "collapse",
                              width: "100%",
                            }}
                            className="mt-5"
                          >
                            <tbody>
                              <th
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                              >
                                  Details
                              </th>{" "}
                              <th
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                              >
                                  Information Provided
                              </th>{" "}
                              <th
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                              >
                                  Information Verified
                              </th>{" "}
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className=""
                                >
                                    Experience Type
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {experienceInfo[index]?.experience?.type}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {evidence?.type}
                                </td>
                              </tr>
                              {experienceInfo[index]?.experience?.type ===
                                  "Experience" && (
                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                      backgroundColor: "#f2f2f2",
                                      fontWeight: "600",
                                    }}
                                    className=""
                                  >
                                      Company Name
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {
                                      experienceInfo[index]?.experience
                                        ?.companyName
                                    }
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {evidence?.companyName}
                                  </td>
                                </tr>
                              )}
                              {experienceInfo[index]?.experience?.type ===
                                  "Experience" && (
                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                      backgroundColor: "#f2f2f2",
                                      fontWeight: "600",
                                    }}
                                    className="employee-label"
                                  >
                                      Period Of Employment
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {
                                      experienceInfo[index]?.experience
                                        ?.periodOfEmployement
                                    }
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {evidence?.periodOfEmployement}
                                  </td>
                                </tr>
                              )}
                              {experienceInfo[index]?.experience?.type ===
                                  "Experience" && (
                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                      backgroundColor: "#f2f2f2",
                                      fontWeight: "600",
                                    }}
                                    className="employee-label"
                                  >
                                      Designation
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {
                                      experienceInfo[index]?.experience
                                        ?.designation
                                    }

                                    {/* {evidence?.designation} */}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {evidence?.designation}
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    Additional Comments{" "}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderLeft: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "end",
                                  }}
                                >
                                  {evidence?.additionalComment}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderRight: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    Status
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderLeft: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "end",
                                  }}
                                >
                                  <span
                                    className={
                                      evidence?.status === "Clear"
                                        ? "text-green-600	font-bold"
                                        : "text-red-600 font-bold"
                                    }
                                  >
                                    {evidence?.status}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderRight: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                ></td>
                              </tr>
                            </tbody>
                          </table>
                          {evidence?.proofUrl?.map((url, index) => (
                            <div key={index} className="mx-auto mt-[250px]">
                              <AsyncImage
                                className="h-[700px] w-full m-auto p-[90px]"
                                fileUrl={url}
                                alt="Proof URL for education evidence"
                              />
                            </div>
                          ))}
                          <tr>
                            <td
                              style={{
                                padding: "8px",
                                textAlign: "center",
                                fontWeight: "600",
                                color: "green",
                              }}
                              className="employee-label"
                            >
                                Status :
                            </td>
                            <td
                              className="text-green-600	font-bold"
                              style={{
                                padding: "8px",
                                textAlign: "center",
                              }}
                            >
                              <span
                                className={
                                  evidence?.status === "Clear"
                                    ? "text-green-600	font-bold"
                                    : "text-red-600 font-bold"
                                }
                              >
                                {evidence?.status === "Clear"
                                  ? "Green"
                                  : evidence?.status}
                              </span>
                            </td>
                          </tr>
                        </div>
                      )
                    )
                    : ""}

                  {verificationInfo?.finalReportInfo?.drivingLicenceAvidence
                    ?.proofUrl?.length > 0 ? (
                      <div className="page">
                        <h3 className="text-center mt-[100px]">
                        Evidence Documents for Driving Licence Verification
                        </h3>
                        {verificationInfo?.finalReportInfo?.drivingLicenceAvidence?.proofUrl?.map(
                          (item) => {
                            return (
                              <>
                                <div className="mx-auto mt-[60px]">
                                  <AsyncImage
                                    className="h-[600px] w-full m-auto p-[90px]"
                                    fileUrl={item}
                                    alt="education evidence"
                                    url
                                  />
                                </div>
                              </>
                            );
                          }
                        )}
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                          Status :
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                          >
                            <span
                              className={
                                verificationInfo?.finalReportInfo
                                  ?.drivingLicenceAvidence?.status === "Clear"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {verificationInfo?.finalReportInfo
                                ?.drivingLicenceAvidence?.status === "Clear"
                                ? "Clear"
                                : "text-red-600 font-bold"}
                            </span>
                          </td>
                        </tr>
                      </div>
                    ) : (
                      ""
                    )}

                  {/* {Reference} */}

                  {verificationInfo?.finalReportInfo?.referenceAvidence
                    ?.length > 0 &&
                  verificationInfo?.finalReportInfo?.referenceAvidence?.every(
                    (referenceEvidence) => referenceEvidence?.status
                  )
                    ? verificationInfo?.finalReportInfo?.referenceAvidence?.map(
                      (referenceEvidence, index) => (
                        <div key={index} className="page">
                          <h3 className="text-center mt-[100px]">
                              Evidence Document for Reference
                          </h3>
                          <table
                            style={{
                              borderCollapse: "collapse",
                              width: "100%",
                            }}
                            className="mt-5"
                          >
                            <tbody>
                              <th
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                              >
                                  Details
                              </th>{" "}
                              <th
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                              >
                                  Information Provided
                              </th>{" "}
                              <th
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                              >
                                  Information Verified
                              </th>{" "}
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className=""
                                >
                                    Type
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {referenceInfo[index]?.reference?.type}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {referenceEvidence?.type}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    Full Name
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {referenceInfo[index]?.reference?.fullName}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {referenceEvidence?.fullName}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    Contact Number
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {
                                    referenceInfo[index]?.reference
                                      ?.contactNumber
                                  }
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {referenceEvidence?.contactNumber}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    Email
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {referenceInfo[index]?.reference?.email}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {referenceEvidence?.email}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    City
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {referenceInfo[index]?.reference?.city}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {referenceEvidence?.city}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    State
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {referenceInfo[index]?.reference?.state}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {referenceEvidence?.state}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    Country
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {referenceInfo[index]?.reference?.country}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {referenceEvidence?.country}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    Pin Code
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {referenceInfo[index]?.reference?.pinCode}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  {referenceEvidence?.pinCode}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    Additional Comments
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderLeft: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "end",
                                  }}
                                >
                                  {referenceEvidence?.additionalComment}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderRight: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                  className="employee-label"
                                >
                                    Status
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderLeft: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "end",
                                  }}
                                >
                                  <span
                                    className={
                                      referenceEvidence?.status === "Clear"
                                        ? "text-green-600	font-bold"
                                        : "text-red-600 font-bold"
                                    }
                                  >
                                    {referenceEvidence?.status}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    borderRight: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                ></td>
                              </tr>
                            </tbody>
                          </table>
                          <tr>
                            <td
                              style={{
                                padding: "8px",
                                textAlign: "center",
                                fontWeight: "600",
                                color: "green",
                              }}
                              className="employee-label"
                            >
                                Status :
                            </td>
                            <td
                              className="text-green-600	font-bold"
                              style={{
                                padding: "8px",
                                textAlign: "center",
                              }}
                            >
                              <span
                                className={
                                  referenceEvidence?.status === "Clear"
                                    ? "text-green-600	font-bold"
                                    : "text-red-600 font-bold"
                                }
                              >
                                {referenceEvidence?.status === "Clear"
                                  ? "Clear"
                                  : "Reject"}
                              </span>
                            </td>
                          </tr>
                        </div>
                      )
                    )
                    : ""}

                  {verificationInfo?.finalReportInfo?.sexOffenderAvidence
                    ?.proofUrl?.length > 0 ? (
                      <div className="page">
                        <h3 className="text-center mt-[100px]">
                        Evidence Documents for Sex Offender Verification
                        </h3>
                        {verificationInfo?.finalReportInfo?.sexOffenderAvidence?.proofUrl?.map(
                          (item) => {
                            return (
                              <>
                                <div className="mx-auto mt-[60px]">
                                  <AsyncImage
                                    className="h-[600px] w-full m-auto p-[90px]"
                                    fileUrl={item}
                                    alt="education evidence"
                                    url
                                  />
                                </div>
                              </>
                            );
                          }
                        )}
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                          Status :
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                          >
                            <span
                              className={
                                verificationInfo?.finalReportInfo
                                  ?.sexOffenderAvidence?.status === "Clear"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {verificationInfo?.finalReportInfo
                                ?.sexOffenderAvidence?.status === "Clear"
                                ? "Clear"
                                : "text-red-600 font-bold"}
                            </span>
                          </td>
                        </tr>
                      </div>
                    ) : (
                      ""
                    )}

                  {verificationInfo?.finalReportInfo?.globalDatabaseAvidence
                    ?.proofUrl?.length > 0 ? (
                      <div className="page">
                        <h3 className="text-center mt-[100px]">
                        Evidence Documents for Global Database Verification
                        </h3>
                        {verificationInfo?.finalReportInfo?.globalDatabaseAvidence?.proofUrl?.map(
                          (item) => {
                            return (
                              <>
                                <div className="mx-auto mt-[60px]">
                                  <AsyncImage
                                    className="h-[600px] w-full m-auto p-[90px]"
                                    fileUrl={item}
                                    alt="education evidence"
                                    url
                                  />
                                </div>
                              </>
                            );
                          }
                        )}
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                          Status :
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                          >
                            <span
                              className={
                                verificationInfo?.finalReportInfo
                                  ?.globalDatabaseAvidence?.status === "Clear"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {verificationInfo?.finalReportInfo
                                ?.globalDatabaseAvidence?.status === "Clear"
                                ? "Clear"
                                : "Reject"}
                            </span>
                          </td>
                        </tr>
                      </div>
                    ) : (
                      ""
                    )}

                  <div className="mt-[100px] text-center">
                    <h1 className="flex flex-col ">Confidential</h1>
                  </div>

                  <div className="page">
                    <table
                      style={{
                        borderCollapse: "collapse",
                        width: "100%",
                      }}
                      className="mt-[100px]"
                    >
                      <tbody>
                        <tr>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "#f2f2f2",
                              fontWeight: "600",
                            }}
                          >
                            Status
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "#f2f2f2",
                              fontWeight: "600",
                            }}
                          >
                            Color Code{" "}
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "#f2f2f2",
                              fontWeight: "600",
                            }}
                          >
                            Remarks
                          </th>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Clear
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              color: "green",
                              fontWeight: "600",
                            }}
                          >
                            Green
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Detais provided
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table
                      style={{
                        borderCollapse: "collapse",
                        width: "100%",
                      }}
                      className="mt-5"
                    >
                      <tbody>
                        <tr>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "#f2f2f2",
                              fontWeight: "600",
                            }}
                          >
                            Status
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "#f2f2f2",
                              fontWeight: "600",
                            }}
                          >
                            Color Code{" "}
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "#f2f2f2",
                              fontWeight: "600",
                            }}
                          >
                            Remarks
                          </th>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Clear
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              color: "green",
                              fontWeight: "600",
                            }}
                          >
                            Green
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Detais provided
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <td>
                  <div className="page-footer-space"></div>
                </td>
              </tr>
            </tfoot>
          </table>
        </>
      )}
    </>
  );
};
