import React, { useState, useEffect, useRef } from "react";
import { Modal } from "antd";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import SignatureCanvas from "react-signature-canvas"; // New import
import ApiService from "../../services/APIServices";
import pamac_logo from "../../assets/images/pamac_logo.png";
import { customItemTemplate } from "../../Helper/UploadFileTemplate.jsx";

const PamacLetter = ({
  isOpen,
  onClose,
  userInfo,
  onSubmit,
  showSubmitButton = true,
  getUpdatedInfo,
}) => {
  const [fileUpload, setFileUpload] = useState([]);
  const [fileMap, setFileMap] = useState([]);
  const [date, setDate] = useState("");
  const [userSigned, setUserSigned] = useState("");
  const [signatureMode, setSignatureMode] = useState("upload"); // 'upload' or 'draw'
  const signaturePadRef = useRef(null);

  useEffect(() => {
    if (userInfo) {
      setUserSigned(userInfo?.userInfo?.userSigned || "");
      setDate(userInfo?.userInfo?.date || "");

      if (userInfo?.userInfo?.userSigned) {
        setFileUpload([userInfo.userInfo.userSigned]);
        fetchAndDisplaySavedImages();
      }
    }
  }, [userInfo]);

  const fetchAndDisplaySavedImages = async () => {
    if (fileUpload.length > 0) {
      try {
        const fileName = fileUpload[0].split("/").pop();
        const response = await ApiService.get(`download/${fileName}`);
        setFileMap({ 0: response.data });
      } catch (error) {
        console.error("Error fetching saved image:", error);
      }
    }
  };

  useEffect(() => {
    if (fileUpload.length > 0) {
      fetchAndDisplaySavedImages();
    }
  }, [fileUpload]);

  const handleFileUpload = async (event) => {
    let fileData = [];

    if (signatureMode === "draw") {
      // Handle drawn signature
      if (signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
        const signatureData = signaturePadRef.current.toDataURL();
        fileData.push({
          path: signatureData,
          name: `signature_${Date.now()}.png`,
        });
      } else {
        toast.error("Please create a signature first.");
        return;
      }
    } else {
      // Handle file upload (existing logic)
      const allowedExtensions = /\.(png|jpeg|pdf)$/i;
      const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
      const files = event.files;

      for (let file of files) {
        const fileNameParts = file.name.split(".");
        if (!allowedExtensions.exec(file.name) || fileNameParts.length > 10) {
          toast.error(
            "Invalid file type. Only PNG, JPEG, and PDF are allowed."
          );
          return;
        }
        if (file.size > maxFileSize) {
          toast.error("File size exceeds the limit.");
          return;
        }

        const base64String = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (e) => resolve(e.target.result);
          reader.readAsDataURL(file);
        });

        fileData.push({ path: base64String, name: file.name });
      }
    }

    // Update preview
    fileData.forEach((file, index) => {
      setFileMap((prev) => ({
        ...prev,
        [fileUpload.length + index]: file.path,
      }));
    });

    try {
      const res = await ApiService.post("multiUpload", { files: fileData });
      const uploadedFileUrls = res.data.map((item) => item.url);
      setFileUpload(uploadedFileUrls);
      if (uploadedFileUrls.length > 0) {
        setUserSigned(uploadedFileUrls[0]);
      }
      toast.success("Signature saved successfully.");

      if (signatureMode === "draw") {
        signaturePadRef.current.clear();
      }
    } catch (error) {
      console.error("File upload error: ", error);
      toast.error("Error saving signature. Please try again.");
    }
  };

  const clearSignature = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };

  const handleUpdate = async () => {
    const updateData = {
      userSigned: userSigned,
      date: date,
    };

    try {
      await ApiService.post(`user/${userInfo?.userInfo?._id}`, { updateData });

      const userInfoUpdate = {
        userInfo: {
          ...userInfo.userInfo,
          userSigned: userSigned,
          date: date,
        },
      };

      await ApiService.post(
        `userVerificationRequest/${userInfo._id}`,
        userInfoUpdate
      );
      toast.success("User Update Successfully");

      if (getUpdatedInfo) {
        getUpdatedInfo();
      }
    } catch (error) {
      toast.error("Error updating user information");
    }
  };

  const handleSubmit = async () => {
    await handleUpdate();
    if (onSubmit) {
      onSubmit();
    }
  };

  const downloadPamacLetter = async () => {
    try {
      const letterContentWithoutButton = document.querySelector(
        ".pamac-letter-content"
      );

      if (!letterContentWithoutButton) {
        toast.error("Could not find letter content");
        return;
      }

      const tempContainer = letterContentWithoutButton.cloneNode(true);
      const downloadButton = tempContainer.querySelector(
        ".download-buttons-container"
      );
      if (downloadButton) {
        downloadButton.style.display = "none";
      }

      const inputFields = tempContainer.querySelectorAll("input");

      inputFields.forEach((input) => {
        const div = document.createElement("div");
        div.className = "border p-2 rounded mb-2";

        if (input.type === "date" && input.value) {
          div.textContent = new Date(input.value).toLocaleDateString();
        } else {
          div.textContent = input.value || "";
        }

        if (input.parentNode) {
          input.parentNode.replaceChild(div, input);
        }
      });

      const signatureImg = tempContainer.querySelector("img[alt^='Preview']");
      if (signatureImg && fileMap[0]) {
        signatureImg.src = fileMap[0];
        await new Promise((resolve) => {
          const img = new Image();
          img.onload = resolve;
          img.onerror = resolve;
          img.src = fileMap[0];
        });
      }

      document.body.appendChild(tempContainer);
      tempContainer.style.position = "absolute";
      tempContainer.style.left = "-9999px";
      tempContainer.style.width = "800px";
      tempContainer.style.backgroundColor = "white";
      tempContainer.style.padding = "40px";

      const canvas = await html2canvas(tempContainer, {
        scale: 2,
        useCORS: true,
        backgroundColor: "#ffffff",
        width: 800,
        height: tempContainer.scrollHeight,
      });

      document.body.removeChild(tempContainer);

      canvas.toBlob(
        (blob) => {
          if (!blob) {
            toast.error("Failed to generate image");
            return;
          }

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `PAMAC_Letter_${userInfo?.userInfo?.firstName || "User"}_${new Date().toLocaleDateString().replace(/\//g, "-")}.png`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);

          toast.success("Letter downloaded successfully!");
        },
        "image/png",
        1.0
      );
    } catch (error) {
      console.error("Download error:", error);
      toast.error("Failed to download letter. Please try again.");
    }
  };

  return (
    <Modal width="70%" open={isOpen} footer={null} onCancel={onClose}>
      <div className="pamac-letter-content p-6">
        <div className="mb-4 relative">
          <div className="absolute top-0 right-0">
            <img
              src={pamac_logo}
              alt="PAMAC"
              className="w-[120px]"
              crossOrigin="anonymous"
            />
          </div>

          <div className="flex justify-center items-center h-[120px]">
            <h2 className="text-lg font-bold text-center">
              Letter of Authorization
            </h2>
          </div>
        </div>

        <div className="mb-6">
          <p className="text-justify">
            I Mr. / Ms.{" "}
            <span className="font-bold underline">
              {userInfo?.userInfo?.firstName} {userInfo?.userInfo?.lastName}
            </span>{" "}
            hereby authorize{" "}
            <span className="font-bold underline">
              {userInfo?.companyInfo?.name}
            </span>{" "}
            and its representative to verify information provided in my resume
            and applications of employment and to conduct enquire as may be
            necessary, at the company&apos;s discretion. I authorize all persons
            who may have information relevant to this enquiry to disclose it to
            or its representative. I release all persons from liability on
            account of such disclosure. In case the organization finds any
            information contrary to that provided by me, I accept their decision
            to withdraw the job offer made to me. The job offer would thereby
            stand null and void.
          </p>
        </div>

        <form className="space-y-4">
          <div className="row my-2">
            <div className="col-md-12 border-bottom">
              <label className="block mb-1 font-bold">Signed:</label>
              {fileUpload.length === 0 && (
                <div className="mb-4">
                  <div className="flex gap-4 mb-4">
                    <button
                      type="button"
                      className={`px-4 py-2 rounded ${
                        signatureMode === "upload"
                          ? "bg-sky-800 text-white"
                          : "bg-gray-200"
                      }`}
                      onClick={() => setSignatureMode("upload")}
                    >
                      Upload Signature
                    </button>
                    <button
                      type="button"
                      className={`px-4 py-2 rounded ${
                        signatureMode === "draw"
                          ? "bg-sky-800 text-white"
                          : "bg-gray-200"
                      }`}
                      onClick={() => setSignatureMode("draw")}
                    >
                      Draw Signature
                    </button>
                  </div>
                </div>
              )}

              {signatureMode === "upload" && fileUpload.length === 0 && (
                <FileUpload
                  name="fileUpload[]"
                  auto
                  multiple
                  accept="image/*"
                  customUpload={true}
                  uploadHandler={handleFileUpload}
                  itemTemplate={customItemTemplate}
                  required
                />
              )}

              {signatureMode === "draw" && fileUpload.length === 0 && (
                <div className="border rounded p-4">
                  <SignatureCanvas
                    ref={signaturePadRef}
                    canvasProps={{
                      className: "signature-canvas border rounded",
                      style: {
                        width: "100%",
                        height: "200px",
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                  />
                  <div className="mt-4 flex gap-4">
                    <button
                      type="button"
                      onClick={clearSignature}
                      className="px-4 py-2 bg-gray-200 rounded"
                    >
                      Clear
                    </button>
                    <button
                      type="button"
                      onClick={() => handleFileUpload({ files: [] })}
                      className="px-4 py-2 bg-sky-800 text-white rounded"
                    >
                      Save Signature
                    </button>
                  </div>
                </div>
              )}

              {/* Show saved signature preview */}
              {fileUpload.length > 0 && (
                <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                  {fileUpload.map((fileUrl, index) => (
                    <li
                      key={index}
                      className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                    >
                      {fileMap[index] && (
                        <img
                          src={fileMap[index]}
                          alt={`Preview ${index}`}
                          crossOrigin="anonymous"
                          className="mt-2 max-w-[200px] max-h-[200px] object-contain"
                        />
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div>
            <label className="block mb-1 font-bold" htmlFor="clientType">
              Name in Block Capitals:
            </label>
            <input
              type="text"
              value={`${userInfo?.userInfo?.firstName?.toUpperCase() || ""} ${userInfo?.userInfo?.lastName?.toUpperCase() || ""}`}
              className="w-full p-2 border rounded uppercase"
              readOnly
            />
          </div>

          <div>
            <label className="block mb-1 font-bold" htmlFor="clientType">
              Date of Birth:
            </label>
            <input
              type="date"
              value={
                userInfo?.userInfo?.dateOfBirth
                  ? new Date(userInfo.userInfo.dateOfBirth)
                    .toISOString()
                    .split("T")[0]
                  : ""
              }
              className="w-full p-2 border rounded"
              readOnly
            />
          </div>

          <div>
            <label className="block mb-1 font-bold" htmlFor="clientType">
              Date:
            </label>
            <input
              type="date"
              value={date || userInfo?.userInfo?.date || ""}
              onChange={(e) => setDate(e.target.value)}
              className="w-full p-2 border rounded"
              required
            />
          </div>
        </form>

        <div className="text-sm mt-4">
          <p>
            *A general authority letter will be issued by the employer, which
            will confirm that PAMAC Finserve Private Limited is authorized to
            conduct the background verification checks on behalf of the employer
            from the concerned authorities.
          </p>
        </div>

        <div className="mt-6 text-center flex justify-center gap-4 download-buttons-container">
          {showSubmitButton && !userInfo?.isDetailFilled?.status && (
            <button
              type="button"
              onClick={handleSubmit}
              className="w-[141px] text-white text-xs font-medium h-[37px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow cursor-pointer"
            >
              Submit
            </button>
          )}

          <Button
            label="Download"
            onClick={downloadPamacLetter}
            className="w-[170px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
            icon="pi pi-download"
            iconPos="left"
          />
        </div>
      </div>
    </Modal>
  );
};

export default PamacLetter;
