import { Button, Form, Modal, Switch, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import { useTable, useRowSelect, useSortBy } from "react-table";
import NoData from "../../Helper/NoDataFound.jsx";
import ApiService from "../../services/APIServices";
import Stack from "@mui/material/Stack";
import { InputText } from "primereact/inputtext";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProgressSpinner } from "primereact/progressspinner";
import UploadBulkStaffModal from "./UploadBulkStaffModal.jsx";
import AddFieldExecutiveModal from "./AddFieldExecutiveModal.jsx";
import { toast } from "react-toastify";
import BCATabs from "./BCATabs.jsx";
import CryptoJS from "crypto-js";
import { casesCheckList, envChecks } from "../../utils/constants.js";
import { MultiSelect } from "primereact/multiselect";
import { Sidebar } from "primereact/sidebar";
import Icons from "../../Helper/Icons.jsx";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import ExecutiveLogs from "../StaffMember/ExecutiveLogs.jsx";

export const StaffMemberList = () => {
  const [page, setPage] = useState(1);
  const [data, setDataStaff] = useState([]);
  const [search, setSearch] = useState("");
  const [spinner, setSpinner] = useState(true);
  const [showAddStaff, setShowaddStaff] = useState(false);
  const [showAddStaffBulk, setShowaddStaffBulk] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [panCard, setPanCard] = useState("");
  const [aadharCard, setAadharCard] = useState("");
  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [email, setEmail] = useState(null);
  const [state, setState] = useState("");
  const [type, setType] = useState("");
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  const [BCAInfo, setBCAInfo] = useState("");
  const [totalPages, setTotalPages] = useState();
  const [selectedChecks, setSelectedChecks] = useState([]);
  const [checksArray, setChecksArray] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedCompanyDetail, setSelectedCompanyDetail] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [visibleRight, setVisibleRight] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");

  const checks = casesCheckList.filter((check) => envChecks[check.name]);

  const handleSubmit = () => {
    const updatedChecks = [
      ...selectedCompanyDetail?.assignedChecks,
      ...checksArray,
    ].filter(
      (check, index, self) => index === self.findIndex((c) => c === check) // Ensures no duplicates
    );

    ApiService.post(`BCAStaff/${selectedCompanyDetail?._id}`, {
      assignedChecks: updatedChecks,
    })
      .then(() => {
        toast.success("Check updated successfully");
        setVisibleModal(false);
        setSelectedChecks([]);
        getStaff();
      })
      .catch(() => {
        toast.error("Error in check update");
      });
  };

  const handleRemoveAssignedCheck = (index) => {
    const updatedAssignedChecks = selectedCompanyDetail.assignedChecks.filter(
      (_, i) => i !== index
    );

    setSelectedCompanyDetail((prev) => ({
      ...prev,
      assignedChecks: updatedAssignedChecks,
    }));
  };

  const handleRemoveSelectedCheck = (index) => {
    const updatedSelectedChecks = selectedChecks.filter((_, i) => i !== index);

    setSelectedChecks(updatedSelectedChecks);

    const updatedChecksArray = updatedSelectedChecks.map((check) => check.name);
    setChecksArray(updatedChecksArray);
  };

  const checksTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  const getUserInfo = () => {
    let BCA = JSON.parse(localStorage.getItem("user"));
    setBCAInfo(BCA);
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  const onBulkStaff = async () => {
    setIsLoading(true);

    const body = {
      dataArray: jsonData,
      BCAId: BCAInfo._id,
      role: "BCAStaff",
    };

    ApiService.post("BCAStaff/bulk", body)
      .then(() => {
        setIsLoading(false);
        setShowaddStaffBulk(false);
        getStaff();
        toast.success("Field Executive Added successfully");
        form.resetFields();
      })
      .catch(() => {
        setIsLoading(false);
        setShowaddStaffBulk(false);
        form.resetFields();
        toast.error("User is already Registered");
      });
  };

  useEffect(() => {
    if (jsonData?.length > 0) {
      onBulkStaff();
    }
  }, [jsonData]);

  const closeModal = () => {
    setShowaddStaffBulk(false);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleDataParsed = (data) => {
    setJsonData(data); // You can set the parsed data to state or use it in any way needed
  };

  let queryParams = `?limit=4&page=${page}`;
  if (search) {
    queryParams += `&text=${search}`;
  }
  const getStaff = () => {
    let BCA = JSON.parse(localStorage.getItem("user"));
    let BCAId = BCA.role === "BCA" ? BCA._id : BCA.BCAId;

    ApiService.get(`BCAStaff/${BCAId}${queryParams}`, {
      text: search,
    })
      .then((res) => {
        setDataStaff(res?.data?.docs);
        setTotalPages(res?.data?.totalPages);
        setSpinner(false);
      })
      .catch(() => {
        setSpinner(false);
        toast.error("Failed to fetch staff. Please try again.");
      });
  };

  useEffect(() => {
    getStaff();
  }, [search, page]);

  const onchange = (id, newValue) => {
    ApiService.post(`BCAStaff/${id}`, { hasStaffAccess: newValue })
      .then(() => {
        setDataStaff((prevData) =>
          prevData.map((item) =>
            item._id === id ? { ...item, hasStaffAccess: newValue } : item
          )
        );
      })
      .catch(() => {});
  };

  const confirm1 = (id) => {
    confirmDialog({
      message: (
        <div className="flex items-center pt-[17px]">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          <span className="pt-[2px]">Are you sure you want to DELETE?</span>
        </div>
      ),
      header: "Confirmation",
      accept: () => accept(id),
      reject: () => setDialogVisible(false), // Close dialog on reject
      onHide: () => setDialogVisible(false), // Close dialog on close
    });
  };

  const accept = (id) => {
    deleteMember(id);
  };

  const deleteMember = (id) => {
    const url = `BCA/remove/${id}`;

    ApiService.post(url)
      .then(() => {
        setDialogVisible(false);
        toast.success("Member Deleted Successfully");
        getStaff();
      })
      .catch(() => {
        toast.error("Error In Delete Member");
      });
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
        Cell: ({ row }) => {
          const firstName = row?.original?.firstName;
          return (
            <div
              className="flex cursor-pointer align-items-center gap-2 text-teal-500 hover:text-blue-500"
              role="presentation"
              onClick={() => {
                setVisibleRight(true);
                setSelectedUser(row?.original);
              }}
            >
              <span>
                <Tooltip
                  placement="top"
                  title="Click here to watch activity logs"
                >
                  <span>
                    {firstName ? <span>{firstName}</span> : <span>NA</span>}
                  </span>
                </Tooltip>
              </span>
            </div>
          );
        },
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "User Name",
        accessor: "userName",
        Cell: ({ row }) => {
          const userName = row?.original?.userName;

          return (
            <div className="flex align-items-center gap-2">
              {userName ? <span>{userName}</span> : <span>NA</span>}
            </div>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Checks",
        accessor: "",
        Cell: ({ row }) => {
          return (
            <div
              role="presentation"
              onClick={() => {
                setVisibleModal(true);
                setSelectedCompanyDetail(row?.original);
              }}
              className="flex cursor-pointer align-items-center gap-2"
            >
              <Icons name="sidebar-arrow" />
            </div>
          );
        },
      },
      {
        Header: "Access",
        accessor: "hasStaffAccess",
        Cell: ({ row }) => (
          <Switch
            checked={row?.original?.hasStaffAccess}
            onChange={() =>
              onchange(row?.original?._id, !row?.original?.hasStaffAccess)
            }
          />
        ),
      },
      {
        Header: "Delete",
        Cell: ({ row }) => {
          const id = row?.original?._id;
          return (
            <div
              className="cursor-pointer"
              role="presentation"
              onClick={() => confirm1(id)}
            >
              <DeleteIcon style={{ color: "red" }} />
            </div>
          );
        },
      },
    ],
    []
  );

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data },
    useSortBy,
    useRowSelect
  );

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/;
    return passwordRegex.test(password);
  };

  const secretKey = process.env.REACT_APP_SECRET_KEY;

  const encryptField = (field) => {
    if (!field) return field; // If the field is empty or null, return as is
    return CryptoJS.AES.encrypt(field, secretKey).toString();
  };

  const onFinish = () => {
    if (!validatePassword(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }
    setIsLoading(true);
    const body = {
      BCAId: BCAInfo.role === "BCA" ? BCAInfo._id : BCAInfo.BCAId,
      CCId: BCAInfo._id,
      assignedChecks: checksArray,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      role: "BCAStaff",
      aadharCard: aadharCard,
      panCard: panCard,
      userName: userName,
      password: encryptField(password),
      state: state,
      type: type,
    };
    ApiService.post("BCAStaff", body)
      .then(() => {
        setIsLoading(false);
        toast.success("Field Executive added successfully");
        setShowaddStaff(false);
        form.resetFields();
        getStaff();
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Something went wrong");
      });
  };
  return (
    <>
      <div className=" mx-auto bg-white">
        <div className="flex flex-row lg:justify-end mt-[25px] xl:mt-0 w-full">
          <BCATabs
            setShowaddStaff={setShowaddStaff}
            setShowaddStaffBulk={setShowaddStaffBulk}
          />
          <AddFieldExecutiveModal
            form={form}
            showAddStaff={showAddStaff}
            setShowaddStaff={setShowaddStaff}
            onFinish={onFinish}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            aadharCard={aadharCard}
            setAadharCard={setAadharCard}
            panCard={panCard}
            setPanCard={setPanCard}
            state={state}
            setState={setState}
            type={type}
            setType={setType}
            userName={userName}
            setUserName={setUserName}
            selectedChecks={selectedChecks}
            setSelectedChecks={setSelectedChecks}
            checksArray={checksArray}
            setChecksArray={setChecksArray}
            password={password}
            setPassword={setPassword}
            passwordError={passwordError}
            isLoading={isLoading}
          />

          <UploadBulkStaffModal
            showAddStaffBulk={showAddStaffBulk}
            setShowAddStaffBulk={setShowaddStaffBulk}
            handleDataParsed={handleDataParsed}
            closeModal={closeModal}
          />
        </div>{" "}
        <div className="flex flex-col mt-[20px]">
          <div className=" shadow p-[30px]  overflow-x-auto ">
            <div className="inline-block min-w-full  align-middle ">
              <div className="justify-between flex flex-row">
                <div className=" text-black text-[22px] font-semibold ">
                  Verification Executives
                </div>

                <div className="flex flex-row gap-[5px] ">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      className="h-[39px]"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      placeholder="Search by Name"
                    />
                  </span>
                </div>
              </div>

              <div className="w-full mt-[20px]  flex md:flex md:overflow-x-auto lg:overflow-x-visible md:rounded-lg">
                <table className="mt-2 min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-white">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        className="px-6 py-4"
                        key={Math.random().toString(36).substr(2, 9)}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            className="py-3.5 px-4 text-gray-400 text-sm font-medium rtl:text-right text-nowrap "
                            key={Math.random().toString(36).substr(2, 9)}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ""
                                  : ""
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900"
                    {...getTableBodyProps()}
                  >
                    {spinner ? (
                      <tr>
                        <td colSpan={columns.length}>
                          <div className="text-center mt-1">
                            <ProgressSpinner
                              style={{ width: "50px", height: "50px" }}
                              strokeWidth="8"
                              animationDuration=".7s"
                            />
                          </div>
                        </td>
                      </tr>
                    ) : data.length === 0 ? (
                      <tr>
                        <td colSpan={columns.length}>
                          <NoData info="Ooops, we could not find any case" />
                        </td>
                      </tr>
                    ) : (
                      rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap"
                            key={Math.random().toString(36).substr(2, 9)}
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell) => {
                              const cellValue = cell.value || "";
                              const columnHeader = cell.column.render("Header");
                              return (
                                <td
                                  className="px-4 py-4 text-zinc-800 text-sm font-medium whitespace-nowrap"
                                  key={Math.random().toString(36).substr(2, 9)}
                                  {...cell.getCellProps()}
                                >
                                  {columnHeader === "User Name" ||
                                  columnHeader === "Email" ? (
                                      <Tooltip placement="top" title={cellValue}>
                                        <span>
                                          {cellValue?.substring(0, 5)}...
                                        </span>
                                      </Tooltip>
                                    ) : (
                                      cell.render("Cell")
                                    )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-center mt-4 mb-4">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages}
                    onChange={handleChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    page={page}
                  />
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        width="30%"
        title="Select Checks"
        open={visibleModal}
        footer={null}
        onCancel={() => setVisibleModal(false)}
        className="custom-modal-content modalRespo"
      >
        <div>
          <MultiSelect
            value={selectedChecks}
            options={checks}
            onChange={(e) => {
              setSelectedChecks(e.value);

              const selectedChecksNames = e.value.map((check) => check.name);
              setChecksArray(selectedChecksNames);
            }}
            optionLabel="name"
            placeholder="Select Type"
            itemTemplate={checksTemplate}
            className="w-full md:w-20rem"
            display="chip"
            filter
          />
        </div>

        <div className="mt-[20px]">
          <div className="text-black text-[22px] font-semibold">
            Selected Checks:
          </div>

          {selectedCompanyDetail?.assignedChecks?.length > 0 && (
            <ul className="!pl-0">
              {selectedCompanyDetail.assignedChecks.map((check, index) => (
                <li
                  key={`assigned-${index}`}
                  className="flex items-center justify-between bg-gradient-to-r from-sky-800 to-teal-400 font-semibold text-transparent bg-clip-text text-sm font-medium whitespace-nowrap my-1"
                >
                  <span>{check}</span>
                  <button
                    className="text-red-500 hover:text-red-700 ml-2"
                    onClick={() => handleRemoveAssignedCheck(index)}
                  >
                    ✖
                  </button>
                </li>
              ))}
            </ul>
          )}

          <ul className="!pl-0">
            {selectedChecks.map((check, index) => (
              <li
                key={`selected-${index}`}
                className="flex items-center justify-between bg-gradient-to-r from-sky-800 to-teal-400 font-semibold text-transparent bg-clip-text text-sm font-medium whitespace-nowrap my-1"
              >
                <span>{check.name}</span>
                <button
                  className="text-red-500 hover:text-red-700 ml-2"
                  onClick={() => handleRemoveSelectedCheck(index)}
                >
                  ✖
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="mt-3">
          <Button
            className="w-[100px] max-[700px]:mt-2 text-white text-xs font-medium h-[41px] !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </Modal>

      <ConfirmDialog visible={dialogVisible} />
      <Sidebar
        visible={visibleRight}
        style={{ width: "1200px" }}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <ExecutiveLogs user={selectedUser} />
      </Sidebar>
    </>
  );
};
