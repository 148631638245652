import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Link } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const AadharCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
    // height: "590px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 80,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

const getStatusColor = (status) => {
  switch (status) {
  case "Major Discrepancy":
    return "red";
  case "Minor Discrepancy":
    return "orange";
  case "Verified Clear":
    return "green";
  case "Insufficiency":
    return "#859bed";
  case "Unable to Verify":
    return "orange";
  case "Stop Check":
    return "gray";
  case "Rejected":
    return "red";
  case "Approved":
    return "green";
  case "Completed":
    return "green";
  case "InProgress":
    return "#859bed";
  case "Pending":
    return "orange";
  case "BcaApproval":
    return "green";
  case "BcaRejection":
    return "red";
  case "Positive":
    return "green";
  case "Negative":
    return "red";
  case "Recommended":
    return "yellow";
  case "Not Recommended":
    return "#859bed";
  case "Failed":
    return "red";
  case "Could Not Verified":
    return "red";
  default:
    return "yellow";
  }
};

const formatDate = (dateString) => {
  let date;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const VleaderAadharDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [aadharInfo, setAadharInfo] = useState([]);

  const getAadharInfo = (id) => {
    if (id) {
      ApiService.get(`identityCheck/${id}`)
        .then((res) => {
          const aadharData = res?.data?.docs.find(
            (doc) => doc.identity?.type === "Aadhaar Card"
          );
          if (aadharData) {
            console.log("AadharCard>>>", aadharData);
            setAadharInfo(aadharData);
          } else {
            console.log("No Aadhaar Card data found");
            setAadharInfo([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    console.log("AAdharInfo>>>>", aadharInfo);
  }, [aadharInfo]);

  //   useEffect(() => {
  //     const id = location?.state?.id;
  //     if (id) {
  //       getAadharInfo(id);
  //     } else {
  //       console.log("No id found in location state");
  //     }
  //   }, [location?.state?.id]);

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getAadharInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  //   useEffect(() => {
  //     if (aadharInfo.length > 0) {
  //       onDataUpdate({
  //         aadharInfo,
  //         renderAadharDetails: () => renderAadharDetails(),
  //       });
  //     }
  //   }, [aadharInfo]);

  useEffect(() => {
    console.log("aadharInfo state updated:", aadharInfo);

    if (aadharInfo && Object.keys(aadharInfo).length > 0) {
      console.log("onDataUpdate called with:", {
        aadharInfo,
        renderAadharDetails: () => renderAadharDetails(),
      });

      onDataUpdate({
        aadharInfo,
        renderAadharDetails: () => renderAadharDetails(),
      });
    }
  }, [aadharInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      <Link href={imgSrc} className={className || ""} target="_blank">
        {alt || "Click here to view the image"}
      </Link>
    );
  };

  const renderAadharDetails = () => {
    return (
      <>
        <React.Fragment
        //    key={index}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: 5,
              margin: 10,
            }}
          >
            <Text
              style={{
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              Identity Aadhar Details:
            </Text>

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: "bold",
                  marginRight: 5,
                }}
              >
                Check Status:
              </Text>
              <View
                style={{
                  backgroundColor: getStatusColor(aadharInfo?.identity?.status),
                  paddingVertical: 2,
                  paddingHorizontal: 10,
                  borderRadius: 4,
                }}
              >
                <Text
                  style={{
                    color: "black",
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  {aadharInfo?.identity?.status}
                </Text>
              </View>
            </View>
          </View>
          {/* Render table on one page */}
          <View style={AadharCheckStyles.table}>
            <View style={AadharCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 8,
                  fontSize: 12,
                  fontWeight: "bold",
                  backgroundColor: "#d3d3d3",
                }}
              >
                Details
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 8,
                  fontSize: 12,
                  fontWeight: "bold",
                  backgroundColor: "#d3d3d3",
                }}
              >
                As Provided
              </Text>
            </View>
            <View style={AadharCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Aadhar Number
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.cardNumber} */}
                {aadharInfo?.identity?.cardNumber}
              </Text>
            </View>
            <View style={AadharCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Name
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.name} */}
                {aadharInfo?.identity?.name}
              </Text>
            </View>
            <View style={AadharCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Fathers Name
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.fathersName} */}
                {aadharInfo?.identity?.fathersName}
              </Text>
            </View>
            <View style={AadharCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Candidate - Date of Birth
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.dateOfBirth} */}
                {aadharInfo?.identity?.dateOfBirth
                  ? formatDate(aadharInfo?.identity?.dateOfBirth)
                  : "NA"}
              </Text>
            </View>
            <View style={AadharCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Address
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.address} */}
                {aadharInfo?.identity?.address}
              </Text>
            </View>
            <View style={AadharCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                State
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.state} */}
                {aadharInfo?.identity?.state}
              </Text>
            </View>
            <View style={AadharCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Gender
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.gender} */}
                {aadharInfo?.identity?.gender}
              </Text>
            </View>
            <View style={AadharCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Last digit of mobile Number
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.lastDigit} */}
                {aadharInfo?.identity?.lastDigit}
              </Text>
            </View>
            <View style={AadharCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Age
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.age} */}
                {aadharInfo?.identity?.age}
              </Text>
            </View>
            <View style={AadharCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Date of Verification
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.dateOfVerification} */}
                {aadharInfo?.identity?.dateOfVerification
                  ? formatDate(aadharInfo?.identity?.dateOfVerification)
                  : "NA"}
                  
              </Text>
            </View>
            <View style={AadharCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Record summery
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.recordSummery} */}
                {aadharInfo?.identity?.recordSummery}
              </Text>
            </View>
            <View style={AadharCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Proof
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {aadharInfo?.identity?.uploadProofUrl?.map(
                  (url, proofIndex) => {
                    return (
                      <Text key={proofIndex} style={{ marginRight: 5 }}>
                        <AsyncImage
                          fileUrl={url}
                          alt={`Aadhar evidence ${proofIndex + 1}`}
                        />
                        {proofIndex <
                          aadharInfo.identity.uploadProofUrl.length - 1 && (
                          <Text style={{ marginRight: 5 }}> , </Text>
                        )}
                      </Text>
                    );
                  }
                )}
              </Text>
            </View>
          </View>
          {/* <View break /> */}

          <View break />
        </React.Fragment>
      </>
    );
  };

  return null;
};
