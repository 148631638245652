import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    margin: 40,
    padding: 10,
    textAlign: "justify",
    fontSize: 10,
    lineHeight: 1.5,
  },
  header: {
    fontSize: 13,
    marginBottom: 10,
  },
  disclaimerTitle: {
    fontSize: 13,
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 10,
  },
  paragraph: {
    marginBottom: 10,
  },
  footer: {
    marginTop: 20,
    textAlign: "center",
    fontSize: 13,
    fontWeight: "bold",
  },
});

export const SiriusDisclaimer = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.header}>
        Audited by Name: Sirisha G {"\n"}Designation: Head Verifications
      </Text>
      <Text style={styles.disclaimerTitle}>Disclaimer</Text>
      <Text style={styles.paragraph}>
        This report is based on the information gathered from various sources
        that was made available to Sirius team during the verification process
        and should not be considered as a definitive pronouncement on the
        individual/s whose background was sought to be verified. The Sirius team
        has no responsibility to update its findings for events or circumstances
        occurring after the date of this report, unless specifically requested
        for.
      </Text>
      <Text style={styles.paragraph}>
        The report is based on our understanding of the information and the
        facts provided in the Case Initiation Form pertaining to your
        candidates/employees. In case of any change in the fact pattern, our
        comments may need to be revisited.
      </Text>
      <Text style={styles.paragraph}>
        All draft findings, interim advice and preliminary reports in this
        regard issued before this final report stand withdrawn.
      </Text>
      <Text style={styles.paragraph}>
        This report is primarily for the information and use of the Client but
        may be disclosed to its legal advisors or external auditors after the
        final report is issued by Sirius. We make no representation or warranty
        on the contents of the report other than those mentioned in the
        agreement for services with the Client.
      </Text>
      <Text style={styles.paragraph}>
        The report may not be used for any other purpose, or transmitted,
        distributed to any other party, by the Client without our prior written
        consent.
      </Text>
      <Text style={styles.paragraph}>
        The education checks are only for informational purposes, and not valid
        for any legal purpose.
      </Text>
      <Text style={styles.paragraph}>
        The Sirius team reserves the right to change or modify the report
        without any notice to Client and upload its contents on to Sirius’s
        database of verified profiles. Sirius may share the contents of the
        report at any time with any other client without any prior notice to the
        Client.
      </Text>
      <Text style={styles.footer}>End of Report</Text>
    </View>
  );
};
