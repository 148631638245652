import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const CreditCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
    // height: "590px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 40,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

const getStatusColor = (status) => {
  switch (status) {
  case "Major Discrepancy":
    return "red";
  case "Minor Discrepancy":
    return "orange";
  case "Verified Clear":
    return "green";
  case "Insufficiency":
    return "#859bed";
  case "Unable to Verify":
    return "orange";
  case "Stop Check":
    return "gray";
  case "Rejected":
    return "red";
  case "Approved":
    return "green";
  case "Completed":
    return "green";
  case "InProgress":
    return "#859bed";
  case "Pending":
    return "orange";
  case "BcaApproval":
    return "green";
  case "BcaRejection":
    return "red";
  case "Positive":
    return "green";
  case "Negative":
    return "red";
  case "Recommended":
    return "yellow";
  case "Not Recommended":
    return "#859bed";
  case "Failed":
    return "red";
  case "Could Not Verified":
    return "red";
  default:
    return "yellow";
  }
};

export const SiriusCreditDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [creditInfo, setCreditInfo] = useState([]);

  const getCreditInfo = (id) => {
    if (id) {
      ApiService.get(`creditCheck/${id}`)
        .then((res) => {
          setCreditInfo(res?.data?.docs);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getCreditInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (creditInfo.length > 0) {
      onDataUpdate({
        creditInfo,
        renderCreditDetails: () => renderCreditDetails(),
      });
    }
  }, [creditInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
      <Image src={imgSrc} alt={alt || "image"} className={className || ""} />
    );
  };

  const renderCreditDetails = () => {
    return (
      <>
        {creditInfo?.length > 0 && (
          <>
            {creditInfo.map((creditEvidence, index) => (
              <React.Fragment key={index}>
                {/* Render table on one page */}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: 1,
                    marginTop: 50,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  >
                    Detailed Report-Credit check
                  </Text>
                </View>
                <View style={CreditCheckStyles.table}>
                  <View style={CreditCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Facts
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Details provided by the client
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Verification remarks
                    </Text>
                  </View>
                  <View style={CreditCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Name of the candidate
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {creditEvidence?.credit?.name}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {creditEvidence?.verificationDetails?.nameRemark}
                    </Text>
                  </View>
                  <View style={CreditCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Name of the father
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {creditEvidence?.credit?.fatherName}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {creditEvidence?.verificationDetails?.fathersNameRemark}
                    </Text>
                  </View>
                  <View style={CreditCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Date of birth
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {creditEvidence?.credit?.dateOfBirth}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {creditEvidence?.verificationDetails?.dateOfBirthRemark}
                    </Text>
                  </View>
                  <View style={CreditCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Address
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {creditEvidence?.credit?.address}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {creditEvidence?.verificationDetails?.addressRemark}
                    </Text>
                  </View>
                  <View style={CreditCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      PAN Number
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {creditEvidence?.credit?.panNumber}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {creditEvidence?.verificationDetails?.panNumberRemark}
                    </Text>
                  </View>
                  <View style={CreditCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      CIBIL Score
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {creditEvidence?.credit?.cibilScore}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {creditEvidence?.verificationDetails?.cibilScoreRemark}
                    </Text>
                  </View>
                  <View style={CreditCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Status
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    ></Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                        backgroundColor: getStatusColor(
                          creditEvidence?.credit?.status
                        ),
                      }}
                    >
                      {creditEvidence?.credit?.status}
                    </Text>
                  </View>
                  <View style={CreditCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Final remarks
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {creditEvidence?.credit?.additionalRemark}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      {creditEvidence?.verificationDetails?.additionalRemarks}
                    </Text>
                  </View>
                </View>
                <View>
                  <Text> </Text>
                </View>{" "}
                <View>
                  <Text> </Text>
                </View>{" "}
                <View>
                  <Text> </Text>
                </View>{" "}
                <View>
                  <Text> </Text>
                </View>{" "}
                {/* <View break /> */}
                {creditEvidence?.credit?.uploadProofUrl?.map(
                  (url, proofIndex) => (
                    <View
                      key={proofIndex}
                      style={CreditCheckStyles.centeredImageContainer}
                      wrap={false} // Prevent image splitting between pages
                      break // Page break to place image on a new page
                    >
                      <AsyncImage
                        fileUrl={url}
                        alt={`credit evidence ${proofIndex + 1}`}
                      />
                    </View>
                  )
                )}
                <View break />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };

  return null;
};
