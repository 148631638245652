import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
// import { Calendar } from "primereact/calendar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getBase64 } from "../../Helper/Base64File";
import { FileUpload } from "primereact/fileupload";
import Inefficiency from "../UserProfileView/Checklist/Inefficiency.jsx";
import {
  salaryTypeRemark,
  experienceAnnexure,
  statusRemark,
  selectRemark,
  getSeverity,
  remarkStatus,
  modeOfVerification,
} from "../../utils/constants.js";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { Modal } from "antd";
import { useFormik } from "formik";
import { ValidateInput } from "../../Helper/ValidateInput.jsx";
import { customItemTemplate } from "../../Helper/UploadFileTemplate.jsx";
import { SplitButton } from "primereact/splitbutton";
import { InputTextarea } from "primereact/inputtextarea";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import NoData from "../../Helper/NoDataFound.jsx";

const ExperienceDetail = ({
  userVerificationDetail,
  userVerificationRequestInfo,
  taskDetail,
  experienceCheckInfo,
  getExperienceCheckInfo,
  activityDetails,
  showActivity,
  showReWork,
  getActivityDetails,
  flag,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  // const [editStartDate, setEditStartDate] = useState(false);
  // const [editEndDate, setEditEndDate] = useState(false);
  const [fileUpload, SetFileUplaod] = useState([]);
  const [uploadProof, SetUploadProof] = useState([]);
  const [showAddStaff, setShowaddStaff] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showViewForm, setShowViewForm] = useState(false);
  const [details, setDetails] = useState({});
  const [emailForVerification, setEmailForVerification] = useState("");
  // const [editStartDateRemark, setEditStartDateRemark] = useState(false);
  // const [editEndDateRemark, setEditEndDateRemark] = useState(false);
  const [emailCcForVerification, setEmailCcForVerification] = useState(""); // For 'To'
  const [emailsForCc, setEmailsForCc] = useState([]); // For 'Cc'
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [fileMap, setFileMap] = useState([]);
  const [proofImage, setProofImage] = useState([]);
  const [timer, setTimer] = useState(null);
  const [activity, setActivity] = useState("");
  const [isEdit, setIsEdit] = useState({
    companyName: false,

    employeeCode: false,

    employmentStartDates: false,

    employmentEndDates: false,

    designation: false,

    salary: false,

    supervisorName: false,

    typeOfEmployment: false,

    reasonForLeaving: false,
  });

  // Logic to handle adding emails to the 'Cc' array
  const handleKeyDownForCc = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault(); // Prevent form submission
      addCcEmail();
    }
  };

  const addCcEmail = () => {
    const trimmedEmail = emailCcForVerification.trim();
    if (trimmedEmail) {
      setEmailsForCc((prev) => [...prev, trimmedEmail]);
      setEmailCcForVerification(""); // Clear the input field
    } else {
      alert("Please enter a valid email.");
    }
  };

  useEffect(() => {
    console.log("emailsForCc>>", emailsForCc);
  }, [emailsForCc]);

  // const validateEmail = (email) => {
  //   const emailRegex = /^[a-zA-Z0-9.%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/;
  //   return emailRegex.test(email);
  // };

  const removeCcEmail = (indexToRemove) => {
    setEmailsForCc((prev) =>
      prev.filter((_, index) => index !== indexToRemove)
    );
  };

  const updateCaseIsQCOpen = async (flag) => {
    let body = {
      status: flag,
    };
    ApiService.post(`userVerificationRequest/${userVerificationDetail}`, body)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const sendEmailToHr = (e, email, info) => {
    e.preventDefault();
    console.log("INFO>>", info);
    console.log("EMAIL>>", email);
    let linkUrl = ` ${process.env.REACT_APP_FRONTEND_BASE_URL}`;
    let candidate = `${taskDetail?.userVerificationRequest?.userInfo?.firstName} ${taskDetail?.userVerificationRequest?.userInfo?.lastName}`;
    let URL;
    if (process.env.REACT_APP_IS_PAMAC_REPORT_ENABLED === "true") {
      URL = "sendEmailToHrForPamac";
    } else if (process.env.REACT_APP_IS_VLEADER_REPORT_ENABLED === "true") {
      URL = "sendEmailToHr";
    }

    ApiService.post(URL, {
      email: email,
      ccEmails: emailsForCc,
      info: info,
      url: linkUrl,
      candidateName: candidate,
    })
      .then(() => {
        toast.success("Email Sent Successfully");
        setShowModal(false);
        setEmailsForCc([]);
        setEmailCcForVerification("");
        setEmailForVerification("");
      })
      .catch((error) => {
        console.error("Unable to send:", error);
        toast.error("Unable to send email!:");
        // setShowModal(false);
      });
  };

  // const formatDateToMMYY = (isoDate) => {
  //   if (!isoDate) return "";
  //   const date = new Date(isoDate);
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
  //   const year = date.getFullYear();
  //   console.log("FORMATEDDATE>>", month, year);

  //   return `${month}/${year}`;
  // };

  // const formatDateToDDMMYY = (isoDate) => {
  //   if (!isoDate) return "";
  //   const date = new Date(isoDate);
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
  //   const year = date.getFullYear();
  //   console.log("FORMATEDDATE>>", month, year);

  //   return `${day}/${month}/${year}`;
  // };

  // const [remarkForActivity, setRemarkForActivity] = useState("");
  const SIGNEDURL_EXPIRATION_TIME =
    process.env.REACT_APP_SIGNEDURL_EXPIRATION_TIME;

  let localStorageData = JSON.parse(localStorage.getItem("user"));
  const confirm1 = (id) => {
    if (localStorageData?.role === "user") {
      setIsModalOpen(true);
    }
    confirmDialog({
      message: (
        <div className="flex items-center pt-[17px]">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          <span className="pt-[2px]">Are you sure you want to DELETE?</span>
        </div>
      ),
      header: "Confirmation",
      accept: () => accept(id),
      // reject: () => setDialogVisible(false),
      // onHide: () => setDialogVisible(false),
    });
  };

  const toggleEdit = (field) => {
    setIsEdit((prevState) => ({
      ...prevState,

      [field]: !prevState[field], // Toggles the boolean value for the specific field
    }));
  };

  const accept = (id) => {
    deleteItem(id);
  };

  const deleteItem = (id) => {
    const url = `experienceCheck/remove/${id}`;

    ApiService.post(url)
      .then(() => {
        // setDialogVisible(false);
        toast.success("Item Deleted Successfully");
        getExperienceCheckInfo(userVerificationDetail);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Delete Item");
      });
  };

  const createActivity = async (action, caseId) => {
    let body = {
      userVerificationRequestId: caseId,
      // checkId: checkId,
      FEName:
        localStorageData?.role === "BCA"
          ? localStorageData?.name
          : localStorageData?.role === "BCAStaff"
            ? `${localStorageData?.firstName} ${localStorageData?.lastName}`
            : localStorageData?.role === "Coordinator"
              ? localStorageData?.vendorName
              : localStorageData?.role === "DataEntry"
                ? localStorageData?.vendorName
                : "",
      checkName: "Experience",
      description: action,
      activityDetails: {
        action: action,
      },
    };
    await ApiService.post("caseActivity", body)
      .then(() => {
        getActivityDetails(userVerificationDetail);
        setActivity("");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Create Activity");
      });
  };

  const experienceType = [
    { name: "Fresher" },
    { name: "Experience-1" },
    { name: "Experience-2" },
    { name: "Experience-3" },
  ];

  const getDiscreteCallInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "experienceCheck/link";
    } else {
      url = "experienceCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  // const updateTaskStatus = async () => {
  //   let body = {
  //     status: "Completed",
  //   };
  //   await ApiService.post(`task/status/${taskDetail?._id}`, body)
  //     .then(() => {
  //       toast.success("Task Submitted Successfully");
  //       navigate("/staff-member-dashboard");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  const handleSaveClick = (e, id, index) => {
    e.preventDefault();
    updateExperienceDetails(e, id, index);
    handleTaskForReWork();
  };

  const updateTaskStatus = async (taskId, status, flag) => {
    let body = {
      status: status,
    };
    await ApiService.post(`task/status/${taskId}`, body)
      .then(() => {
        if (status === "Completed") {
          toast.success("Task Submitted Successfully");
          updateCaseIsQCOpen(flag);
          navigate("/staff-member-dashboard");
        } else if (status === "Rework") {
          updateCaseIsQCOpen(flag);
          // navigate("/cases");
          toast.success("Task Updated Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  const handleSubmitTask = () => {
    updateTaskStatus(taskDetail?._id, "Completed", "QC-Open");
  };

  const handleTaskForReWork = () => {
    updateTaskStatus(userVerificationRequestInfo, "Rework", "Rework-WIP");
  };

  const removeImg = (e, id, item, reportIndex, type) => {
    e.preventDefault();

    // Filter the array and exclude the selected URL by index

    if (type === "file") {
      const updatedUrls = item?.experience?.reportUrl.filter(
        (_, index) => index !== reportIndex
      );

      updateExperienceFileArray(e, id, item, updatedUrls);
    } else {
      const updatedUrls = item?.experience?.uploadProofUrl.filter(
        (_, index) => index !== reportIndex
      );

      updateExperienceProofArray(e, id, item, updatedUrls);
    }
  };

  const updateExperienceFileArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "experienceCheck/link";
    } else {
      url = "experienceCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      experience: {
        ...item.experience,
        reportUrl: updatedReportUrls, // Set the updated reportUrl array here
        remark: item.experience.remark,
        uploadProofUrl: [
          ...new Set([...item.experience.uploadProofUrl, ...uploadProof]),
        ],
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      reason: {
        ...item.reason,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Experience details updated successfully");
      SetFileUplaod([]); // Reset file upload state
      SetUploadProof([]); // Reset proof upload state
      if (flag === "false") {
        getExperienceCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateExperienceProofArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "experienceCheck/link";
    } else {
      url = "experienceCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      experience: {
        ...item.experience,
        reportUrl: [...new Set([...item.experience.reportUrl, ...fileUpload])], // Set the updated reportUrl array here
        remark: item.experience.remark,
        uploadProofUrl: updatedReportUrls,
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      reason: {
        ...item.reason,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Experience details updated successfully");
      SetFileUplaod([]); // Reset file upload state
      SetUploadProof([]); // Reset proof upload state
      if (flag === "false") {
        getExperienceCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Month is zero-based
    const day = ("0" + date.getDate()).slice(-2);

    return `${month}/${day}/${year}`;
  };

  // const createWebForm = async () => {
  //   let body = {
  //     webFormFields: {
  //       candidateName: `${taskDetail?.userVerificationRequest?.userInfo?.firstName || ""
  //         } ${taskDetail?.userVerificationRequest?.userInfo?.lastName || ""}`,
  //       email: values.email,
  //       endDate: formatDate(new Date(values.endDate)),
  //       startDate: formatDate(new Date(values.startDate)),
  //       designation: checked3,
  //       reasonToLeave: checked4,
  //       feedback: checked5,
  //       annualCCT: checked6,
  //     },
  //     webFormDetails: {
  //       candidateName: `${taskDetail?.userVerificationRequest?.userInfo?.firstName || ""
  //         } ${taskDetail?.userVerificationRequest?.userInfo?.lastName || ""}`,
  //       email: values.email,
  //       startDate: null,
  //       endDate: null,
  //       designation: null,
  //       reasonToLeave: null,
  //       feedback: null,
  //       annualCCT: null,
  //     },
  //     webFormURL: process.env.REACT_APP_FRONTEND_URL,
  //     documentId: WebFormReferenceId,
  //     userVerificationRequestId:
  //       location?.state?.taskList?.userVerificationRequestId,
  //   };
  //   await ApiService.post(`task/status/${taskDetail?._id}`, body)
  //     .then(() => {
  //       toast.success("Task Submitted Successfully");
  //       navigate("/staff-member-dashboard");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  const handleGetWebForm = (id) => {
    setShowViewForm(true);
    ApiService.get(`webForm/${id}`)
      .then((res) => {
        formik.setFieldValue("name", res?.data?.webFormDetails?.name);
        formik.setFieldValue("email", res?.data?.webFormDetails?.email);
        formik.setFieldValue("startDate", res?.data?.webFormDetails?.startDate);
        formik.setFieldValue("endDate", res?.data?.webFormDetails?.endDate);
        formik.setFieldValue("feedback", res?.data?.webFormDetails?.feedBack);
        formik.setFieldValue(
          "designation",
          res?.data?.webFormDetails?.designation
        );
        formik.setFieldValue(
          "reasonToLeave",
          res?.data?.webFormDetails?.leaveReason
        );
        formik.setFieldValue("annualCCT", res?.data?.webFormDetails?.annualCTC);
      })
      .catch((err) => console.log(err));
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },

    onSubmit: async (values) => {
      setShowaddStaff(true);
      let body = {
        webFormFields: {
          candidateName: `${
            taskDetail?.userVerificationRequest?.userInfo?.firstName || ""
          } ${taskDetail?.userVerificationRequest?.userInfo?.lastName || ""}`,
          email: details.hrEmail,
          endDate: formatDate(new Date(values.endDate)),
          startDate: formatDate(new Date(values.startDate)),
          designation: checked3,
          reasonToLeave: checked4,
          feedback: checked5,
          annualCCT: checked6,
        },
        webFormDetails: {
          candidateName: `${
            taskDetail?.userVerificationRequest?.userInfo?.firstName || ""
          } ${taskDetail?.userVerificationRequest?.userInfo?.lastName || ""}`,
          email: details.hrEmail,
          startDate: null,
          endDate: null,
          designation: null,
          reasonToLeave: null,
          feedback: null,
          annualCCT: null,
        },
        webFormURL: process.env.REACT_APP_FRONTEND_BASE_URL,
        documentId: details?.documentId,
        userVerificationRequestId: details?.userVerificationRequestId,
      };
      ApiService.post("webForm", body)
        .then(async (res) => {
          setShowaddStaff(false);
          toast.success(res.data.message);
          formik.resetForm();
          getExperienceCheckInfo(userVerificationDetail);
          setDetails({});
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  const onInputChange = (e, index, type, field) => {
    const { value } = e.target;
    if (ValidateInput(value, type)) {
      setData((prevData) => {
        // Create a copy of the original data array
        const newData = [...prevData];
        // Update the specific object at the given index
        if (field === "experience") {
          newData[index] = {
            ...newData[index],
            experience: {
              ...newData[index].experience,
              [e.target.name]: e.target.value,
            },
          };
        } else if (field === "verificationDetails") {
          newData[index] = {
            ...newData[index],
            verificationDetails: {
              ...newData[index].verificationDetails,
              [e.target.name]: e.target.value,
            },
          };
        } else if (field === "reason") {
          newData[index] = {
            ...newData[index],
            reason: {
              ...newData[index].reason,
              [e.target.name]: e.target.value,
            },
          };
        }

        return newData;
      });
    }
  };

  useEffect(() => {
    setData(experienceCheckInfo);
    SetFileUplaod(data?.experience?.reportUrl || []);
  }, [experienceCheckInfo]);

  const updateExperienceDetails = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "experienceCheck/link";
    } else {
      url = "experienceCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      experience: {
        ...data[index]?.experience,
        reportUrl: [
          ...new Set([...data[index]?.experience?.reportUrl, ...fileUpload]),
        ],
        remark: data[index]?.experience?.remark,
        uploadProofUrl: [
          ...new Set([
            ...data[index]?.experience?.uploadProofUrl,
            ...uploadProof,
          ]),
        ],
      },
      verificationDetails: {
        ...data[index]?.verificationDetails,
      },
      reason: {
        ...data[index]?.reason,
      },
    };
    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      // createActivity(
      //   remarkForActivity,
      //   userVerificationDetail,
      //   experienceCheckInfo[0]._id
      // );
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Experience details updated successfully");
      if (flag === "false") {
        getExperienceCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleEdit = () => {
    setEditable(!editable);
  };
  const handleFileUpload = async (event, category) => {
    const allowedExtensions = /.(png|jpeg|pdf)$/i;
    const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
    const files = event.files;
    const fileData = [];

    for (let file of files) {
      // Split the filename by dots to check for multiple extensions
      const fileNameParts = file.name.split(".");

      // Validate file extension and check for multiple extensions
      if (!allowedExtensions.exec(file.name) || fileNameParts.length > 10) {
        toast.error(
          "Invalid file type. Only files with '.png','.jpeg', and '.pdf' extensions are allowed."
        );
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("Please choose a valid file size.");
        return;
      }

      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });

      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
      case "file":
        SetFileUplaod(uploadedFileUrls);
        toast.success("File successfully uploaded");
        break;
      case "uploadProof":
        SetUploadProof(uploadedFileUrls);
        toast.success("Upload proof successfully uploaded");
        break;
      default:
        break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      toast.error("Error uploading file. Please try again.");
      // Handle error uploading files
    }
  };

  const clearImages = () => {
    setFileMap([]);
    setProofImage([]);
    toast.warning("Link expired, please download again.");
  };

  const getImg = (e, fileName, dataIndex, reportIndex, imgFlag) => {
    e.preventDefault();
    // Clear the existing timer if any
    if (timer) {
      clearTimeout(timer);
    }

    ApiService.get(`download/${fileName}`)
      .then((res) => {
        if (imgFlag === "img") {
          setFileMap((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
          // const newDownloadState = downloadState.map((state, i) =>
          //   i === index ? !state : state
          // );
          // setDownloadState(newDownloadState);
        } else {
          setProofImage((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
        }
        const newTimer = setTimeout(clearImages, SIGNEDURL_EXPIRATION_TIME); // 2 minutes
        setTimer(newTimer);
        toast.success("Document download Sucessfully ");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Image Download");
      });
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const updateCheckStatus = async (status, id) => {
    // e.preventDefault();
    const body = { status: status };
    try {
      // Create the request body with only the status field

      // Make the API call to update only the status field
      await ApiService.post(`experienceCheck/experience/status/${id}`, body);

      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      // setCheckStatus(status);
      getExperienceCheckInfo(userVerificationDetail);
      // navigate("/staff-member-dashboard");
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };
  // const updateTaskItem = async (id, status) => {
  //   let body = {
  //     status: status,
  //     _id: id,
  //     objectName: "experience",
  //   };
  //   await ApiService.post(`task/item/${taskDetail?._id}`, body)
  //     .then(() => {
  //       // toast.success("Task Item Updated Successfully");
  //       // navigate("/staff-member-dashboard");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              createActivity(
                "Experience Task Submitted!",
                userVerificationDetail,
                experienceCheckInfo[0]._id
              );
              handleSubmitTask();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}

      {showReWork &&
        data?.map((item, dataIndex) =>
          dataIndex === 0 ? (
            <div
              key={dataIndex}
              className="border rounded shadow-md my-4 bg-[#fafafa]"
            >
              <div className="grid items-center sm:grid-cols-3 gap-x-4 mt-2 p-3">
                <div className="col-span-2">
                  <label
                    className="small mb-1 font-bold"
                    htmlFor="reWorkRemark"
                  >
                    Remark For Re-Work
                  </label>
                  <InputTextarea
                    className="form-control"
                    type="text"
                    name="reWorkRemark"
                    onChange={(e) =>
                      onInputChange(
                        e,
                        dataIndex,
                        "alphanumericWithAllowedSymbols",
                        "experience"
                      )
                    }
                    value={item?.experience?.reWorkRemark || ""}
                    // disabled={
                    //   flag === "true" || localStorageData?.role === "BCAStaff"
                    // }
                    rows={5}
                    cols={30}
                  />
                </div>
                {taskDetail?.status !== "InProgress" ? (
                  <div className="mt-[26px]">
                    <Button
                      className="w-[100px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      label="Submit"
                      type="submit"
                      loading={loading}
                      onClick={(e) => handleSaveClick(e, item._id, dataIndex)}
                      disabled={
                        flag === "true" || localStorageData?.role === "BCAStaff"
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
                {/* {taskDetail?.status !== "InProgress" ? (
                  <div className="mt-[26px]">
                    <Button
                      label="Submit"
                      type="button"
                      loading={loading}
                      onClick={() => handleTaskForReWork()}
                      className="w-[100px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      disabled={
                        flag === "true" || localStorageData?.role === "BCAStaff"
                      }
                    />
                  </div>
                ) : (
                  <></>
                )} */}
              </div>
              <div className="px-3 py-4 mt-4 bg-white shadow-md rounded">
                <label
                  className="small mb-1 font-bold"
                  htmlFor="submittedRemark"
                >
                  Submitted Remark:
                </label>
                <p className="text-gray-800 mt-2">
                  {item.experience?.reWorkRemark || "No remark submitted"}
                </p>
              </div>
            </div>
          ) : null
        )}

      {showActivity && (
        <div className="border  rounded shadow-md my-4 bg-[#fafafa]">
          <div className="grid items-center sm:grid-cols-3 gap-x-4 mt-2 p-3 ">
            <div className="col-span-2">
              <label className="small mb-1 font-bold" htmlFor="activity">
                Activity
              </label>
              <textarea
                className="form-control h-[60px] "
                name="activity"
                onChange={(e) => {
                  setActivity(e.target.value);
                  // onInputChange(e, dataIndex, "alphanumeric");
                }}
                value={activity || ""}
              />
            </div>
            <div className="mt-[26px]">
              <Button
                label="Save"
                type="submit"
                loading={loading}
                onClick={(e) => {
                  e.preventDefault();
                  createActivity(
                    activity,
                    userVerificationDetail
                    // item?._id
                  );
                }}
                className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              />
            </div>
          </div>
          <div className="">
            <label className="small mb-1 px-3   font-bold" htmlFor="activity">
              All Activities List
            </label>
            <div className="h-[310px] p-3 overflow-y-scroll">
              <List
                sx={{
                  width: "100%",
                  // maxWidth: 450,
                  bgcolor: "#fafafa",
                }}
              >
                {activityDetails.length === 0 ? (
                  <>
                    <div className="flex items-center lg:ml-[50px] mt-[30px]">
                      <NoData info="Ooops, we could not find any activity" />
                    </div>
                  </>
                ) : (
                  <>
                    {activityDetails.map((item) => {
                      const createdAt = new Date(item?.createdAt);
                      const formattedDate =
                        createdAt.toLocaleDateString("en-GB");
                      const formattedTime =
                        createdAt.toLocaleTimeString("en-GB");

                      return (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            className="border rounded !mb-3 shadow-md bg-[#ffffff]"
                          >
                            <ListItemAvatar>
                              <Avatar
                                alt="Remy Sharp"
                                src="http://bootdey.com/img/Content/avatar/avatar1.png"
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Box className="flex flex-wrap item-center justify-between wrap">
                                  <Typography
                                    component="span"
                                    sx={{ fontWeight: "600" }} // Apply bold style here
                                    className="text-black text-wrap"
                                  >
                                    {item.FEName}
                                  </Typography>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    className="!font-bold text-black mt-1"
                                  >
                                    {formattedDate} {formattedTime}
                                  </Typography>
                                </Box>
                              }
                              className="!font-bold text-black"
                              secondary={
                                <React.Fragment>
                                  <Box
                                    component="span"
                                    sx={{
                                      display: "inline",
                                      color: "blue-300",
                                      marginRight: 1,
                                    }}
                                  >
                                    <Typography
                                      component="span"
                                      sx={{ color: "green" }}
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      Activity -
                                    </Typography>
                                  </Box>
                                  {item?.description}
                                  <br />
                                  {/* <Typography
                                sx={{ display: "inline", color: "green" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {item?.activityDetails?.action}
                              </Typography> */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          {/* <Divider variant="inset" component="li" /> */}
                        </>
                      );
                    })}
                  </>
                )}
              </List>
            </div>
          </div>{" "}
        </div>
      )}

      {data?.map((item, dataIndex) => (
        <>
          <Card
            title={item?.qualification}
            className={`sm:border-2 ${dataIndex === 0 ? "mt-0" : "mt-4"} relative`}
          >
            {localStorageData?.role !== "BCAStaff" && (
              <div
                className=" text-end cursor-pointer absolute right-[-11px] top-[-13px] "
                role="presentation"
                onClick={() => confirm1(item._id)}
              >
                <DeleteIcon
                  style={{ color: "red", height: "35px", width: "35px" }}
                />
                {/* <ConfirmDialog /> */}
              </div>
            )}
            <div className="flex flex-col justify-end">
              {(() => {
                if (process.env.REACT_APP_IS_PAMAC_REPORT_ENABLED === "true") {
                  let user = JSON.parse(localStorage.getItem("user"));
                  const allowedRoles = [
                    "BCAStaff",
                    "BCA",
                    "Coordinator",
                    "Auditor",
                  ];

                  return (
                    <>
                      <div className="flex flex-row justify-between mb-[10px]">
                        <div className="flex gap-2">
                          {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                            "true" && (
                            <div className="flex flex-row gap-1">
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  e.preventDefault();
                                  createActivity(
                                    "Pending -> Approved",
                                    item?.userVerificationRequestId,
                                    item?._id
                                  );
                                  updateCheckStatus(
                                    "Approved",

                                    item?._id,
                                    dataIndex
                                  );
                                }}
                              >
                                <Button
                                  label="Approve"
                                  severity="success"
                                  icon="pi text-white pi-check-circle"
                                  size="small"
                                />
                              </div>
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  e.preventDefault();
                                  createActivity(
                                    "Pending -> Rejected",
                                    item?.userVerificationRequestId,
                                    item?._id
                                  );
                                  updateCheckStatus(
                                    "Rejected",

                                    item?._id,
                                    dataIndex
                                  );
                                }}
                              >
                                <Button
                                  label="Reject"
                                  icon="pi text-white pi-times-circle"
                                  severity="danger"
                                  size="small"
                                />
                              </div>
                            </div>
                          )}
                          {process.env.REACT_APP_SHOW_PAMAC_COLUMNS ===
                            "true" &&
                            allowedRoles.includes(user?.role) && (
                            <>
                              <SplitButton
                                label="Update Status"
                                icon="pi pi-check"
                                model={[
                                  {
                                    label: "Major Discrepancy",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Major Discrepancy",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Major Discrepancy",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Minor Discrepancy",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Minor Discrepancy",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Minor Discrepancy",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Verified Clear",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Verified Clear",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Verified Clear",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Insufficiency",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Insufficiency",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Insufficiency",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Unable to Verify",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Unable to Verify",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Unable to Verify",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Stop Check",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Stop Check",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Stop Check",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Positive",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Positive",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Positive",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Negative",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Negative",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Negative",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Recommended",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Recommended",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Recommended",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Not Recommended",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Not Recommended",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Not Recommended",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Failed",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Failed",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Failed",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                  {
                                    label: "Could Not Verified",
                                    command: () => {
                                      createActivity(
                                        "Pending -> Could Not Verified",
                                        item?.userVerificationRequestId,
                                        item?._id
                                      );
                                      updateCheckStatus(
                                        "Could Not Verified",

                                        item?._id,
                                        dataIndex
                                      );
                                    },
                                  },
                                ]}
                              />
                              <div className="ml-[20px]">
                                {getSeverity(item?.experience?.status)}
                              </div>
                            </>
                          )}
                        </div>

                        {process.env.REACT_APP_ENABLE_WEBFORM_FEATURE ===
                          "true" && (
                          <>
                            {item?.inefficiencyId !== null ? (
                              <>
                                <div>
                                  <button
                                    className="w-[100px]  text-white text-[10px] font-medium h-[40px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
                                    onClick={() => {
                                      handleGetWebForm(item?.inefficiencyId);
                                    }}
                                  >
                                    View Feedback
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                {localStorageData.role !== "user" && (
                                  <div>
                                    <button
                                      className="w-[100px]  text-white text-[10px] font-medium h-[40px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
                                      onClick={() => {
                                        setShowaddStaff(true);
                                        setDetails({
                                          documentId: item?._id,
                                          info: item?.experience,
                                          hrEmail: item?.experience?.hrEmail,
                                          userVerificationRequestId:
                                            item?.userVerificationRequestId,
                                        });
                                      }}
                                    >
                                      Ask Feedback
                                    </button>
                                  </div>
                                )}

                                {localStorageData.role !== "user" &&
                                  (process.env
                                    .REACT_APP_IS_VLEADER_REPORT_ENABLED ===
                                    "true" ||
                                    process.env
                                      .REACT_APP_IS_PAMAC_REPORT_ENABLED ===
                                      "true") && (
                                  <div>
                                    <button
                                      className="w-[100px]  text-white text-[10px] font-medium h-[40px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
                                      onClick={() => {
                                        setShowModal(true);
                                        setDetails({
                                          info: item?.experience,
                                        });
                                      }}
                                    >
                                        send mail
                                    </button>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  );
                } 
                return (
                  flag === "true" && (
                    <>
                      <div className="flex flex-row justify-between mb-[10px]">
                        <div className="flex gap-2">
                          {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                              "true" && (
                            <div className="flex flex-row gap-1">
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  e.preventDefault();
                                  createActivity(
                                    "Pending -> Approved",
                                    item?.userVerificationRequestId,
                                    item?._id
                                  );
                                  updateCheckStatus(
                                    "Approved",

                                    item?._id,
                                    dataIndex
                                  );
                                }}
                              >
                                <Button
                                  label="Approve"
                                  severity="success"
                                  icon="pi text-white pi-check-circle"
                                  size="small"
                                />
                              </div>
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  e.preventDefault();
                                  createActivity(
                                    "Pending -> Rejected",
                                    item?.userVerificationRequestId,
                                    item?._id
                                  );
                                  updateCheckStatus(
                                    "Rejected",

                                    item?._id,
                                    dataIndex
                                  );
                                }}
                              >
                                <Button
                                  label="Reject"
                                  icon="pi text-white pi-times-circle"
                                  severity="danger"
                                  size="small"
                                />
                              </div>
                            </div>
                          )}
                          {process.env.REACT_APP_SHOW_PAMAC_COLUMNS ===
                              "true" && (
                            <SplitButton
                              label="Update Status"
                              icon="pi pi-check"
                              // onClick={(e) => {
                              //   // Do nothing, the menu items will handle the status update
                              // }}
                              model={[
                                {
                                  label: "Major Discrepancy",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Major Discrepancy",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Major Discrepancy",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Minor Discrepancy",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Minor Discrepancy",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Minor Discrepancy",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Verified Clear",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Verified Clear",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Verified Clear",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Insufficiency",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Insufficiency",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Insufficiency",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Unable to Verify",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Unable to Verify",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Unable to Verify",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Stop Check",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Stop Check",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Stop Check",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Positive",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Positive",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Positive",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Negative",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Negative",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Negative",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Recommended",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Recommended",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Recommended",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Not Recommended",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Not Recommended",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Not Recommended",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Failed",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Failed",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Failed",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                                {
                                  label: "Could Not Verified",
                                  command: () => {
                                    createActivity(
                                      "Pending -> Could Not Verified",
                                      item?.userVerificationRequestId,
                                      item?._id
                                    );
                                    updateCheckStatus(
                                      "Could Not Verified",

                                      item?._id,
                                      dataIndex
                                    );
                                  },
                                },
                              ]}
                            />
                          )}
                          <div className="ml-[20px]">
                            {getSeverity(item?.experience?.status)}
                          </div>
                        </div>

                        {process.env.REACT_APP_ENABLE_WEBFORM_FEATURE ===
                            "true" && (
                          <>
                            {item?.inefficiencyId !== null ? (
                              <>
                                <div>
                                  <button
                                    className="w-[100px]  text-white text-[10px] font-medium h-[40px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
                                    onClick={() => {
                                      handleGetWebForm(item?.inefficiencyId);
                                    }}
                                  >
                                      View Feedback
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <button
                                    className="w-[100px]  text-white text-[10px] font-medium h-[40px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
                                    onClick={() => {
                                      setShowaddStaff(true);
                                      setDetails({
                                        documentId: item?._id,
                                        info: item?.experience,
                                        hrEmail: item?.experience?.hrEmail,
                                        userVerificationRequestId:
                                            item?.userVerificationRequestId,
                                      });
                                    }}
                                  >
                                      Ask Feedback
                                  </button>
                                </div>

                                {(process.env
                                  .REACT_APP_IS_VLEADER_REPORT_ENABLED ===
                                    "true" ||
                                    process.env
                                      .REACT_APP_IS_PAMAC_REPORT_ENABLED ===
                                      "true") && (
                                  <div>
                                    <button
                                      className="w-[100px]  text-white text-[10px] font-medium h-[40px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
                                      onClick={() => {
                                        setShowModal(true);
                                        setDetails({
                                          info: item?.experience,
                                        });
                                      }}
                                    >
                                        send mail
                                    </button>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )
                );
                
              })()}

              {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS === "true" && (
                <form>
                  <div className="col-md-4">
                    <Dropdown
                      value={item?.experience?.type || ""}
                      onChange={(e) => onInputChange(e, dataIndex)}
                      options={experienceType}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select Type"
                      filter
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                      className="w-full md:w-14rem"
                      inputId="type"
                      name="type"
                      disabled={flag === "true"}
                    />
                  </div>
                  {(item?.experience?.type === "Experience-1" ||
                    item?.experience?.type === "Experience-2" ||
                    item?.experience?.type === "Experience-3") && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Company Name
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="companyName"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.experience?.companyName || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Designation
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="designation"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.experience?.designation || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        {process.env.REACT_APP_SHOW_PAMAC_COLUMNS && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Reporting Manager’s Name
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="reportingManagerName"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "alphanumeric")
                              }
                              value={
                                item?.experience?.reportingManagerName || ""
                              }
                              disabled={flag === "true"}
                            />
                          </div>
                        )}
                        {process.env.REACT_APP_SHOW_PAMAC_COLUMNS && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Reporting Manager’s designation
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="reportingManagerDesignation"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "alphanumeric")
                              }
                              value={
                                item?.experience?.reportingManagerDesignation ||
                                ""
                              }
                              disabled={flag === "true"}
                            />
                          </div>
                        )}

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Period Of Employment(In Months)
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="periodOfEmployement"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "numeric")
                            }
                            value={item?.experience?.periodOfEmployement || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                          "true" && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Location
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="location"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "text")
                              }
                              value={item?.experience?.location || ""}
                              disabled={flag === "true"}
                            />
                          </div>
                        )}
                        {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                          "true" && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Company Address
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="companyAddress"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "alphanumeric")
                              }
                              value={item?.experience?.companyAddress || ""}
                              disabled={flag === "true"}
                            />
                          </div>
                        )}
                        {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                          "true" && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="clientType">
                              Contact Detail
                            </label>
                            <InputText
                              className="form-control"
                              keyfilter="int"
                              maxLength={10}
                              name="contactDetail"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "numeric")
                              }
                              value={item?.experience?.contactDetail || ""}
                              disabled={flag === "true"}
                            />
                          </div>
                        )}
                        {process.env.REACT_APP_SHOW_PAMAC_COLUMNS && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Eligibility for rehire
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="rehire"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "alphanumeric")
                              }
                              value={item?.experience?.rehire || ""}
                              disabled={flag === "true"}
                            />
                          </div>
                        )}
                      </div>

                      <div className="row my-2">
                        {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                          "true" && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Employment Code
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="employmentCode"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "alphanumeric")
                              }
                              value={item?.experience?.employmentCode || ""}
                              disabled={flag === "true"}
                            />
                          </div>
                        )}
                        {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                          "true" && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Salary
                            </label>
                            <InputText
                              className="form-control"
                              keyfilter="int"
                              name="salary"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "numeric")
                              }
                              value={item?.experience?.salary || ""}
                              disabled={flag === "true"}
                            />
                          </div>
                        )}

                        {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                          "true" && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Supervisor Name
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="supervisorName"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "text")
                              }
                              value={item?.experience?.supervisorName || ""}
                              disabled={flag === "true"}
                            />
                          </div>
                        )}
                      </div>

                      <div className="row my-2">
                        {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                          "true" && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="clientType">
                              Supervisor Number
                            </label>
                            <InputText
                              className="form-control"
                              keyfilter="int"
                              name="supervisorNumber"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "numeric")
                              }
                              value={item?.experience?.supervisorNumber || ""}
                              disabled={flag === "true"}
                            />
                          </div>
                        )}
                        {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                          "true" && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Supervisor Email
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="supervisorEmail"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "alphanumeric")
                              }
                              value={item?.experience?.supervisorEmail || ""}
                              disabled={flag === "true"}
                            />
                          </div>
                        )}
                        {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                          "true" && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              HR Name
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="hrName"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "text")
                              }
                              value={item?.experience?.hrName || ""}
                              disabled={flag === "true"}
                            />
                          </div>
                        )}
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            HR Number
                          </label>
                          <InputText
                            className="form-control"
                            keyfilter="int"
                            name="hrNumber"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "numeric")
                            }
                            maxLength={10}
                            value={item?.experience?.hrNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        {process.env.REACT_APP_SHOW_PAMAC_COLUMNS !==
                          "true" && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              HR Email
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="hrEmail"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithSymbols"
                                )
                              }
                              value={item?.experience?.hrEmail || ""}
                              disabled={flag === "true"}
                            />
                          </div>
                        )}

                        {process.env.REACT_APP_SHOW_PAMAC_COLUMNS && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Any Area of Concern
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="areaOfConcern"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "alphanumeric")
                              }
                              value={item?.experience?.areaOfConcern || ""}
                              disabled={flag === "true"}
                            />
                          </div>
                        )}
                        {process.env.REACT_APP_SHOW_PAMAC_COLUMNS && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Mode of verification
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="modeOfVerification"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "alphanumeric")
                              }
                              value={item?.experience?.modeOfVerification || ""}
                              disabled={flag === "true"}
                            />
                          </div>
                        )}
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            UAN Number
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="uanNumber"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.experience?.uanNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                          "true" && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Reason For Leaving
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="reasonForLeaving"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "text")
                              }
                              value={item?.experience?.reasonForLeaving || ""}
                              disabled={flag === "true"}
                            />
                          </div>
                        )}

                        {process.env.REACT_APP_SHOW_PAMAC_COLUMNS && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Verifier Name
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="verifierName"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "alphanumeric")
                              }
                              value={item?.experience?.verifierName || ""}
                              disabled={flag === "true"}
                            />
                          </div>
                        )}
                        {process.env.REACT_APP_SHOW_PAMAC_COLUMNS && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Verifier Designation
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="verifierDesignation"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "alphanumeric")
                              }
                              value={
                                item?.experience?.verifierDesignation || ""
                              }
                              disabled={flag === "true"}
                            />
                          </div>
                        )}
                        {process.env.REACT_APP_SHOW_PAMAC_COLUMNS && (
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Verifier Contact No.
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="verifierContact"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "alphanumeric")
                              }
                              value={item?.experience?.verifierContact || ""}
                              disabled={flag === "true"}
                            />
                          </div>
                        )}

                        <div className="col-md-12 border-bottom">
                          <label className="small mb-1" htmlFor="clientType">
                            File Upload{" "}
                            <span className="opacity-70">
                              (Please Choose JPEG,PNG,PDF less than 5MB)
                            </span>
                          </label>
                          {item?.experience?.reportUrl?.length === 0 ||
                          editable === true ? (
                              <FileUpload
                                name="fileUpload[]"
                                multiple
                                auto
                                accept="image/*,application/pdf"
                                customUpload={true}
                                // maxFileSize={50000000}
                                uploadHandler={(e) => handleFileUpload(e, "file")}
                                itemTemplate={customItemTemplate}
                                disabled={flag === "true"}
                              />
                            ) : (
                              ""
                            )}

                          <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                            {(item?.experience?.reportUrl || []).map(
                              (fileUrl, reportIndex) => {
                                // Extract file name from the URL
                                const fileName = fileUrl.split("/").pop();
                                const fileExtension = fileName
                                  .split(".")
                                  .pop()
                                  .toLowerCase();

                                const isImage = ["jpeg", "png"].includes(
                                  fileExtension
                                );

                                const isPdf = fileExtension === "pdf";
                                return (
                                  <li
                                    key={reportIndex}
                                    className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                  >
                                    <div
                                      role="presentation"
                                      onClick={(e) =>
                                        removeImg(
                                          e,
                                          item._id,
                                          item,
                                          reportIndex,
                                          "file"
                                        )
                                      } // Pass the `item` to removeImg
                                      className="cursor-pointer"
                                    >
                                      <i
                                        className="pi pi-times"
                                        style={{ color: "red" }}
                                      ></i>
                                    </div>
                                    <Button
                                      label="Download"
                                      onClick={(e) =>
                                        getImg(
                                          e,
                                          fileName,
                                          dataIndex,
                                          reportIndex,
                                          "img"
                                        )
                                      }
                                      className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                    />

                                    {fileMap[dataIndex]?.[reportIndex] && (
                                      <div className="text-center mt-2">
                                        {isImage && (
                                          <a
                                            href={
                                              fileMap[dataIndex][reportIndex]
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            download={`file_${dataIndex}_${reportIndex}`}
                                          >
                                            View Image
                                          </a>
                                        )}
                                        {isPdf && (
                                          <a
                                            href={
                                              fileMap[dataIndex][reportIndex]
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-center"
                                          >
                                            View PDF
                                          </a>
                                        )}
                                      </div>
                                    )}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-md-8 mt-2">
                    <label className="small mb-1" htmlFor="remark">
                      Remark
                    </label>
                    <textarea
                      className="form-control h-[110px]"
                      name="remark"
                      onChange={(e) => {
                        // setRemarkForActivity(e.target.value);
                        onInputChange(e, dataIndex, "alphanumeric");
                      }}
                      value={item?.experience?.remark || ""}
                      disabled={flag === "false"}
                    />
                  </div>

                  <div className="col-md-12 my-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Upload Proof{" "}
                      <span className="opacity-70">
                        (Please Choose PDF less than 10MB)
                      </span>
                    </label>
                    {item?.experience?.uploadProofUrl?.length === 0 && (
                      <FileUpload
                        name="fileUpload[]"
                        auto
                        multiple
                        accept="image/*"
                        customUpload={true}
                        maxFileSize={10000000}
                        uploadHandler={(e) =>
                          handleFileUpload(e, "uploadProof")
                        }
                        itemTemplate={customItemTemplate}
                        disabled={flag === "false"}
                      />
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.experience?.uploadProofUrl || []).map(
                        (fileUrl, proofIndex) => {
                          // Extract file name from the URL
                          const fileName = fileUrl.split("/").pop();
                          const fileExtension = fileName
                            .split(".")
                            .pop()
                            .toLowerCase();

                          const isImage = ["jpeg", "png"].includes(
                            fileExtension
                          );

                          return (
                            <li
                              key={proofIndex}
                              className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                            >
                              <div
                                role="presentation"
                                onClick={(e) =>
                                  removeImg(
                                    e,
                                    item._id,
                                    item,
                                    proofIndex,
                                    "proof"
                                  )
                                } // Pass the `item` to removeImg
                                className="cursor-pointer"
                              >
                                <i
                                  className="pi pi-times"
                                  style={{ color: "red" }}
                                ></i>
                              </div>
                              <Button
                                label="Download"
                                onClick={(e) =>
                                  getImg(
                                    e,
                                    fileName,
                                    dataIndex,
                                    proofIndex,
                                    "proof"
                                  )
                                }
                                className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                              />
                              <div className="text-center mt-2">
                                {isImage &&
                                  proofImage[dataIndex]?.[proofIndex] && (
                                  <a
                                    href={proofImage[dataIndex][proofIndex]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={`file_${dataIndex}_${proofIndex}`}
                                  >
                                      View Image
                                  </a>
                                )}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                  <div></div>

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.experience?.status === "Pending" ? (
                        item?.experience?.reportUrl?.length > 0 ? (
                          <Button
                            label="Edit"
                            type="button"
                            onClick={(e) => {
                              handleEdit(e);
                            }}
                            className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    {item?.experience?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateExperienceDetails(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.experience?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.experience?.status)}</div>
                    )}
                  </div>
                </form>
              )}
              {process.env.REACT_APP_IS_PAMAC_REPORT_ENABLED === "true" && (
                <form>
                  <div className="col-md-4">
                    <Dropdown
                      value={item?.experience?.type || ""}
                      onChange={(e) => {
                        onInputChange(e, dataIndex, " ", "experience");
                      }}
                      options={experienceType}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select Type"
                      filter
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                      className="w-full md:w-14rem"
                      inputId="type"
                      name="type"
                    />
                  </div>

                  <>
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Name & Address of the company
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="companyName"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "experience"
                            )
                          }
                          value={item?.experience?.companyName || ""}
                        />
                      </div>
                      {localStorageData.role !== "user" && (
                        <>
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="clientType">
                              Remark
                            </label>
                            <Dropdown
                              value={
                                item?.verificationDetails?.companyNameRemark ||
                                ""
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "text",
                                  "verificationDetails"
                                )
                              }
                              options={remarkStatus}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select"
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="companyNameRemark"
                              name="companyNameRemark"
                            />
                          </div>
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="entityName"
                            ></label>
                            {item?.verificationDetails?.companyNameRemark ===
                              "Not Verified" && (
                              <InputText
                                className="form-control"
                                type="text"
                                name="reasonOfCompanyName"
                                placeholder="Enter details"
                                onChange={(e) =>
                                  onInputChange(e, dataIndex, "text", "reason")
                                }
                                value={item?.reason?.reasonOfCompanyName || ""}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Title / designation
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="designation"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "experience"
                            )
                          }
                          value={item?.experience?.designation || ""}
                        />
                      </div>
                      {localStorageData.role !== "user" && (
                        <>
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Remark
                            </label>
                            <Dropdown
                              value={
                                item?.verificationDetails?.designationRemark ||
                                ""
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumeric",
                                  "verificationDetails"
                                )
                              }
                              options={remarkStatus}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select"
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="designationRemark"
                              name="designationRemark"
                            />
                          </div>
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="entityName"
                            ></label>
                            {item?.verificationDetails?.designationRemark ===
                              "Not Verified" && (
                              <InputText
                                className="form-control"
                                type="text"
                                name="reasonOfDesignation"
                                placeholder="Enter details"
                                onChange={(e) =>
                                  onInputChange(e, dataIndex, "text", "reason")
                                }
                                value={item?.reason?.reasonOfDesignation || ""}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Reporting Manager’s name & designation
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="reportingManagerDesignation"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "experience"
                            )
                          }
                          value={
                            item?.experience?.reportingManagerDesignation || ""
                          }
                        />
                      </div>
                      {localStorageData.role !== "user" && (
                        <>
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Remark
                            </label>
                            <Dropdown
                              value={
                                item?.verificationDetails
                                  ?.reportingManagerDesignationRemark || ""
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumeric",
                                  "verificationDetails"
                                )
                              }
                              options={remarkStatus}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select"
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="reportingManagerDesignationRemark"
                              name="reportingManagerDesignationRemark"
                            />
                          </div>
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="entityName"
                            ></label>
                            {item?.verificationDetails
                              ?.reportingManagerDesignationRemark ===
                              "Not Verified" && (
                              <InputText
                                className="form-control"
                                type="text"
                                name="reasonOfReportingManagerDesignation"
                                placeholder="Enter details"
                                onChange={(e) =>
                                  onInputChange(e, dataIndex, "text", "reason")
                                }
                                value={
                                  item?.reason
                                    ?.reasonOfReportingManagerDesignation || ""
                                }
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row my-2">
                      <div className="col-md-4 mb-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Employment Start Dates (From)
                        </label>

                        {/* {item?.experience?.employmentStartDates !== "" &&
                          editStartDate === false && (
                            <i
                              className="pi pi-pencil"
                              style={{
                                fontSize: "1rem",
                                color: "#075B86",
                                cursor: "pointer",
                                marginLeft: 5,
                              }}
                              onClick={() => setEditStartDate(!editStartDate)}
                              role="button"
                              tabIndex="0"
                              aria-label="Edit Date"
                            ></i>
                          )}
                        <br />
                        {item?.experience?.employmentStartDates === "" ||
                        editStartDate === true ? (
                          <>
                            <Calendar
                              value={
                                item?.experience?.employmentStartDates || ""
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "experience"
                                )
                              }
                              name="employmentStartDates"
                              placeholder="DD/MM/YYYY"
                              dateFormat="dd/mm/yy"
                              showIcon
                              // readOnlyInput
                              className="!w-[350px]"
                            />
                          </>
                        ) : (
                          <> */}
                        <InputText
                          className="form-control"
                          type="Date"
                          name="employmentStartDates"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "experience"
                            )
                          }
                          value={item?.experience?.employmentStartDates || ""}
                        />
                        {/* </>
                        )} */}
                      </div>
                      {localStorageData.role !== "user" && (
                        <>
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="clientType">
                              Remark
                            </label>
                            <Dropdown
                              value={
                                item?.verificationDetails
                                  ?.employmentStartDatesRemark || ""
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumeric",
                                  "verificationDetails"
                                )
                              }
                              options={remarkStatus}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select"
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="employmentStartDatesRemark"
                              name="employmentStartDatesRemark"
                            />
                          </div>
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="entityName"
                            ></label>
                            {item?.verificationDetails
                              ?.employmentStartDatesRemark ===
                              "Not Verified" && (
                              <InputText
                                className="form-control"
                                type="Date"
                                name="reasonOfEmploymentStartDates"
                                placeholder="Enter details"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols",
                                    "reason"
                                  )
                                }
                                value={
                                  item?.reason?.reasonOfEmploymentStartDates ||
                                  ""
                                }
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="row my-2">
                      <div className="col-md-4 mb-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Employment End Dates (To)
                        </label>

                        {/* {item?.experience?.employmentEndDates !== "" &&
                          editEndDate === false && (
                            <i
                              className="pi pi-pencil"
                              style={{
                                fontSize: "1rem",
                                color: "#075B86",
                                cursor: "pointer",
                                marginLeft: 5,
                              }}
                              onClick={() => setEditEndDate(!editEndDate)}
                              role="button"
                              tabIndex="0"
                              aria-label="Edit Date"
                            ></i>
                          )}
                        <br />
                        {item?.experience?.employmentEndDates === "" ||
                        editEndDate === true ? (
                          <>
                            <Calendar
                              value={item?.experience?.employmentEndDates || ""}
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "experience"
                                )
                              }
                              name="employmentEndDates"
                              placeholder="DD/MM/YYYY"
                              dateFormat="dd/mm/yy"
                              showIcon
                              // readOnlyInput
                              className="!w-[350px]"
                            />
                          </>
                        ) : (
                          <> */}
                        <InputText
                          className="form-control"
                          type="text"
                          name="employmentEndDates"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "experience"
                            )
                          }
                          value={item?.experience?.employmentEndDates || ""}
                        />
                        {/* </>
                        )} */}
                      </div>
                      {localStorageData.role !== "user" && (
                        <>
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="clientType">
                              Remark
                            </label>
                            <Dropdown
                              value={
                                item?.verificationDetails
                                  ?.employmentEndDatesRemark || ""
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumeric",
                                  "verificationDetails"
                                )
                              }
                              options={remarkStatus}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select"
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="employmentEndDatesRemark"
                              name="employmentEndDatesRemark"
                            />
                          </div>
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="entityName"
                            ></label>
                            {item?.verificationDetails
                              ?.employmentEndDatesRemark === "Not Verified" && (
                              <InputText
                                className="form-control"
                                type="text"
                                name="reasonOfEmploymentEndDates"
                                placeholder="Enter details"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols",
                                    "reason"
                                  )
                                }
                                value={
                                  item?.reason?.reasonOfEmploymentEndDates || ""
                                }
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Salary / remuneration
                        </label>
                        <InputText
                          className="form-control"
                          // keyfilter="int"
                          type="text"
                          name="salary"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "experience"
                            )
                          }
                          value={item?.experience?.salary || ""}
                        />
                      </div>
                      {localStorageData.role !== "user" && (
                        <>
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Remark
                            </label>
                            <Dropdown
                              value={
                                item?.verificationDetails?.salaryRemark || ""
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={remarkStatus}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select"
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="salaryRemark"
                              name="salaryRemark"
                            />
                          </div>
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="entityName"
                            ></label>
                            {item?.verificationDetails?.salaryRemark ===
                              "Not Verified" && (
                              <InputText
                                className="form-control"
                                type="text"
                                name="reasonOfSalary"
                                placeholder="Enter details"
                                onChange={(e) =>
                                  onInputChange(e, dataIndex, "text", "reason")
                                }
                                value={item?.reason?.reasonOfSalary || ""}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Reason For Leaving
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="reasonForLeaving"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "text", "experience")
                          }
                          value={item?.experience?.reasonForLeaving || ""}
                        />
                      </div>
                      {localStorageData.role !== "user" && (
                        <>
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Remark
                            </label>
                            <Dropdown
                              value={
                                item?.verificationDetails
                                  ?.reasonForLeavingRemark || ""
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumeric",
                                  "verificationDetails"
                                )
                              }
                              options={remarkStatus}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select"
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="reasonForLeavingRemark"
                              name="reasonForLeavingRemark"
                            />
                          </div>
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="entityName"
                            ></label>
                            {item?.verificationDetails
                              ?.reasonForLeavingRemark === "Not Verified" && (
                              <InputText
                                className="form-control"
                                type="text"
                                name="reasonOfReasonForLeaving"
                                placeholder="Enter details"
                                onChange={(e) =>
                                  onInputChange(e, dataIndex, "text", "reason")
                                }
                                value={
                                  item?.reason?.reasonOfReasonForLeaving || ""
                                }
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {localStorageData.role !== "user" && (
                      <>
                        <div className="row my-2">
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Eligibility for rehire
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="rehire"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumeric",
                                  "experience"
                                )
                              }
                              value={item?.experience?.rehire || ""}
                            />
                          </div>
                        </div>

                        <div className="row my-2">
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Any Area of Concern
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="areaOfConcern"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumeric",
                                  "experience"
                                )
                              }
                              value={item?.experience?.areaOfConcern || ""}
                            />
                          </div>
                        </div>

                        <div className="row my-2">
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Mode of verification
                            </label>
                            <Dropdown
                              value={item?.experience?.modeOfVerification || ""}
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumeric",
                                  "experience"
                                )
                              }
                              options={modeOfVerification}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select"
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="modeOfVerification"
                              name="modeOfVerification"
                            />
                          </div>
                        </div>

                        <div className="row my-2">
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Name & designation of the verifier
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="verifierName"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "experience"
                                )
                              }
                              value={item?.experience?.verifierName || ""}
                            />
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="entityName">
                              Contact details of the verifier
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="verifierContact"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumeric",
                                  "experience"
                                )
                              }
                              value={item?.experience?.verifierContact || ""}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        HR Email
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="hrEmail"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithSymbols",
                            "experience"
                          )
                        }
                        value={item?.experience?.hrEmail || ""}
                      />
                    </div>
                    {localStorageData.role !== "user" && (
                      <>
                        <div className="row my-2">
                          <div className="col-md-8">
                            <label className="small mb-1" htmlFor="clientType">
                              Additional remarks
                            </label>
                            <InputTextarea
                              className="form-control"
                              type="text"
                              name="additionalRemarks"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumeric",
                                  "experience"
                                )
                              }
                              value={item?.experience?.additionalRemarks || ""}
                              rows={5}
                              cols={30}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.experience?.reportUrl?.length === 0 ||
                      editable === true ? (
                          <FileUpload
                            name="fileUpload[]"
                            multiple
                            auto
                            accept="image/*,application/pdf"
                            customUpload={true}
                            // maxFileSize={50000000}
                            uploadHandler={(e) => handleFileUpload(e, "file")}
                            itemTemplate={customItemTemplate}
                          />
                        ) : (
                          ""
                        )}

                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.experience?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </>
                  {localStorageData.role !== "user" && (
                    <>
                      <div className="col-md-8 mt-2">
                        <label className="small mb-1" htmlFor="remark">
                          Remark
                        </label>
                        <textarea
                          className="form-control h-[110px]"
                          name="remark"
                          onChange={(e) => {
                            // setRemarkForActivity(e.target.value);
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "experience"
                            );
                          }}
                          value={item?.experience?.remark || ""}
                        />
                      </div>

                      <div className="col-md-12 my-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Upload Proof{" "}
                          <span className="opacity-70">
                            (Please Choose JPEG,PNG Only)
                          </span>
                        </label>
                        {item?.experience?.uploadProofUrl?.length === 0 && (
                          <FileUpload
                            name="fileUpload[]"
                            auto
                            multiple
                            accept="image/*"
                            customUpload={true}
                            maxFileSize={10000000}
                            uploadHandler={(e) =>
                              handleFileUpload(e, "uploadProof")
                            }
                            itemTemplate={customItemTemplate}
                          />
                        )}
                        <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                          {(item?.experience?.uploadProofUrl || []).map(
                            (fileUrl, proofIndex) => {
                              // Extract file name from the URL
                              const fileName = fileUrl.split("/").pop();
                              const fileExtension = fileName
                                .split(".")
                                .pop()
                                .toLowerCase();

                              const isImage = ["jpeg", "png"].includes(
                                fileExtension
                              );

                              return (
                                <li
                                  key={proofIndex}
                                  className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                >
                                  <div
                                    role="presentation"
                                    onClick={(e) =>
                                      removeImg(
                                        e,
                                        item._id,
                                        item,
                                        proofIndex,
                                        "proof"
                                      )
                                    } // Pass the `item` to removeImg
                                    className="cursor-pointer"
                                  >
                                    <i
                                      className="pi pi-times"
                                      style={{ color: "red" }}
                                    ></i>
                                  </div>
                                  <Button
                                    label="Download"
                                    onClick={(e) =>
                                      getImg(
                                        e,
                                        fileName,
                                        dataIndex,
                                        proofIndex,
                                        "proof"
                                      )
                                    }
                                    className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                  />
                                  <div className="text-center mt-2">
                                    {isImage &&
                                      proofImage[dataIndex]?.[proofIndex] && (
                                      <a
                                        href={
                                          proofImage[dataIndex][proofIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${proofIndex}`}
                                      >
                                          View Image
                                      </a>
                                    )}
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </>
                  )}

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.experience?.status === "Pending" ? (
                        item?.experience?.reportUrl?.length > 0 ? (
                          <Button
                            label="Edit"
                            type="button"
                            onClick={(e) => {
                              handleEdit(e);
                            }}
                            className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    {/* {item?.experience?.status === "Pending" ? ( */}
                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateExperienceDetails(e, item._id, dataIndex);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.experience?.status)}</div>
                    </div>
                    {/* ) : ( */}
                    {/* <div>{getSeverity(item?.experience?.status)}</div>
                    )} */}
                  </div>
                </form>
              )}

              {process.env.REACT_APP_IS_GVS_REPORT_ENABLED === "true" && (
                <form>
                  <div className="col-md-4">
                    <Dropdown
                      value={item?.experience?.type || ""}
                      onChange={(e) => onInputChange(e, dataIndex)}
                      options={experienceType}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select Type"
                      filter
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                      className="w-full md:w-14rem"
                      inputId="type"
                      name="type"
                      disabled={
                        flag === "true" || localStorageData?.role === "Auditor"
                      }
                    />
                  </div>
                  {(item?.experience?.type === "Experience-1" ||
                    item?.experience?.type === "Experience-2" ||
                    item?.experience?.type === "Experience-3") && (
                    <>
                      <div className="row my-2"></div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name of candidate
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="nameOfCandidate"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "experience"
                              )
                            }
                            value={item?.experience?.nameOfCandidate || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>
                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={
                                item?.verificationDetails
                                  ?.nameOfCandidateRemark
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="nameOfCandidateRemark"
                              name="nameOfCandidateRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Organization Name
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="organizationName"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text", "experience")
                            }
                            value={item?.experience?.organizationName || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>
                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={
                                item?.verificationDetails
                                  ?.organizationNameRemark
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="organizationNameRemark"
                              name="organizationNameRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Employment Start Dates
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="employmentStartDates"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "experience"
                              )
                            }
                            value={item?.experience?.employmentStartDates || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>
                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={
                                item?.verificationDetails
                                  ?.employmentStartDatesRemark
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="employmentStartDatesRemark"
                              name="employmentStartDatesRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Employment End Dates
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="employmentEndDates"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "experience"
                              )
                            }
                            value={item?.experience?.employmentEndDates || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>
                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={
                                item?.verificationDetails
                                  ?.employmentEndDatesRemark
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="employmentEndDatesRemark"
                              name="employmentEndDatesRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Last Position Held & Department
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="lastPosition"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text", "experience")
                            }
                            value={item?.experience?.lastPosition || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>
                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={
                                item?.verificationDetails?.lastPositionRemark
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="lastPositionRemark"
                              name="lastPositionRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Employee ID/Code
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="employeeCode"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "experience"
                              )
                            }
                            value={item?.experience?.employeeCode || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>

                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={
                                item?.verificationDetails?.employeeCodeRemark
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="employeeCodeRemark"
                              name="employeeCodeRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Designation
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="designation"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text", "experience")
                            }
                            value={item?.experience?.designation || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Supervisor’s Name
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="supervisorName"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text", "experience")
                            }
                            value={item?.experience?.supervisorName || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>
                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={
                                item?.verificationDetails
                                  ?.supervisorNameRemark
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="supervisorNameRemark"
                              name="supervisorNameRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Supervisor’s Designation
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="supervisorDesignation"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text", "experience")
                            }
                            value={
                              item?.experience?.supervisorDesignation || ""
                            }
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>

                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={
                                item?.verificationDetails
                                  ?.supervisorDesignationRemark
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="supervisorDesignationRemark"
                              name="supervisorDesignationRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>
                      {/* <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Salary Details
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="salary"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric","experience")
                            }
                            value={item?.experience?.salary || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>

                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={item?.verificationDetails?.salaryRemark}
                              onChange={(e) => onInputChange(e, dataIndex,"alphanumericWithAllowedSymbols","verificationDetails")}
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="salaryRemark"
                              name="salaryRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div> */}
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Reason for Leaving
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="reasonForLeaving"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text", "experience")
                            }
                            value={item?.experience?.reasonForLeaving || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>
                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={
                                item?.verificationDetails
                                  ?.reasonForLeavingRemark
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="reasonForLeavingRemark"
                              name="reasonForLeavingRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>

                      <label
                        className="large font-bold mt-1"
                        htmlFor="clientType"
                      >
                        Salary Details
                      </label>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Salary Type
                          </label>
                          <Dropdown
                            value={item?.experience?.salaryType}
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "experience"
                              )
                            }
                            options={salaryTypeRemark}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select Remark"
                            filter
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem"
                            inputId="salaryType"
                            name="salaryType"
                            disabled={
                              flag === "false" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>

                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={item?.verificationDetails?.salaryRemark}
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="salaryRemark"
                              name="salaryRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Salary Amount
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="salary"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "numeric",
                                "experience"
                              )
                            }
                            value={item?.experience?.salary || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>

                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={item?.verificationDetails?.salaryRemark}
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="salaryRemark"
                              name="salaryRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Salary Currency
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="salaryCurrency"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumeric",
                                "experience"
                              )
                            }
                            value={item?.experience?.salaryCurrency || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>

                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={
                                item?.verificationDetails
                                  ?.salaryCurrencyRemark
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="salaryCurrencyRemark"
                              name="salaryCurrencyRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Office address
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="officeAddress"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumeric",
                                "experience"
                              )
                            }
                            value={item?.experience?.officeAddress || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>

                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={
                                item?.verificationDetails?.officeAddressRemark
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="officeAddressRemark"
                              name="officeAddressRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Country
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="country"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumeric",
                                "experience"
                              )
                            }
                            value={item?.experience?.country || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>

                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={item?.verificationDetails?.countryRemark}
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="countryRemark"
                              name="countryRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            State
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="state"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumeric",
                                "experience"
                              )
                            }
                            value={item?.experience?.state || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>

                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={item?.verificationDetails?.stateRemark}
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="stateRemark"
                              name="stateRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            District/City
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="city"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumeric",
                                "experience"
                              )
                            }
                            value={item?.experience?.city || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>

                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={item?.verificationDetails?.cityRemark}
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="cityRemark"
                              name="cityRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Pincode
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="pincode"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "numeric",
                                "experience"
                              )
                            }
                            value={item?.experience?.pincode || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>

                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={item?.verificationDetails?.pincodeRemark}
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "verificationDetails"
                                )
                              }
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="pincodeRemark"
                              name="pincodeRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div>
                      {localStorageData?.role !== "user" &&
                        localStorageData?.role !== "DataEntry" && (
                        <>
                          <div className="row my-2">
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                  Eligibility to Re-hire
                              </label>
                              <InputText
                                className="form-control"
                                type="text"
                                name="rehire"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "text",
                                    "experience"
                                  )
                                }
                                value={item?.experience?.rehire || ""}
                                disabled={
                                  flag === "true" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                          </div>
                          <div className="row my-2">
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                  Respondent Name
                              </label>
                              <InputText
                                className="form-control"
                                type="text"
                                name="respondentName"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "text",
                                    "experience"
                                  )
                                }
                                value={item?.experience?.respondentName || ""}
                                disabled={
                                  flag === "true" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                  Respondent’s Contact Details
                              </label>
                              <InputText
                                className="form-control"
                                type="text"
                                name="respondentContactDetails"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols",
                                    "experience"
                                  )
                                }
                                value={
                                  item?.experience
                                    ?.respondentContactDetails || ""
                                }
                                disabled={
                                  flag === "true" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="entityName"
                              >
                                  HR Email
                              </label>
                              <InputText
                                className="form-control"
                                type="text"
                                name="hrEmail"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithSymbols",
                                    "experience"
                                  )
                                }
                                value={item?.experience?.hrEmail || ""}
                                disabled={
                                  flag === "true" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                          </div>

                          <div className="row my-2">
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                  Attendance & Punctuality
                              </label>
                              <InputText
                                className="form-control"
                                type="text"
                                name="attendance"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "text",
                                    "experience"
                                  )
                                }
                                value={item?.experience?.attendance || ""}
                                disabled={
                                  flag === "true" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                  Job Performance
                              </label>
                              <InputText
                                className="form-control"
                                type="text"
                                name="jobPerformance"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "text",
                                    "experience"
                                  )
                                }
                                value={item?.experience?.jobPerformance || ""}
                                disabled={
                                  flag === "true" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                  Organization Integrity Issues
                              </label>
                              <InputText
                                className="form-control"
                                type="text"
                                name="organizationIntegrityIssues"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "text",
                                    "experience"
                                  )
                                }
                                value={
                                  item?.experience
                                    ?.organizationIntegrityIssues || ""
                                }
                                disabled={
                                  flag === "true" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                          </div>

                          <div className="row my-2">
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                  Exit formalities
                              </label>
                              <InputText
                                className="form-control"
                                type="text"
                                name="exitFormalities"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "text",
                                    "experience"
                                  )
                                }
                                value={
                                  item?.experience?.exitFormalities || ""
                                }
                                disabled={
                                  flag === "true" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                  FnF Settled
                              </label>
                              <InputText
                                className="form-control"
                                type="text"
                                name="fnFSettled"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "text",
                                    "experience"
                                  )
                                }
                                value={item?.experience?.fnFSettled || ""}
                                disabled={
                                  flag === "true" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                  Does the candidate owe any dues
                              </label>
                              <InputText
                                className="form-control"
                                type="text"
                                name="candidateDues"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "text",
                                    "experience"
                                  )
                                }
                                value={item?.experience?.candidateDues || ""}
                                disabled={
                                  flag === "true" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                          </div>

                          <div className="row my-2">
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                  Any disciplinary issues against the candidate
                              </label>
                              <InputText
                                className="form-control"
                                type="text"
                                name="candidateDisciplinary"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "text",
                                    "experience"
                                  )
                                }
                                value={
                                  item?.experience?.candidateDisciplinary ||
                                    ""
                                }
                                disabled={
                                  flag === "true" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="entityName"
                              >
                                  Select
                              </label>
                              <Dropdown
                                value={
                                  item?.verificationDetails?.selectRemark ||
                                    ""
                                }
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols",
                                    "verificationDetails"
                                  )
                                }
                                options={selectRemark}
                                optionLabel="name"
                                optionValue="name"
                                placeholder="Select Remark"
                                filter
                                valueTemplate={selectedCountryTemplate}
                                itemTemplate={countryOptionTemplate}
                                className="w-full md:w-14rem"
                                inputId="selectRemark"
                                name="selectRemark"
                                disabled={
                                  flag === "false" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                          </div>
                          <div className="row my-2">
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                  Any special comments about the candidate
                              </label>
                              <InputText
                                className="form-control"
                                type="text"
                                name="candidateSpecialComments"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols",
                                    "experience"
                                  )
                                }
                                value={
                                  item?.experience
                                    ?.candidateSpecialComments || ""
                                }
                                disabled={
                                  flag === "true" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="entityName"
                              >
                                  Annexure
                              </label>

                              <Dropdown
                                value={
                                  item?.verificationDetails?.annexure || ""
                                }
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols",
                                    "verificationDetails"
                                  )
                                }
                                options={experienceAnnexure}
                                optionLabel="name"
                                optionValue="name"
                                placeholder="Select Annexure"
                                filter
                                valueTemplate={selectedCountryTemplate}
                                itemTemplate={countryOptionTemplate}
                                className="w-full md:w-14rem"
                                inputId="annexure"
                                name="annexure"
                                disabled={
                                  flag === "false" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                          </div>
                          <div className="row my-2">
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                  Candidate overall behavior and code of conduct
                              </label>
                              <InputText
                                className="form-control"
                                type="text"
                                name="candidateBehavior"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "text",
                                    "experience"
                                  )
                                }
                                value={
                                  item?.experience?.candidateBehavior || ""
                                }
                                disabled={
                                  flag === "true" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                  Ever Seen Intoxicated or taking drugs, alcohol
                                  in Office/ Company Campus
                              </label>
                              <InputText
                                className="form-control"
                                type="text"
                                name="intoxicated"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "text",
                                    "experience"
                                  )
                                }
                                value={item?.experience?.intoxicated || ""}
                                disabled={
                                  flag === "true" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="entityName"
                              >
                                  Remark Date
                              </label>
                              <InputText
                                className="form-control"
                                type="Date"
                                name="remarkDate"
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols",
                                    "verificationDetails"
                                  )
                                }
                                value={
                                  item?.verificationDetails?.remarkDate || ""
                                }
                                disabled={
                                  flag === "false" ||
                                    localStorageData?.role === "Auditor"
                                }
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="row my-2">
                        <div className="col-md-8">
                          <label className="small mb-1" htmlFor="clientType">
                            Comments
                          </label>
                          <InputTextarea
                            className="form-control"
                            type="text"
                            name="additionalRemarks"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols",
                                "experience"
                              )
                            }
                            value={item?.experience?.additionalRemarks || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                            rows={5}
                            cols={30}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 border-bottom">
                        <label className="small mb-1" htmlFor="clientType">
                          File Upload{" "}
                          <span className="opacity-70">
                            (Please Choose JPEG,PNG,PDF less than 5MB)
                          </span>
                        </label>
                        {item?.experience?.reportUrl?.length === 0 ||
                        editable === true ? (
                            <FileUpload
                              name="fileUpload[]"
                              multiple
                              auto
                              accept="image/*,application/pdf"
                              customUpload={true}
                              // maxFileSize={50000000}
                              uploadHandler={(e) => handleFileUpload(e, "file")}
                              itemTemplate={customItemTemplate}
                              disabled={localStorageData?.role === "BCAStaff"}
                            />
                          ) : (
                            ""
                          )}

                        <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                          {(item?.experience?.reportUrl || []).map(
                            (fileUrl, reportIndex) => {
                              // Extract file name from the URL
                              const fileName = fileUrl.split("/").pop();
                              const fileExtension = fileName
                                .split(".")
                                .pop()
                                .toLowerCase();

                              const isImage = ["jpeg", "png"].includes(
                                fileExtension
                              );

                              const isPdf = fileExtension === "pdf";
                              return (
                                <li
                                  key={reportIndex}
                                  className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                >
                                  <div
                                    role="presentation"
                                    onClick={(e) =>
                                      removeImg(
                                        e,
                                        item._id,
                                        item,
                                        reportIndex,
                                        "file"
                                      )
                                    } // Pass the `item` to removeImg
                                    className="cursor-pointer"
                                  >
                                    <i
                                      className="pi pi-times"
                                      style={{ color: "red" }}
                                    ></i>
                                  </div>
                                  <Button
                                    label="Download"
                                    onClick={(e) =>
                                      getImg(
                                        e,
                                        fileName,
                                        dataIndex,
                                        reportIndex,
                                        "img"
                                      )
                                    }
                                    className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                  />

                                  {fileMap[dataIndex]?.[reportIndex] && (
                                    <div className="text-center mt-2">
                                      {isImage && (
                                        <a
                                          href={fileMap[dataIndex][reportIndex]}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          download={`file_${dataIndex}_${reportIndex}`}
                                        >
                                          View Image
                                        </a>
                                      )}
                                      {isPdf && (
                                        <a
                                          href={fileMap[dataIndex][reportIndex]}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-center"
                                        >
                                          View PDF
                                        </a>
                                      )}
                                    </div>
                                  )}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </>
                  )}

                  {localStorageData?.role !== "user" &&
                    localStorageData?.role !== "DataEntry" && (
                    <>
                      <div className="col-md-8 mt-2">
                        <label className="small mb-1" htmlFor="remark">
                            Remark
                        </label>
                        <textarea
                          className="form-control h-[110px]"
                          name="remark"
                          onChange={(e) => {
                            // setRemarkForActivity(e.target.value);
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "experience"
                            );
                          }}
                          value={item?.experience?.remark || ""}
                          disabled={
                            flag === "false" ||
                              localStorageData?.role === "Auditor"
                          }
                        />
                      </div>

                      <div className="col-md-12 my-2">
                        <label className="small mb-1" htmlFor="clientType">
                            Upload Proof{" "}
                          <span className="opacity-70">
                              (Please Choose PDF less than 10MB)
                          </span>
                        </label>
                        {item?.experience?.uploadProofUrl?.length === 0 && (
                          <FileUpload
                            name="fileUpload[]"
                            auto
                            multiple
                            accept="image/*"
                            customUpload={true}
                            maxFileSize={10000000}
                            uploadHandler={(e) =>
                              handleFileUpload(e, "uploadProof")
                            }
                            itemTemplate={customItemTemplate}
                            disabled={
                              flag === "false" ||
                                localStorageData?.role === "Auditor"
                            }
                          />
                        )}
                        <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                          {(item?.experience?.uploadProofUrl || []).map(
                            (fileUrl, proofIndex) => {
                              // Extract file name from the URL
                              const fileName = fileUrl.split("/").pop();
                              const fileExtension = fileName
                                .split(".")
                                .pop()
                                .toLowerCase();

                              const isImage = ["jpeg", "png"].includes(
                                fileExtension
                              );

                              return (
                                <li
                                  key={proofIndex}
                                  className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                >
                                  <div
                                    role="presentation"
                                    onClick={(e) =>
                                      removeImg(
                                        e,
                                        item._id,
                                        item,
                                        proofIndex,
                                        "proof"
                                      )
                                    } // Pass the `item` to removeImg
                                    className="cursor-pointer"
                                  >
                                    <i
                                      className="pi pi-times"
                                      style={{ color: "red" }}
                                    ></i>
                                  </div>
                                  <Button
                                    label="Download"
                                    onClick={(e) =>
                                      getImg(
                                        e,
                                        fileName,
                                        dataIndex,
                                        proofIndex,
                                        "proof"
                                      )
                                    }
                                    className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                  />
                                  <div className="text-center mt-2">
                                    {isImage &&
                                        proofImage[dataIndex]?.[proofIndex] && (
                                      <a
                                        href={
                                          proofImage[dataIndex][proofIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${proofIndex}`}
                                      >
                                            View Image
                                      </a>
                                    )}
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </>
                  )}

                  <div></div>

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.experience?.status === "Pending" ? (
                        item?.experience?.reportUrl?.length > 0 ? (
                          <Button
                            label="Edit"
                            type="button"
                            onClick={(e) => {
                              handleEdit(e);
                            }}
                            className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    {item?.experience?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateExperienceDetails(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.experience?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.experience?.status)}</div>
                    )}
                  </div>
                </form>
              )}

              {process.env.REACT_APP_IS_SIRIUS_REPORT_ENABLED === "true" && (
                <form>
                  <div className="col-md-4">
                    <Dropdown
                      value={item?.experience?.type || ""}
                      onChange={(e) => onInputChange(e, dataIndex)}
                      options={experienceType}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select Type"
                      filter
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                      className="w-full md:w-14rem"
                      inputId="type"
                      name="type"
                      disabled={
                        flag === "true" || localStorageData?.role === "Auditor"
                      }
                    />
                  </div>

                  <>
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="nameOfCandidate"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "experience"
                            )
                          }
                          value={item?.experience?.nameOfCandidate || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Remark
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="nameOfCandidateRemark"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "verificationDetails"
                            )
                          }
                          value={
                            item?.verificationDetails?.nameOfCandidateRemark ||
                            ""
                          }
                          disabled={flag === "false"}
                        />
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Employee ID
                        </label>
                        <InputText
                          className="form-control"
                          type="int"
                          name="employeeId"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "experience"
                            )
                          }
                          value={item?.experience?.employeeId || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Remark
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="employeeIdRemark"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "verificationDetails"
                            )
                          }
                          value={
                            item?.verificationDetails?.employeeIdRemark || ""
                          }
                          disabled={flag === "false"}
                        />
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Designation
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="designation"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "experience"
                            )
                          }
                          value={item?.experience?.designation || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Remark
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="designationRemark"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "verificationDetails"
                            )
                          }
                          value={
                            item?.verificationDetails?.designationRemark || ""
                          }
                          disabled={flag === "false"}
                        />
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Department
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="department"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "experience"
                            )
                          }
                          value={item?.experience?.department || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Remark
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="departmentRemark"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "verificationDetails"
                            )
                          }
                          value={
                            item?.verificationDetails?.departmentRemark || ""
                          }
                          disabled={flag === "false"}
                        />
                      </div>
                    </div>

                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Date of Joining
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="employmentStartDates"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "experience"
                            )
                          }
                          value={item?.experience?.employmentStartDates || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Remark
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="employmentStartDatesRemark"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "verificationDetails"
                            )
                          }
                          value={
                            item?.verificationDetails
                              ?.employmentStartDatesRemark || ""
                          }
                          disabled={flag === "false"}
                        />
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Last working date
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="employmentEndDates"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "experience"
                            )
                          }
                          value={item?.experience?.employmentEndDates || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Remark
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="employmentEndDatesRemark"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "verificationDetails"
                            )
                          }
                          value={
                            item?.verificationDetails
                              ?.employmentEndDatesRemark || ""
                          }
                          disabled={flag === "false"}
                        />
                      </div>
                    </div>

                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          CTC per annum
                        </label>
                        <InputText
                          className="form-control"
                          // keyfilter="int"
                          type="text"
                          name="salary"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "experience"
                            )
                          }
                          value={item?.experience?.salary || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Remark
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="salaryRemark"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "text",
                              "verificationDetails"
                            )
                          }
                          value={item?.verificationDetails?.salaryRemark || ""}
                          disabled={flag === "false"}
                        />
                      </div>
                    </div>

                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Verifier Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="verifierName"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "experience"
                            )
                          }
                          value={item?.experience?.verifierName || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Remark
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="verifierNameRemark"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "text",
                              "verificationDetails"
                            )
                          }
                          value={
                            item?.verificationDetails?.verifierNameRemark || ""
                          }
                          disabled={flag === "false"}
                        />
                      </div>
                    </div>

                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          HR Email
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="hrEmail"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithSymbols",
                              "experience"
                            )
                          }
                          value={item?.experience?.hrEmail || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-8">
                        <label className="small mb-1" htmlFor="clientType">
                          Any comments?
                        </label>
                        <InputTextarea
                          className="form-control"
                          type="text"
                          name="additionalRemarks"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithSymbols",
                              "experience"
                            )
                          }
                          value={item?.experience?.additionalRemarks || ""}
                          disabled={flag === "true"}
                          rows={5}
                          cols={30}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Remark
                        </label>
                        <InputTextarea
                          className="form-control"
                          type="text"
                          name="remark"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithSymbols",
                              "verificationDetails"
                            )
                          }
                          value={item?.verificationDetails?.remark || ""}
                          disabled={flag === "false"}
                          rows={5}
                          cols={30}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.experience?.reportUrl?.length === 0 ||
                      editable === true ? (
                          <FileUpload
                            name="fileUpload[]"
                            multiple
                            auto
                            accept="image/*,application/pdf"
                            customUpload={true}
                            // maxFileSize={50000000}
                            uploadHandler={(e) => handleFileUpload(e, "file")}
                            itemTemplate={customItemTemplate}
                            disabled={
                              flag === "true" ||
                            localStorageData?.role === "Auditor"
                            }
                          />
                        ) : (
                          ""
                        )}

                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.experience?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </>

                  {localStorageData?.role !== "user" && (
                    <div className="col-md-8 mt-2">
                      <label className="small mb-1" htmlFor="remark">
                        Remark
                      </label>
                      <textarea
                        className="form-control h-[110px]"
                        name="remark"
                        onChange={(e) => {
                          // setRemarkForActivity(e.target.value);
                          onInputChange(e, dataIndex, "alphanumeric");
                        }}
                        value={item?.experience?.remark || ""}
                        disabled={
                          flag === "false" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  )}
                  {localStorageData?.role !== "user" && (
                    <div className="col-md-12 my-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Upload Proof{" "}
                        <span className="opacity-70">
                          (Please Choose PDF less than 10MB)
                        </span>
                      </label>
                      {item?.experience?.uploadProofUrl?.length === 0 && (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*"
                          customUpload={true}
                          maxFileSize={10000000}
                          uploadHandler={(e) =>
                            handleFileUpload(e, "uploadProof")
                          }
                          itemTemplate={customItemTemplate}
                          disabled={
                            flag === "false" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.experience?.uploadProofUrl || []).map(
                          (fileUrl, proofIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            return (
                              <li
                                key={proofIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      proofIndex,
                                      "proof"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      proofIndex,
                                      "proof"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />
                                <div className="text-center mt-2">
                                  {isImage &&
                                    proofImage[dataIndex]?.[proofIndex] && (
                                    <a
                                      href={proofImage[dataIndex][proofIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download={`file_${dataIndex}_${proofIndex}`}
                                    >
                                        View Image
                                    </a>
                                  )}
                                </div>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )}
                  <div></div>

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.experience?.status === "Pending" ? (
                        item?.experience?.reportUrl?.length > 0 ? (
                          <Button
                            label="Edit"
                            type="button"
                            onClick={(e) => {
                              handleEdit(e);
                            }}
                            className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    {/* {item?.experience?.status === "Pending" ? ( */}
                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateExperienceDetails(e, item._id, dataIndex);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.experience?.status)}</div>
                    </div>
                    {/* ) : (
                      <div>{getSeverity(item?.experience?.status)}</div>
                    )} */}
                  </div>
                </form>
              )}
              {process.env.REACT_APP_IS_VLEADER_REPORT_ENABLED === "true" && (
                <form>
                  <div className="col-md-4">
                    <Dropdown
                      value={item?.experience?.type || ""}
                      onChange={(e) =>
                        onInputChange(e, dataIndex, "", "experience")
                      }
                      options={experienceType}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select Type"
                      filter
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                      className="w-full md:w-14rem"
                      inputId="type"
                      name="type"
                      disabled={
                        flag === "true" || localStorageData?.role === "Auditor"
                      }
                    />
                  </div>
                  {(item?.experience?.type === "Experience-1" ||
                    item?.experience?.type === "Experience-2" ||
                    item?.experience?.type === "Experience-3") && (
                    <>
                      <div className="flex items-center my-2">
                        <div className="col-md-4 mb-2">
                          <label className="small mb-1" htmlFor="clientType">
                            Company Name & Branch
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="organizationName"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text", "experience")
                            }
                            value={item?.experience?.organizationName || ""}
                            // disabled={
                            //   flag === "true" ||
                            //   localStorageData?.role === "Auditor"
                            // }
                          />
                        </div>
                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <>
                            {" "}
                            <div className="mt-4 ml-12 mr-12">
                              <Checkbox
                                inputId="companyName"
                                name="companyName"
                                checked={
                                  isEdit.companyName === true ||
                                    item?.verificationDetails
                                      ?.organizationNameRemark !== ""
                                }
                                onChange={() => toggleEdit("companyName")}
                              />
                            </div>
                            {(isEdit.companyName ||
                                item?.verificationDetails
                                  ?.organizationNameRemark) && (
                              <div className="col-md-4">
                                <label
                                  className="small mb-1"
                                  htmlFor="entityName"
                                >
                                    Remark
                                </label>

                                <InputText
                                  className="form-control"
                                  type="text"
                                  name="organizationNameRemark"
                                  onChange={(e) =>
                                    onInputChange(
                                      e,

                                      dataIndex,

                                      "text",

                                      "verificationDetails"
                                    )
                                  }
                                  value={
                                    item?.verificationDetails
                                      ?.organizationNameRemark || ""
                                  }

                                  // readOnly={

                                  //   flag === "true" ||

                                  //   localStorageData?.role === "Auditor"

                                  // }
                                />
                              </div>
                            )}{" "}
                          </>
                        )}
                      </div>
                      <div className="flex items-center my-2">
                        <div className="col-md-4 mb-2">
                          <label className="small mb-1" htmlFor="clientType">
                            Designation & Department
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="designation"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text", "experience")
                            }
                            value={item?.experience?.designation || ""}
                            // disabled={
                            //   flag === "true" ||
                            //   localStorageData?.role === "Auditor"
                            // }
                          />
                        </div>
                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <>
                            {" "}
                            <div className="mt-4 ml-12 mr-12">
                              <Checkbox
                                inputId="designation"
                                name="designation"
                                checked={
                                  isEdit.designation === true ||
                                    item?.verificationDetails
                                      ?.designationRemark !== ""
                                }
                                onChange={() => toggleEdit("designation")}
                              />
                            </div>
                            {(isEdit.designation ||
                                item?.verificationDetails
                                  ?.designationRemark) && (
                              <div className="col-md-4">
                                <label
                                  className="small mb-1"
                                  htmlFor="entityName"
                                >
                                    Remark
                                </label>

                                <InputText
                                  className="form-control"
                                  type="text"
                                  name="designationRemark"
                                  onChange={(e) =>
                                    onInputChange(
                                      e,

                                      dataIndex,

                                      "text",

                                      "verificationDetails"
                                    )
                                  }
                                  value={
                                    item?.verificationDetails
                                      ?.designationRemark || ""
                                  }

                                  // readOnly={

                                  //   flag === "true" ||

                                  //   localStorageData?.role === "Auditor"

                                  // }
                                />
                              </div>
                            )}{" "}
                          </>
                        )}
                      </div>

                      <div className="col-md-4 mb-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Address (main office and branch where worked)
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="officeAddress"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "experience"
                            )
                          }
                          value={item?.experience?.officeAddress || ""}
                          // disabled={
                          //   flag === "true" ||
                          //   localStorageData?.role === "Auditor"
                          // }
                        />
                      </div>

                      <div className="col-md-4 mb-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Telephone / Mobile Number
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="companyContactNumber"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "experience"
                            )
                          }
                          value={item?.experience?.companyContactNumber || ""}
                          // disabled={
                          //   flag === "true" ||
                          //   localStorageData?.role === "Auditor"
                          // }
                        />
                      </div>

                      <div className="flex items-center my-2">
                        <div className="col-md-4 mb-2">
                          <label className="small mb-1" htmlFor="clientType">
                            Reporting To(Supervisor)
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="supervisorName"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text", "experience")
                            }
                            value={item?.experience?.supervisorName || ""}
                            // disabled={
                            //   flag === "true" ||
                            //   localStorageData?.role === "Auditor"
                            // }
                          />
                        </div>
                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <>
                            <div className="mt-4 ml-12 mr-12">
                              <Checkbox
                                inputId="supervisorName"
                                name="supervisorName"
                                checked={
                                  isEdit.supervisorName === true ||
                                    item?.verificationDetails
                                      ?.supervisorNameRemark !== ""
                                }
                                onChange={() => toggleEdit("supervisorName")}
                              />
                            </div>
                            {(isEdit.supervisorName ||
                                item?.verificationDetails
                                  ?.supervisorNameRemark) && (
                              <div className="col-md-4">
                                <label
                                  className="small mb-1"
                                  htmlFor="entityName"
                                >
                                    Remark
                                </label>

                                <InputText
                                  className="form-control"
                                  type="text"
                                  name="supervisorNameRemark"
                                  onChange={(e) =>
                                    onInputChange(
                                      e,

                                      dataIndex,

                                      "text",

                                      "verificationDetails"
                                    )
                                  }
                                  value={
                                    item?.verificationDetails
                                      ?.supervisorNameRemark || ""
                                  }

                                  // readOnly={

                                  //   flag === "true" ||

                                  //   localStorageData?.role === "Auditor"

                                  // }
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>

                      <div className="col-md-4 mb-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Supervisor Contact Number
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="supervisorNumber"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "experience"
                            )
                          }
                          value={item?.experience?.supervisorNumber || ""}
                          // disabled={
                          //   flag === "true" ||
                          //   localStorageData?.role === "Auditor"
                          // }
                        />
                      </div>

                      <div className="col-md-4 mb-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Subordinate Name and Details
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="subordinateName"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "text", "experience")
                          }
                          value={item?.experience?.subordinateName || ""}
                          // disabled={
                          //   flag === "true" ||
                          //   localStorageData?.role === "Auditor"
                          // }
                        />
                      </div>

                      <div className="col-md-4 mb-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Subordinate Contact Number
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="subordinateNumber"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "experience"
                            )
                          }
                          value={item?.experience?.subordinateNumber || ""}
                          // disabled={
                          //   flag === "true" ||
                          //   localStorageData?.role === "Auditor"
                          // }
                        />
                      </div>

                      <div className="col-md-4 mb-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Peers Name and Details
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="peersName"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "text", "experience")
                          }
                          value={item?.experience?.peersName || ""}
                          // disabled={
                          //   flag === "true" ||
                          //   localStorageData?.role === "Auditor"
                          // }
                        />
                      </div>

                      <div className="col-md-4 mb-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Peers Contact Number
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="peersNumber"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "experience"
                            )
                          }
                          value={item?.experience?.peersNumber || ""}
                          // disabled={
                          //   flag === "true" ||
                          //   localStorageData?.role === "Auditor"
                          // }
                        />
                      </div>
                      <div className="flex items-center my-2">
                        <div className="col-md-4 mb-2">
                          <label className="small mb-1" htmlFor="clientType">
                            Employee Code given by company
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="employeeCode"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumeric",
                                "experience"
                              )
                            }
                            value={item?.experience?.employeeCode || ""}
                            // disabled={
                            //   flag === "true" ||
                            //   localStorageData?.role === "Auditor"
                            // }
                          />
                        </div>
                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <>
                            {" "}
                            <div className="mt-4 ml-12 mr-12">
                              <Checkbox
                                inputId="employeeCode"
                                name="employeeCode"
                                checked={
                                  isEdit.employeeCode === true ||
                                    item?.verificationDetails
                                      ?.employeeCodeRemark !== ""
                                }
                                onChange={() => toggleEdit("employeeCode")}
                              />
                            </div>
                            {(isEdit.employeeCode ||
                                item?.verificationDetails
                                  ?.employeeCodeRemark) && (
                              <div className="col-md-4">
                                <label
                                  className="small mb-1"
                                  htmlFor="entityName"
                                >
                                    Remark
                                </label>

                                <InputText
                                  className="form-control"
                                  type="text"
                                  name="employeeCodeRemark"
                                  onChange={(e) =>
                                    onInputChange(
                                      e,

                                      dataIndex,

                                      "alphanumeric",

                                      "verificationDetails"
                                    )
                                  }
                                  value={
                                    item?.verificationDetails
                                      ?.employeeCodeRemark || ""
                                  }

                                  // readOnly={

                                  //   flag === "true" ||

                                  //   localStorageData?.role === "Auditor"

                                  // }
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>

                      <div className="col-md-4 mb-2">
                        <label className="small mb-1" htmlFor="clientType">
                          HR Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="hrName"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "text", "experience")
                          }
                          value={item?.experience?.hrName || ""}
                          // disabled={
                          //   flag === "true" ||
                          //   localStorageData?.role === "Auditor"
                          // }
                        />
                      </div>

                      <div className="col-md-4 mb-2">
                        <label className="small mb-1" htmlFor="clientType">
                          HR Number
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="hrNumber"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "experience"
                            )
                          }
                          value={item?.experience?.hrNumber || ""}
                          // disabled={
                          //   flag === "true" ||
                          //   localStorageData?.role === "Auditor"
                          // }
                        />
                      </div>

                      <div className="col-md-4 mb-2">
                        <label className="small mb-1" htmlFor="clientType">
                          HR Mail ID
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="hrEmail"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithSymbols",
                              "experience"
                            )
                          }
                          value={item?.experience?.hrEmail || ""}
                          // disabled={
                          //   flag === "true" ||
                          //   localStorageData?.role === "Auditor"
                          // }
                        />
                      </div>
                      <div className="flex items-center my-2">
                        <div className="col-md-4 mb-2">
                          <label className="small mb-1" htmlFor="clientType">
                            Employment Start Dates (From)
                          </label>

                          {/* {item?.experience?.employmentStartDates !== "" &&
                            editStartDate === false && (
                            <i
                              className="pi pi-pencil"
                              style={{
                                fontSize: "1rem",
                                color: "#075B86",
                                cursor: "pointer",
                                marginLeft: 5,
                              }}
                              onClick={() => setEditStartDate(!editStartDate)}
                              role="button"
                              tabIndex="0"
                              aria-label="Edit Date"
                            ></i>
                          )}
                          <br />
                          {item?.experience?.employmentStartDates === "" ||
                          editStartDate === true ? (
                              <>
                                <Calendar
                                  value={
                                    item?.experience?.employmentStartDates || ""
                                  }
                                  onChange={(e) =>
                                    onInputChange(
                                      e,
                                      dataIndex,
                                      "alphanumericWithAllowedSymbols",
                                      "experience"
                                    )
                                  }
                                  name="employmentStartDates"
                                  placeholder="DD/MM/YYYY"
                                  dateFormat="dd/mm/yy"
                                  showIcon
                                  // readOnlyInput
                                  className="!w-[350px]"
                                />
                              </>
                            ) : (
                              <> */}
                          <InputText
                            className="form-control"
                            type="Date"
                            name="employmentStartDates"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, " ", "experience")
                            }
                            value={item?.experience?.employmentStartDates || ""}
                            // readOnly={true}
                          />
                          {/* </>
                            )} */}
                        </div>
                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <>
                            {" "}
                            <div className="mt-4 ml-12 mr-12">
                              <Checkbox
                                inputId="employmentStartDates"
                                name="employmentStartDates"
                                checked={
                                  isEdit.employmentStartDates === true ||
                                    item?.verificationDetails
                                      ?.employmentStartDatesRemark !== ""
                                }
                                onChange={() =>
                                  toggleEdit("employmentStartDates")
                                }
                              />
                            </div>
                            {(isEdit.employmentStartDates ||
                                item?.verificationDetails
                                  ?.employmentStartDatesRemark) && (
                              <div className="col-md-4">
                                <label
                                  className="small mb-1"
                                  htmlFor="entityName"
                                >
                                    Remark
                                </label>

                                {/* {item?.verificationDetails
                                    ?.employmentStartDatesRemark !== "" &&
                                    editStartDateRemark === false && (
                                      <i
                                        className="pi pi-pencil"
                                        style={{
                                          fontSize: "1rem",
                                          color: "#075B86",
                                          cursor: "pointer",
                                          marginLeft: 5,
                                        }}
                                        onClick={() =>
                                          setEditStartDateRemark(
                                            !editStartDateRemark
                                          )
                                        }
                                        role="button"
                                        tabIndex="0"
                                        aria-label="Edit Date"
                                      ></i>
                                    )}
                                  <br /> */}
                                {/* {item?.verificationDetails
                                    ?.employmentStartDatesRemark === "" ||
                                  editStartDateRemark === true ? (
                                    <>
                                      <Calendar
                                        value={
                                          item?.verificationDetails
                                            ?.employmentStartDatesRemark || ""
                                        }
                                        onChange={(e) =>
                                          onInputChange(
                                            e,
                                            dataIndex,
                                            "alphanumericWithAllowedSymbols",
                                            "verificationDetails"
                                          )
                                        }
                                        name="employmentStartDatesRemark"
                                        placeholder="DD/MM/YYYY"
                                        dateFormat="dd/mm/yy"
                                        showIcon
                                        // readOnlyInput
                                        className="!w-[350px]"
                                      />
                                    </>
                                  ) : (
                                    <> */}
                                <InputText
                                  className="form-control"
                                  type="Date"
                                  name="employmentStartDatesRemark"
                                  onChange={(e) =>
                                    onInputChange(
                                      e,
                                      dataIndex,
                                      " ",
                                      "verificationDetails"
                                    )
                                  }
                                  value={
                                    item?.verificationDetails
                                      ?.employmentStartDatesRemark || " "
                                  }
                                  // readOnly={true}
                                />
                                {/* </>
                                  )} */}
                              </div>
                            )}{" "}
                          </>
                        )}
                      </div>
                      <div className="flex items-center my-2">
                        <div className="col-md-4 mb-2">
                          <label className="small mb-1" htmlFor="clientType">
                            Employment End Dates (To)
                          </label>

                          {/* {item?.experience?.employmentEndDates !== "" &&
                            editEndDate === false && (
                              <i
                                className="pi pi-pencil"
                                style={{
                                  fontSize: "1rem",
                                  color: "#075B86",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                                onClick={() => setEditEndDate(!editEndDate)}
                                role="button"
                                tabIndex="0"
                                aria-label="Edit Date"
                              ></i>
                            )}
                          <br />
                          {item?.experience?.employmentEndDates === "" ||
                          editEndDate === true ? (
                            <>
                              <Calendar
                                value={
                                  item?.experience?.employmentEndDates || ""
                                }
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols",
                                    "experience"
                                  )
                                }
                                name="employmentEndDates"
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy"
                                showIcon
                                // readOnlyInput
                                className="!w-[350px]"
                              />
                            </>
                          ) : (
                            <> */}
                          <InputText
                            className="form-control"
                            type="Date"
                            name="employmentEndDates"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, " ", "experience")
                            }
                            value={item?.experience?.employmentEndDates || ""}
                            // readOnly={true}
                          />
                          {/* </>
                          )} */}
                        </div>
                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <>
                            {" "}
                            <div className="mt-4 ml-12 mr-12">
                              <Checkbox
                                inputId="employmentEndDates"
                                name="employmentEndDates"
                                checked={
                                  isEdit.employmentEndDates === true ||
                                    item?.verificationDetails
                                      ?.employmentEndDatesRemark !== ""
                                }
                                onChange={() =>
                                  toggleEdit("employmentEndDates")
                                }
                              />
                            </div>
                            {(isEdit.employmentEndDates ||
                                item?.verificationDetails
                                  ?.employmentEndDatesRemark) && (
                              <div className="col-md-4">
                                <label
                                  className="small mb-1"
                                  htmlFor="entityName"
                                >
                                    Remark
                                </label>

                                {/* {item?.verificationDetails
                                    ?.employmentEndDatesRemark !== "" &&
                                    editEndDateRemark === false && (
                                      <i
                                        className="pi pi-pencil"
                                        style={{
                                          fontSize: "1rem",
                                          color: "#075B86",
                                          cursor: "pointer",
                                          marginLeft: 5,
                                        }}
                                        onClick={() =>
                                          setEditEndDateRemark(
                                            !editEndDateRemark
                                          )
                                        }
                                        role="button"
                                        tabIndex="0"
                                        aria-label="Edit Date"
                                      ></i>
                                    )}
                                  <br />
                                  {item?.verificationDetails
                                    ?.employmentEndDatesRemark === "" ||
                                  editEndDateRemark === true ? (
                                    <>
                                      <Calendar
                                        value={
                                          item?.verificationDetails
                                            ?.employmentEndDatesRemark || ""
                                        }
                                        onChange={(e) =>
                                          onInputChange(
                                            e,
                                            dataIndex,
                                            "alphanumericWithAllowedSymbols",
                                            "verificationDetails"
                                          )
                                        }
                                        name="employmentEndDatesRemark"
                                        placeholder="DD/MM/YYYY"
                                        dateFormat="dd/mm/yy"
                                        showIcon
                                        // readOnlyInput
                                        className="!w-[350px]"
                                      />
                                    </>
                                  ) : (
                                    <> */}
                                <InputText
                                  className="form-control"
                                  type="Date"
                                  name="employmentEndDatesRemark"
                                  onChange={(e) =>
                                    onInputChange(
                                      e,
                                      dataIndex,
                                      " ",
                                      "verificationDetails"
                                    )
                                  }
                                  value={
                                    item?.verificationDetails
                                      ?.employmentEndDatesRemark || " "
                                  }
                                  // readOnly={true}
                                />
                                {/* </>
                                  )} */}
                              </div>
                            )}{" "}
                          </>
                        )}
                      </div>

                      {/* <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Salary Details
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="salary"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric","experience")
                            }
                            value={item?.experience?.salary || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>

                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <div className="col-md-4">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Remark
                            </label>

                            <Dropdown
                              value={item?.verificationDetails?.salaryRemark}
                              onChange={(e) => onInputChange(e, dataIndex,"alphanumericWithAllowedSymbols","verificationDetails")}
                              options={statusRemark}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Remark"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="salaryRemark"
                              name="salaryRemark"
                              disabled={
                                flag === "false" ||
                                  localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        )}
                      </div> */}
                      <div className="flex items-center my-2">
                        <div className="col-md-4 mb-2">
                          <label className="small mb-1" htmlFor="clientType">
                            Last Drawn Salary
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="salary"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumeric",
                                "experience"
                              )
                            }
                            value={item?.experience?.salary || ""}
                            // disabled={
                            //   flag === "true" ||
                            //   localStorageData?.role === "Auditor"
                            // }
                          />
                        </div>
                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <>
                            {" "}
                            <div className="mt-4 ml-12 mr-12">
                              <Checkbox
                                inputId="salary"
                                name="salary"
                                checked={
                                  isEdit.salary === true ||
                                    item?.verificationDetails?.salaryRemark !==
                                      ""
                                }
                                onChange={() => toggleEdit("salary")}
                              />
                            </div>
                            {(isEdit.salary ||
                                item?.verificationDetails?.salaryRemark) && (
                              <div className="col-md-4">
                                <label
                                  className="small mb-1"
                                  htmlFor="entityName"
                                >
                                    Remark
                                </label>

                                <InputText
                                  className="form-control"
                                  type="text"
                                  name="salaryRemark"
                                  onChange={(e) =>
                                    onInputChange(
                                      e,

                                      dataIndex,

                                      "numeric",

                                      "verificationDetails"
                                    )
                                  }
                                  value={
                                    item?.verificationDetails?.salaryRemark ||
                                      ""
                                  }

                                  // readOnly={

                                  //   flag === "true" ||

                                  //   localStorageData?.role === "Auditor"

                                  // }
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {localStorageData?.role !== "user" &&
                        localStorageData?.role !== "DataEntry" && (
                        <>
                          <div className="col-md-4 mb-2">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Full & Final Formalities (Clear/Pending)
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="fullAndFinalResponsibilities"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "text",
                                  "experience"
                                )
                              }
                              value={
                                item?.experience
                                  ?.fullAndFinalResponsibilities || ""
                              }
                              // disabled={
                              //   flag === "true" ||
                              //   localStorageData?.role === "Auditor"
                              // }
                            />
                          </div>
                          <div className="col-md-4 mb-2">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Any Disciplinary /Performance Issues: (Please
                                Specify)
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="performanceIssues"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "text",
                                  "experience"
                                )
                              }
                              value={
                                item?.experience?.performanceIssues || ""
                              }
                              // disabled={
                              //   flag === "true" ||
                              //   localStorageData?.role === "Auditor"
                              // }
                            />
                          </div>
                          <div className="col-md-4 mb-2">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Pending Dues- If Any
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="candidateDues"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "text",
                                  "experience"
                                )
                              }
                              value={item?.experience?.candidateDues || ""}
                              // disabled={
                              //   flag === "true" ||
                              //   localStorageData?.role === "Auditor"
                              // }
                            />
                          </div>
                          <div className="col-md-4 mb-2">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Eligible for Rehire (If No, Please Specify the
                                reason)
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="rehire"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "text",
                                  "experience"
                                )
                              }
                              value={item?.experience?.rehire || ""}
                              // disabled={
                              //   flag === "true" ||
                              //   localStorageData?.role === "Auditor"
                              // }
                            />
                          </div>
                          <div className="col-md-4 mb-2">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Mode of Verification
                            </label>
                            <Dropdown
                              value={
                                item?.experience?.modeOfVerification || ""
                              }
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumeric",
                                  "experience"
                                )
                              }
                              options={modeOfVerification}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select"
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="modeOfVerification"
                              name="modeOfVerification"
                              // disabled={
                              //   flag === "false" ||
                              //   localStorageData?.role === "Auditor"
                              // }
                            />
                          </div>
                          <div className="col-md-4 mb-2">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Additional Remarks
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="additionalRemarks"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "experience"
                                )
                              }
                              value={
                                item?.experience?.additionalRemarks || ""
                              }
                              // disabled={
                              //   flag === "true" ||
                              //   localStorageData?.role === "Auditor"
                              // }
                            />
                          </div>
                          <div className="col-md-4 mb-2">
                            <label
                              className="small mb-1"
                              htmlFor="clientType"
                            >
                                Verified By : (Please specify Name, Designation
                                and Contact No)
                            </label>
                            <InputText
                              className="form-control"
                              type="text"
                              name="verifiedBy"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols",
                                  "experience"
                                )
                              }
                              value={item?.experience?.verifiedBy || ""}
                              // disabled={
                              //   flag === "true" ||
                              //   localStorageData?.role === "Auditor"
                              // }
                            />
                          </div>{" "}
                        </>
                      )}

                      <div className="flex items-center my-2">
                        <div className="col-md-4 mb-2 mt-[10px]">
                          <label className="small mb-1" htmlFor="entityName">
                            Type of Employment
                          </label>
                          <div className="p-formgroup-inline flex  mt-2">
                            <div className="p-field-checkbox mr-8">
                              <Checkbox
                                inputId="Permanent"
                                name="typeOfEmployment"
                                value="Permanent"
                                checked={
                                  item?.experience?.typeOfEmployment ===
                                  "Permanent"
                                }
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "text",
                                    "experience"
                                  )
                                }
                              />
                              <label htmlFor="Permanent" className="ml-2">
                                Permanent
                              </label>
                            </div>
                            <div className="p-field-checkbox ">
                              <Checkbox
                                inputId="Contractual"
                                name="typeOfEmployment"
                                value="Contractual"
                                checked={
                                  item?.experience?.typeOfEmployment ===
                                  "Contractual"
                                }
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "text",
                                    "experience"
                                  )
                                }
                              />
                              <label htmlFor="Contractual" className="ml-2">
                                Contractual
                              </label>
                            </div>
                          </div>
                        </div>
                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <>
                            {" "}
                            <div className="mt-4 ml-12 mr-12">
                              <Checkbox
                                inputId="typeOfEmployment"
                                name="typeOfEmployment"
                                checked={
                                  isEdit.typeOfEmployment === true ||
                                    item?.verificationDetails
                                      ?.typeOfEmploymentRemark !== ""
                                }
                                onChange={() =>
                                  toggleEdit("typeOfEmployment")
                                }
                              />
                            </div>
                            {(isEdit.typeOfEmployment ||
                                item?.verificationDetails
                                  ?.typeOfEmploymentRemark) && (
                              <div className="col-md-4">
                                <label
                                  className="small mb-1"
                                  htmlFor="entityName"
                                >
                                    Remark
                                </label>

                                <InputText
                                  className="form-control"
                                  type="text"
                                  name="typeOfEmploymentRemark"
                                  onChange={(e) =>
                                    onInputChange(
                                      e,

                                      dataIndex,

                                      "text",

                                      "verificationDetails"
                                    )
                                  }
                                  value={
                                    item?.verificationDetails
                                      ?.typeOfEmploymentRemark || ""
                                  }

                                  // readOnly={

                                  //   flag === "true" ||

                                  //   localStorageData?.role === "Auditor"

                                  // }
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>

                      <div className="flex items-center my-2">
                        <div className="col-md-4 mb-2">
                          <label className="small mb-1" htmlFor="clientType">
                            Reason for Leaving the Company
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="reasonForLeaving"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text", "experience")
                            }
                            value={item?.experience?.reasonForLeaving || ""}
                            // disabled={
                            //   flag === "true" ||
                            //   localStorageData?.role === "Auditor"
                            // }
                          />
                        </div>
                        {localStorageData?.role !== "user" &&
                          localStorageData?.role !== "DataEntry" && (
                          <>
                            {" "}
                            <div className="mt-4 ml-12 mr-12">
                              <Checkbox
                                inputId="reasonForLeaving"
                                name="reasonForLeaving"
                                checked={
                                  isEdit.reasonForLeaving === true ||
                                    item?.verificationDetails
                                      ?.reasonForLeavingRemark !== ""
                                }
                                onChange={() =>
                                  toggleEdit("reasonForLeaving")
                                }
                              />
                            </div>
                            {(isEdit.reasonForLeaving ||
                                item?.verificationDetails
                                  ?.reasonForLeavingRemark) && (
                              <div className="col-md-4">
                                <label
                                  className="small mb-1"
                                  htmlFor="entityName"
                                >
                                    Remark
                                </label>

                                <InputText
                                  className="form-control"
                                  type="text"
                                  name="reasonForLeavingRemark"
                                  onChange={(e) =>
                                    onInputChange(
                                      e,
                                      dataIndex,
                                      "text",
                                      "verificationDetails"
                                    )
                                  }
                                  value={
                                    item?.verificationDetails
                                      ?.reasonForLeavingRemark || ""
                                  }
                                  // disabled={
                                  //   flag === "true" ||
                                  //   localStorageData?.role === "Auditor"
                                  // }
                                />
                              </div>
                            )}{" "}
                          </>
                        )}
                      </div>

                      <div className="col-md-8 mb-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Agency Details (if temporary or contractual )
                        </label>
                        <InputTextarea
                          className="form-control"
                          type="text"
                          name="agencyDetails"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols",
                              "experience"
                            )
                          }
                          value={item?.experience?.agencyDetails || ""}
                          // disabled={
                          //   flag === "true" ||
                          //   localStorageData?.role === "Auditor"
                          // }
                          rows={5}
                          cols={30}
                        />
                      </div>

                      {/* <div className="col-md-4 mb-2">
                        <label className="small mb-1" htmlFor="entityName">
                          Can we verify Current employment details?
                        </label>
                        <div className="p-formgroup-inline flex ">
                          <div className="p-field-checkbox mr-8">
                            <Checkbox
                              inputId="isVerifiableYes"
                              name="isVerifiable"
                              value="Yes"
                              checked={item?.experience?.isVerifiable === "Yes"}
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "text",
                                  "experience"
                                )
                              }
                            />
                            <label htmlFor="graduatedYes" className="ml-2">
                              Yes
                            </label>
                          </div>
                          <div className="p-field-checkbox ">
                            <Checkbox
                              inputId="isVerifiableNo"
                              name="isVerifiable"
                              value="No"
                              checked={item?.experience?.isVerifiable === "No"}
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "text",
                                  "experience"
                                )
                              }
                            />
                            <label htmlFor="isVerifiableNo" className="ml-2">
                              No
                            </label>
                          </div>
                        </div>
                      </div> */}

                      <div className="col-md-12 border-bottom">
                        <label className="small mb-1" htmlFor="clientType">
                          File Upload{" "}
                          <span className="opacity-70">
                            (Please Choose JPEG,PNG,PDF less than 5MB)
                          </span>
                        </label>
                        {item?.experience?.reportUrl?.length === 0 ||
                        editable === true ? (
                            <FileUpload
                              name="fileUpload[]"
                              multiple
                              auto
                              accept="image/*,application/pdf"
                              customUpload={true}
                              // maxFileSize={50000000}
                              uploadHandler={(e) => handleFileUpload(e, "file")}
                              itemTemplate={customItemTemplate}
                            // disabled={
                            //   flag === "true" ||
                            //   localStorageData?.role === "Auditor"
                            // }
                            />
                          ) : (
                            ""
                          )}

                        <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                          {(item?.experience?.reportUrl || []).map(
                            (fileUrl, reportIndex) => {
                              // Extract file name from the URL
                              const fileName = fileUrl.split("/").pop();
                              const fileExtension = fileName
                                .split(".")
                                .pop()
                                .toLowerCase();

                              const isImage = ["jpeg", "png"].includes(
                                fileExtension
                              );

                              const isPdf = fileExtension === "pdf";
                              return (
                                <li
                                  key={reportIndex}
                                  className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                >
                                  <div
                                    role="presentation"
                                    onClick={(e) =>
                                      removeImg(
                                        e,
                                        item._id,
                                        item,
                                        reportIndex,
                                        "file"
                                      )
                                    } // Pass the `item` to removeImg
                                    className="cursor-pointer"
                                  >
                                    <i
                                      className="pi pi-times"
                                      style={{ color: "red" }}
                                    ></i>
                                  </div>
                                  <Button
                                    label="Download"
                                    onClick={(e) =>
                                      getImg(
                                        e,
                                        fileName,
                                        dataIndex,
                                        reportIndex,
                                        "img"
                                      )
                                    }
                                    className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                  />

                                  {fileMap[dataIndex]?.[reportIndex] && (
                                    <div className="text-center mt-2">
                                      {isImage && (
                                        <a
                                          href={fileMap[dataIndex][reportIndex]}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          download={`file_${dataIndex}_${reportIndex}`}
                                        >
                                          View Image
                                        </a>
                                      )}
                                      {isPdf && (
                                        <a
                                          href={fileMap[dataIndex][reportIndex]}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-center"
                                        >
                                          View PDF
                                        </a>
                                      )}
                                    </div>
                                  )}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </>
                  )}

                  {localStorageData?.role !== "user" &&
                    localStorageData?.role !== "DataEntry" && (
                    <>
                      <div className="col-md-8 mt-2">
                        <label className="small mb-1" htmlFor="remark">
                            Remark
                        </label>
                        <textarea
                          className="form-control h-[110px]"
                          name="remark"
                          onChange={(e) => {
                            // setRemarkForActivity(e.target.value);
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumeric",
                              "experience"
                            );
                          }}
                          value={item?.experience?.remark || ""}
                          // disabled={
                          //   flag === "false" ||
                          //   localStorageData?.role === "Auditor"
                          // }
                        />
                      </div>

                      <div className="col-md-12 my-2">
                        <label className="small mb-1" htmlFor="clientType">
                            Upload Proof{" "}
                          <span className="opacity-70">
                              (Please Choose JPEG,PNG,PDF less than 5MB)
                          </span>
                        </label>
                        {item?.experience?.uploadProofUrl?.length === 0 && (
                          <FileUpload
                            name="fileUpload[]"
                            auto
                            multiple
                            accept="image/*,application/pdf"
                            customUpload={true}
                            maxFileSize={10000000}
                            uploadHandler={(e) =>
                              handleFileUpload(e, "uploadProof")
                            }
                            itemTemplate={customItemTemplate}
                            // disabled={
                            //   flag === "false" ||
                            //   localStorageData?.role === "Auditor"
                            // }
                          />
                        )}
                        <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                          {(item?.experience?.uploadProofUrl || []).map(
                            (fileUrl, proofIndex) => {
                              // Extract file name from the URL
                              const fileName = fileUrl.split("/").pop();
                              const fileExtension = fileName
                                .split(".")
                                .pop()
                                .toLowerCase();

                              const isImage = ["jpeg", "png"].includes(
                                fileExtension
                              );
                              const isPdf = fileExtension === "pdf";
                              return (
                                <li
                                  key={proofIndex}
                                  className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                >
                                  <div
                                    role="presentation"
                                    onClick={(e) =>
                                      removeImg(
                                        e,
                                        item._id,
                                        item,
                                        proofIndex,
                                        "proof"
                                      )
                                    } // Pass the `item` to removeImg
                                    className="cursor-pointer"
                                  >
                                    <i
                                      className="pi pi-times"
                                      style={{ color: "red" }}
                                    ></i>
                                  </div>
                                  <Button
                                    label="Download"
                                    onClick={(e) =>
                                      getImg(
                                        e,
                                        fileName,
                                        dataIndex,
                                        proofIndex,
                                        "proof"
                                      )
                                    }
                                    className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                  />
                                  <div className="text-center mt-2">
                                    {isImage &&
                                        proofImage[dataIndex]?.[proofIndex] && (
                                      <a
                                        href={
                                          proofImage[dataIndex][proofIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${proofIndex}`}
                                      >
                                            View Image
                                      </a>
                                    )}
                                    {isPdf &&
                                        proofImage[dataIndex]?.[proofIndex] && (
                                      <a
                                        href={
                                          proofImage[dataIndex][proofIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                            View PDF
                                      </a>
                                    )}
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </>
                  )}

                  <div></div>

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.experience?.status === "Pending" ? (
                        item?.experience?.reportUrl?.length > 0 ? (
                          <Button
                            label="Edit"
                            type="button"
                            onClick={(e) => {
                              handleEdit(e);
                            }}
                            className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    {/* {item?.experience?.status === "Pending" ? ( */}
                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateExperienceDetails(e, item._id, dataIndex);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.experience?.status)}</div>
                    </div>
                    {/* ) : (
                      <div>{getSeverity(item?.experience?.status)}</div>
                    )} */}
                  </div>
                </form>
              )}
            </div>
          </Card>

          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
              checkName="Employment"
            />
          </div>
        </>
      ))}

      <Modal
        width="50%"
        title="Create WebForm"
        open={showAddStaff}
        footer={null}
        onCancel={() => setShowaddStaff(false)}
      >
        <form onSubmit={formik.handleSubmit}>
          <p className="mt-2"> Let’s add your WebForm</p>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="Candidate Name"
                id="name"
                value={`${
                  taskDetail?.userVerificationRequest?.userInfo?.firstName || ""
                } ${
                  taskDetail?.userVerificationRequest?.userInfo?.lastName || ""
                }`}
                disabled
              />
              {/* {formik.touched.name && formik.errors.name ? (
                <div className="text-red-500">{formik.errors.name}</div>
              ) : null} */}
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="Employer Email ID"
                id="email"
                value={`${details?.hrEmail}`}
                disabled
              />
              {/* {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500">{formik.errors.email}</div>
              ) : null} */}
            </div>
          </div>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <label className="small mb-1" htmlFor="entityName">
                Start Date
              </label>
              <InputText
                className="form-control"
                type="date"
                name="startDate"
                placeholder="Start Date"
                // onChange={formik.handleChange}
              />
              {/* <Calendar
                className="w-full"
                id="inputStartdate"
                name="startDate"
                value={formik.values.startDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                showIcon
                showOnFocus
                dateFormat="mm-dd-yy"
              /> */}
            </div>
            <div className="col-md-6">
              <label className="small mb-1" htmlFor="entityName">
                End Date
              </label>
              <InputText
                className="form-control"
                type="date"
                name="endDate"
                placeholder="End Date"
                // onChange={formik.handleChange}
              />
              {/* <Calendar
                className="w-full"
                id="inputEnddate"
                name="end Date"
                value={formik.values.endDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                showIcon
                showOnFocus
                dateFormat="mm-dd-yy"
              /> */}
            </div>
          </div>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="p-inputtext p-component form-control w-[50px] form-control h-[50px] input flex justify-between"
              >
                <p className="text-[#CCCCCC]">Ask For Designation</p>
                <InputSwitch
                  checked={checked3}
                  onChange={(e) => setChecked3(e.value)}
                />
              </div>{" "}
            </div>
            <div className="col-md-6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="p-inputtext p-component form-control w-[50px] form-control h-[50px] input flex justify-between"
              >
                <p className="text-[#CCCCCC]">Reason to leave</p>
                <InputSwitch
                  checked={checked4}
                  onChange={(e) => setChecked4(e.value)}
                />
              </div>{" "}
            </div>
          </div>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="p-inputtext p-component form-control w-[50px] form-control h-[50px] input flex justify-between"
              >
                <p className="text-[#CCCCCC]">Ask For Feedback</p>
                <InputSwitch
                  checked={checked5}
                  onChange={(e) => setChecked5(e.value)}
                />
              </div>{" "}
            </div>
            <div className="col-md-6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="p-inputtext p-component form-control w-[50px] form-control h-[50px] input flex justify-between"
              >
                <p className="text-[#CCCCCC]">Ask For Annual CTC</p>
                <InputSwitch
                  checked={checked6}
                  onChange={(e) => setChecked6(e.value)}
                />
              </div>
            </div>
          </div>
          <div className="mt-[20px] text-end">
            <button
              type="submit"
              // disabled={isLoading}
              className="w-[141px] text-white text-xs font-medium h-[37px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
            >
              Create
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        width="50%"
        title="Feedback Form Submitted By HR"
        open={showViewForm}
        footer={null}
        onCancel={() => setShowViewForm(false)}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <label className="mb-1" htmlFor="inputEmployeName">
                Candidate Name
              </label>
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="Candidate Name"
                id="name"
                {...formik.getFieldProps("name")}
                disabled
              />
            </div>
            <div className="col-md-6">
              <label className="mb-1" htmlFor="inputEmployeId">
                Employer Email ID
              </label>
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="Employer Email ID"
                id="email"
                {...formik.getFieldProps("email")}
                disabled
              />
            </div>
          </div>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <label className="mb-1" htmlFor="inputStartDate">
                Select your Start Date
              </label>
              <InputText
                className="form-control w-[50px]"
                type="date"
                placeholder="StartDate"
                id="startDate"
                {...formik.getFieldProps("startDate")}
                disabled
              />
            </div>
            <div className="col-md-6">
              <label className="mb-1" htmlFor="inputEndDate">
                Select your End Date
              </label>
              <InputText
                className="form-control w-[50px]"
                type="date"
                placeholder="EndDate"
                id="endDate"
                {...formik.getFieldProps("endDate")}
                disabled
              />
            </div>
          </div>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <label className="mb-1" htmlFor="inputDesigntion">
                Designation
              </label>
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="Designation"
                id="designation"
                {...formik.getFieldProps("designation")}
                disabled
              />
            </div>
            <div className="col-md-6">
              <label className="mb-1" htmlFor="inputReasonToLeave">
                Reason to leave
              </label>
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="reasonToLeave"
                id="reasonToLeave"
                {...formik.getFieldProps("reasonToLeave")}
                disabled
              />
            </div>
          </div>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <label className="mb-1" htmlFor="inputFeedback">
                Feedback
              </label>
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="feedback"
                id="feedback"
                {...formik.getFieldProps("feedback")}
                disabled
              />
            </div>
            <div className="col-md-6">
              <label className="mb-1" htmlFor="inputAnnualCTC">
                Annual CTC
              </label>
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="annualCTC"
                id="annualCCT"
                {...formik.getFieldProps("annualCCT")}
                disabled
              />
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        width="50%"
        title="Send Verification Email"
        open={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
      >
        <form>
          <p className="mt-2"> Let’s add Email to send verification form</p>
          <div className="row gx-3 mb-3">
            <div className="col-md-3 flex items-center">
              <label htmlFor="" className="text-[18px] font-medium">
                Name :
              </label>
            </div>
            <div className="col-md-9">
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="Candidate Name"
                id="name"
                value={`${
                  taskDetail?.userVerificationRequest?.userInfo?.firstName || ""
                } ${
                  taskDetail?.userVerificationRequest?.userInfo?.lastName || ""
                }`}
                disabled
              />
            </div>
          </div>

          <div className="row gx-3 mb-3">
            <div className="col-md-3 flex items-center">
              <label htmlFor="" className="text-[18px] font-medium">
                To :
              </label>
            </div>
            <div className="col-md-9">
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="Email ID"
                id="email"
                value={emailForVerification}
                onChange={(e) => setEmailForVerification(e.target.value)}
              />
            </div>
          </div>

          <div className="row gx-3 mb-3">
            <div className="col-md-3 flex items-center">
              <label htmlFor="" className="text-[18px] font-medium">
                Cc :
              </label>
            </div>
            <div className="col-md-9">
              <div className="flex flex-wrap gap-2 items-center border p-2 rounded">
                {/* Display each added email as a tag */}
                {emailsForCc.map((email, index) => (
                  <span
                    key={index}
                    className="bg-gray-200 px-2 py-1 rounded flex items-center"
                  >
                    {email}
                    <button
                      type="button"
                      className="ml-2 text-red-500 hover:text-red-700"
                      onClick={() => removeCcEmail(index)}
                    >
                      &times;
                    </button>
                  </span>
                ))}
                {/* Input field for 'Cc' */}
                <InputText
                  className="form-control w-full"
                  type="text"
                  placeholder="Enter email and press Enter or Comma"
                  id="ccEmail"
                  value={emailCcForVerification}
                  onChange={(e) => setEmailCcForVerification(e.target.value)}
                  onKeyDown={handleKeyDownForCc}
                />
              </div>
            </div>
          </div>

          <div className="mt-[20px] text-end">
            <button
              onClick={(e) => {
                sendEmailToHr(e, emailForVerification, details);
              }}
              className="w-[141px] text-white text-xs font-medium h-[37px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
            >
              Send
            </button>
          </div>
        </form>
      </Modal>
      {localStorageData?.role === "user" && (
        <ConfirmDialog visible={isModalOpen} />
      )}
    </>
  );
};

export default ExperienceDetail;
