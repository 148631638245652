import React from "react";
import { Button } from "primereact/button";

const BCATabs = ({ setShowaddStaff, setShowaddStaffBulk }) => {
  return (
    <div className="flex lg:flex-row justify-end flex-col gap-[7px] max-[700px]:!mt-[10%] max-[700px]:w-full">
      <Button
        label="Add Field Executive"
        icon="pi pi-plus"
        onClick={() => setShowaddStaff(true)}
        className="text-white !text-[14px] h-[41px] !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
      />
      <Button
        label="Add Bulk Field Executive"
        icon="pi pi-plus"
        onClick={() => setShowaddStaffBulk(true)}
        className="text-white !text-[14px] h-[41px] !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
      />
    </div>
  );
};

export default BCATabs;
