import React, { useCallback, useState } from "react";
import { utils, read } from "xlsx";
import { useTable, useSortBy } from "react-table";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";

export default function UploadBulk({
  onDataParsed,
  isLoading,
  // setShowaddStaffBulk,
}) {
  const [pres, setPres] = useState([]);

  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleDownloadFormate = () => {
    const url = `${process.env.REACT_APP_S3_URL}/public/assets/BulkUpload-template.xlsx`;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "BulkUpload-template.xlsx"); // You can set the file name here
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleFileUpload = useCallback(
    async (event) => {
      const uploadedFile = event.files[0]; // files are accessed via event.files
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const wb = read(data, { type: "array" });
        const ws = wb.Sheets[wb.SheetNames[0]];
        const jsonData = utils.sheet_to_json(ws);
        setPres(jsonData);
      };

      reader.readAsArrayBuffer(uploadedFile);
    },
    [setPres]
  );
  const handleSubmit = () => {
    onDataParsed(pres);
    // setShowaddStaffBulk(false);
    // closeModal();
    if (isLoading === false) {
      setPres([]);
    }
  };

  const getAllowedHeaders = () => {
    // Initialize empty array for allowed headers
    let allowedHeaders = [];

    // Check if VERIFYKART columns should be included
    if (process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS === "true") {
      const verifyKartColumns = process.env.REACT_APP_VERIFYKART_CLIENT_COLUMNS;
      if (verifyKartColumns) {
        allowedHeaders = verifyKartColumns;
      }
    }

    // Check if WEACT columns should be included
    else if (process.env.REACT_APP_SHOW_WEACT_COLUMNS === "true") {
      const weActColumns = process.env.REACT_APP_WEACT_CLIENT_COLUMNS;
      if (weActColumns) {
        allowedHeaders = weActColumns;
      }
    }

    // Check if PAMAC columns should be included
    else if (process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true") {
      const pamacColumns = process.env.REACT_APP_PAMAC_CLIENT_COLUMNS;
      if (pamacColumns) {
        allowedHeaders = pamacColumns;
      }
    }

    // Remove duplicate headers (if any)
    return allowedHeaders;
  };

  const allowedHeaders = getAllowedHeaders();

  const columns = React.useMemo(() => {
    const getColumnData = () => {
      const columns = [
        {
          Header: "",
          accessor: "checkbox",
        },
        {
          Header: "Case Number",
          accessor: "caseNumber",
        },
        {
          Header: "Client Name",
          accessor: "clientName",
        },
        {
          Header: "First Name",
          accessor: "firstName",
        },
        {
          Header: "Last Name",
          accessor: "lastName",
        },
        {
          Header: "Email",
          accessor: "email",
        },
        {
          Header: "Phone No",
          accessor: "phoneNumber",
        },

        {
          Header: "Address",
          accessor: "address",
        },
        {
          Header: "Address 1",
          accessor: "address1",
        },
        {
          Header: "Address 2",
          accessor: "address2",
        },
        {
          Header: "City",
          accessor: "city",
        },
        {
          Header: "State",
          accessor: "state",
        },
        {
          Header: "Pincode",
          accessor: "pincode",
        },
      ];

      return columns.filter((column) => allowedHeaders.includes(column.Header));
    };

    return getColumnData();
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: pres }, useSortBy);

  return (
    <div>
      <div className="flex flex-row md:flex-col justify-end">
        <div className="p-[8px]">
          <Button
            disabled={isLoading}
            className="w-[122px] max-[700px]:mt-2 text-white !text-[15px] font-medium h-[41px] !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
            onClick={handleSubmit}
          >
            {/* Add Users */}

            {isLoading ? (
              <>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Wait...
              </>
            ) : (
              "Add Users"
            )}
          </Button>

          {/* <button
              type="submit"
              disabled={isLoading}
              className="w-[141px] text-white text-xs font-medium h-[37px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Wait...
                </>
              ) : (
                `Add ${label}`
              )}
            </button> */}
        </div>

        <div className="p-[8px]">
          <Button
            className="w-[240px] max-[700px]:mt-2 text-white !text-[15px] font-medium h-[41px] !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
            onClick={handleDownloadFormate}
          >
            Download Bulk Format
          </Button>
        </div>
      </div>
      <FileUpload
        accept=".xlsx,.xls"
        customUpload={true}
        auto
        uploadHandler={handleFileUpload}
      />
      <div className="w-full mt-[20px]  flex md:flex overflow-x-auto overflow-style-none md:rounded-lg shadow">
        <table
          {...getTableProps()}
          //   style={{ border: "1px solid black" }}
          className="mt-2 min-w-full divide-y divide-gray-200 dark:divide-gray-700"
        >
          <thead className="bg-white">
            {headerGroups.map((headerGroup) => (
              <tr
                key={Math.random().toString(36).substr(2, 9)}
                {...headerGroup.getHeaderGroupProps()}
                className="px-6 py-4"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    key={Math.random().toString(36).substr(2, 9)}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    // style={{
                    //   borderBottom: "1px solid black",
                    //   background: "aliceblue",
                    //   color: "black",
                    //   fontWeight: "bold",
                    // }}
                    className="py-3.5 px-4 text-gray-400 text-sm font-medium rtl:text-right text-nowrap  "
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900"
          >
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap"
                  key={Math.random().toString(36).substr(2, 9)}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className="px-4 py-4 text-zinc-800 text-sm font-medium whitespace-nowrap"
                        key={Math.random().toString(36).substr(2, 9)}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4 mb-4">
        <Stack spacing={2}>
          <Pagination
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            color="primary"
            page={page}
          />
        </Stack>
      </div>
    </div>
  );
}
